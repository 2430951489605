import React, { useCallback, useState, useEffect } from "react";
import Styled from "styled-components";

import { File } from "../../Components/Input";
import Button from "../../Components/Button";

import Notification, { Error } from "../../Modules/Notifications";
import Confirm from "../../Modules/AlertConfirm";

import Api from "../../service/api";

import useLoading from "../../hooks/useLoading";

const Section = Styled.section`
    & div div .row {
        display: flex;
        justify-content: center;
    }
`;

function Index() {
    const [objectPP1, setObjectPP1] = useState({ image: {}, file: {} });
    const [objectPP2, setObjectPP2] = useState({ image: {}, file: {} });
    const [objectPP3, setObjectPP3] = useState({ image: {}, file: {} });
    const [objectPP4, setObjectPP4] = useState({ image: {}, file: {} });
    const [objectPP5, setObjectPP5] = useState({ image: {}, file: {} });
    const [objectPP6, setObjectPP6] = useState({ image: {}, file: {} });

    const [objects, setObjects] = useState([]);

    const [stateCreate, setStateCreate] = useState(false);

    const { loading } = useLoading();

    const index = useCallback(() => {
        async function index() {
            const idLoading = loading();

            try {
                const response = await Api.get(
                    "/configuracoes-produtos-mercados?type=2"
                );

                if (response.data.length === 0) {
                    setStateCreate(true);
                    loading(idLoading);
                    return;
                }

                setStateCreate(false);

                const objects = response.data[0].object;
                setObjects(objects);

                const objectPP1 = {
                    id: response.data[0].id,
                    file: {
                        id: objects[0].file.id,
                        file: objects[0].file,
                        preview: `${Api.defaults.baseURL}${objects[0].file.url}`,
                    },
                    image: {
                        file: objects[0].image,
                        preview: `${Api.defaults.baseURL}${objects[0].image.url}`,
                    },
                };
                setObjectPP1(objectPP1);

                const objectPP2 = {
                    id: response.data[0].id,
                    file: {
                        id: objects[1].file.id,
                        file: objects[1].file,
                        preview: `${Api.defaults.baseURL}${objects[1].file.url}`,
                    },
                    image: {
                        file: objects[1].image,
                        preview: `${Api.defaults.baseURL}${objects[1].image.url}`,
                    },
                };
                setObjectPP2(objectPP2);

                const objectPP3 = {
                    id: response.data[0].id,
                    file: {
                        id: objects[2].file.id,
                        file: objects[2].file,
                        preview: `${Api.defaults.baseURL}${objects[2].file.url}`,
                    },
                    image: {
                        file: objects[2].image,
                        preview: `${Api.defaults.baseURL}${objects[2].image.url}`,
                    },
                };
                setObjectPP3(objectPP3);

                const objectPP4 = {
                    id: response.data[0].id,
                    file: {
                        id: objects[3].file.id,
                        file: objects[3].file,
                        preview: `${Api.defaults.baseURL}${objects[3].file.url}`,
                    },
                    image: {
                        file: objects[3].image,
                        preview: `${Api.defaults.baseURL}${objects[3].image.url}`,
                    },
                };
                setObjectPP4(objectPP4);

                const objectPP5 = {
                    id: response.data[0].id,
                    file: {
                        id: objects[4].file.id,
                        file: objects[4].file,
                        preview: `${Api.defaults.baseURL}${objects[4].file.url}`,
                    },
                    image: {
                        file: objects[4].image,
                        preview: `${Api.defaults.baseURL}${objects[4].image.url}`,
                    },
                };
                setObjectPP5(objectPP5);

                const objectPP6 = {
                    id: response.data[0].id,
                    file: {
                        id: objects[5].file.id,
                        file: objects[5].file,
                        preview: `${Api.defaults.baseURL}${objects[5].file.url}`,
                    },
                    image: {
                        file: objects[5].image,
                        preview: `${Api.defaults.baseURL}${objects[5].image.url}`,
                    },
                };
                setObjectPP6(objectPP6);

                loading(idLoading);
            } catch (error) {
                Error(error);

                loading(idLoading);
            }
        }

        index();
    }, [loading]);

    useEffect(() => index(), [index]);

    function handleValidate() {
        if (Object.keys(objectPP1.image).length === 0) {
            return { status: false, message: "Imagem PP1 é obrigatório" };
        }

        if (Object.keys(objectPP1.file).length === 0) {
            return { status: false, message: "PDF PP1 é obrigatório" };
        }

        if (Object.keys(objectPP2.image).length === 0) {
            return { status: false, message: "Imagem PP2 é obrigatório" };
        }

        if (Object.keys(objectPP2.file).length === 0) {
            return { status: false, message: "PDF PP2 é obrigatório" };
        }

        if (Object.keys(objectPP3.image).length === 0) {
            return { status: false, message: "Imagem PP3 é obrigatório" };
        }

        if (Object.keys(objectPP3.file).length === 0) {
            return { status: false, message: "PDF PP3 é obrigatório" };
        }

        if (Object.keys(objectPP4.image).length === 0) {
            return { status: false, message: "Imagem PP4 é obrigatório" };
        }

        if (Object.keys(objectPP4.file).length === 0) {
            return { status: false, message: "PDF PP4 é obrigatório" };
        }

        if (Object.keys(objectPP5.image).length === 0) {
            return { status: false, message: "Imagem PP5 é obrigatório" };
        }

        if (Object.keys(objectPP5.file).length === 0) {
            return { status: false, message: "PDF PP5 é obrigatório" };
        }

        if (Object.keys(objectPP6.image).length === 0) {
            return { status: false, message: "Imagem PP6 é obrigatório" };
        }

        if (Object.keys(objectPP6.file).length === 0) {
            return { status: false, message: "PDF PP6 é obrigatório" };
        }

        return { status: true };
    }

    const uploadFile = async (file) => {
        const idLoading = loading();

        let data = new FormData();
        data.append(`files`, file);
        const response = await Api.post("/upload", data);

        loading(idLoading);

        return response.data[0];
    };

    async function create() {
        const idLoading = loading();

        try {
            const validate = handleValidate();
            if (!validate.status) {
                Notification("warning", validate.message);
                loading(idLoading);
                return;
            }

            let objectData = [];
            const pp1Image = await uploadFile(objectPP1.image.file);
            const pp1File = await uploadFile(objectPP1.file.file);
            objectData.push({ image: pp1Image, file: pp1File });

            const pp2Image = await uploadFile(objectPP2.image.file);
            const pp2File = await uploadFile(objectPP2.file.file);
            objectData.push({ image: pp2Image, file: pp2File });

            const pp3Image = await uploadFile(objectPP3.image.file);
            const pp3File = await uploadFile(objectPP3.file.file);
            objectData.push({ image: pp3Image, file: pp3File });

            const pp4Image = await uploadFile(objectPP4.image.file);
            const pp4File = await uploadFile(objectPP4.file.file);
            objectData.push({ image: pp4Image, file: pp4File });

            const pp5Image = await uploadFile(objectPP5.image.file);
            const pp5File = await uploadFile(objectPP5.file.file);
            objectData.push({ image: pp5Image, file: pp5File });

            const pp6Image = await uploadFile(objectPP6.image.file);
            const pp6File = await uploadFile(objectPP6.file.file);
            objectData.push({ image: pp6Image, file: pp6File });

            await Api.post("/configuracoes-produtos-mercados", {
                type: 2,
                object: objectData,
            });

            index();

            Notification(
                "success",
                "Configuração Produtos Profissionais cadastrada"
            );

            loading(idLoading);
        } catch (error) {
            Error(error);

            loading(idLoading);
        }
    }

    return (
        <Section>
            <div className="row">
                <div className="col-sm-12 col-md-6">
                    <h3 className="col-12">Produto 1</h3>
                    <div className="row">
                        <File
                            className="col"
                            label="Imagem*"
                            width="200"
                            accept="image/*"
                            file={objectPP1.image || {}}
                            onUpload={(files) => {
                                const file = files[0];

                                if (objectPP1.id) {
                                    Confirm(
                                        "Alterar Imagem PP1",
                                        "Tem certeza que deseja alterar ?",
                                        async () => {
                                            const idLoading = loading();

                                            try {
                                                await Api.delete(
                                                    `/upload/files/${objectPP1.image.file.id}`
                                                );

                                                const response =
                                                    await uploadFile(file);

                                                let objectData = objects;

                                                objectData[0].image = response;

                                                await Api.put(
                                                    `/configuracoes-produtos-mercados/${objectPP1.id}`,
                                                    {
                                                        object: objectData,
                                                    }
                                                );

                                                index();

                                                Notification(
                                                    "success",
                                                    "Imagem PP1 alterada"
                                                );

                                                loading(idLoading);
                                            } catch (error) {
                                                Error(error);

                                                loading(idLoading);
                                            }
                                        }
                                    );
                                } else {
                                    setObjectPP1({
                                        ...objectPP1,
                                        image: {
                                            file,
                                            preview: URL.createObjectURL(file),
                                        },
                                    });
                                }
                            }}
                            onDeleteFile={() => {
                                if (objectPP1.id) {
                                    Notification(
                                        "warning",
                                        "Não é possivel remover"
                                    );
                                } else {
                                    setObjectPP1({
                                        ...objectPP1,
                                        image: {},
                                    });
                                }
                            }}
                        />
                        <File
                            className="col"
                            label="PDF*"
                            width="200"
                            height="250px"
                            accept=".pdf"
                            fileType="pdf"
                            file={objectPP1.file || {}}
                            onUpload={(files) => {
                                const file = files[0];

                                if (objectPP1.id) {
                                    Confirm(
                                        "Alterar PDF PP1",
                                        "Tem certeza que deseja alterar ?",
                                        async () => {
                                            const idLoading = loading();

                                            try {
                                                await Api.delete(
                                                    `/upload/files/${objectPP1.file.file.id}`
                                                );

                                                const response =
                                                    await uploadFile(file);

                                                let objectData = objects;

                                                objectData[0].file = response;

                                                await Api.put(
                                                    `/configuracoes-produtos-mercados/${objectPP1.id}`,
                                                    {
                                                        object: objectData,
                                                    }
                                                );

                                                index();

                                                Notification(
                                                    "success",
                                                    "PDF PP1 alterada"
                                                );

                                                loading(idLoading);
                                            } catch (error) {
                                                Error(error);

                                                loading(idLoading);
                                            }
                                        }
                                    );
                                } else {
                                    setObjectPP1({
                                        ...objectPP1,
                                        file: {
                                            file,
                                            preview: URL.createObjectURL(file),
                                        },
                                    });
                                }
                            }}
                            onDeleteFile={() => {
                                if (objectPP1.id) {
                                    Notification(
                                        "warning",
                                        "Não é possivel remover"
                                    );
                                } else {
                                    setObjectPP1({
                                        ...objectPP1,
                                        file: {},
                                    });
                                }
                            }}
                        />
                    </div>
                </div>
                <div className="col-sm-12 col-md-6">
                    <h3 className="col-12">Produto 2</h3>
                    <div className="row">
                        <File
                            className="col"
                            label="Imagem*"
                            width="200"
                            accept="image/*"
                            file={objectPP2.image || {}}
                            onUpload={(files) => {
                                const file = files[0];

                                if (objectPP2.id) {
                                    Confirm(
                                        "Alterar Imagem PP2",
                                        "Tem certeza que deseja alterar ?",
                                        async () => {
                                            const idLoading = loading();

                                            try {
                                                await Api.delete(
                                                    `/upload/files/${objectPP2.image.file.id}`
                                                );

                                                const response =
                                                    await uploadFile(file);

                                                let objectData = objects;

                                                objectData[1].image = response;

                                                await Api.put(
                                                    `/configuracoes-produtos-mercados/${objectPP2.id}`,
                                                    {
                                                        object: objectData,
                                                    }
                                                );

                                                index();

                                                Notification(
                                                    "success",
                                                    "Imagem PP2 alterada"
                                                );

                                                loading(idLoading);
                                            } catch (error) {
                                                Error(error);

                                                loading(idLoading);
                                            }
                                        }
                                    );
                                } else {
                                    setObjectPP2({
                                        ...objectPP2,
                                        image: {
                                            file,
                                            preview: URL.createObjectURL(file),
                                        },
                                    });
                                }
                            }}
                            onDeleteFile={() => {
                                if (objectPP2.id) {
                                    Notification(
                                        "warning",
                                        "Não é possivel remover"
                                    );
                                } else {
                                    setObjectPP2({
                                        ...objectPP2,
                                        image: {},
                                    });
                                }
                            }}
                        />
                        <File
                            className="col"
                            label="PDF*"
                            width="200"
                            height="250px"
                            accept=".pdf"
                            fileType="pdf"
                            file={objectPP2.file || {}}
                            onUpload={(files) => {
                                const file = files[0];

                                if (objectPP2.id) {
                                    Confirm(
                                        "Alterar PDF PP2",
                                        "Tem certeza que deseja alterar ?",
                                        async () => {
                                            const idLoading = loading();

                                            try {
                                                await Api.delete(
                                                    `/upload/files/${objectPP2.file.file.id}`
                                                );

                                                const response =
                                                    await uploadFile(file);

                                                let objectData = objects;

                                                objectData[1].file = response;

                                                await Api.put(
                                                    `/configuracoes-produtos-mercados/${objectPP2.id}`,
                                                    {
                                                        object: objectData,
                                                    }
                                                );

                                                index();

                                                Notification(
                                                    "success",
                                                    "PDF PP2 alterada"
                                                );

                                                loading(idLoading);
                                            } catch (error) {
                                                Error(error);

                                                loading(idLoading);
                                            }
                                        }
                                    );
                                } else {
                                    setObjectPP2({
                                        ...objectPP2,
                                        file: {
                                            file,
                                            preview: URL.createObjectURL(file),
                                        },
                                    });
                                }
                            }}
                            onDeleteFile={() => {
                                if (objectPP2.id) {
                                    Notification(
                                        "warning",
                                        "Não é possivel remover"
                                    );
                                } else {
                                    setObjectPP2({
                                        ...objectPP2,
                                        file: {},
                                    });
                                }
                            }}
                        />
                    </div>
                </div>
                <div className="col-sm-12 col-md-6">
                    <h3 className="col-12">Produto 3</h3>
                    <div className="row">
                        <File
                            className="col"
                            label="Imagem*"
                            width="200"
                            accept="image/*"
                            file={objectPP3.image || {}}
                            onUpload={(files) => {
                                const file = files[0];

                                if (objectPP3.id) {
                                    Confirm(
                                        "Alterar Imagem PP3",
                                        "Tem certeza que deseja alterar ?",
                                        async () => {
                                            const idLoading = loading();

                                            try {
                                                await Api.delete(
                                                    `/upload/files/${objectPP3.image.file.id}`
                                                );

                                                const response =
                                                    await uploadFile(file);

                                                let objectData = objects;

                                                objectData[2].image = response;

                                                await Api.put(
                                                    `/configuracoes-produtos-mercados/${objectPP3.id}`,
                                                    {
                                                        object: objectData,
                                                    }
                                                );

                                                index();

                                                Notification(
                                                    "success",
                                                    "Imagem PP3 alterada"
                                                );

                                                loading(idLoading);
                                            } catch (error) {
                                                Error(error);

                                                loading(idLoading);
                                            }
                                        }
                                    );
                                } else {
                                    setObjectPP3({
                                        ...objectPP3,
                                        image: {
                                            file,
                                            preview: URL.createObjectURL(file),
                                        },
                                    });
                                }
                            }}
                            onDeleteFile={() => {
                                if (objectPP3.id) {
                                    Notification(
                                        "warning",
                                        "Não é possivel remover"
                                    );
                                } else {
                                    setObjectPP3({
                                        ...objectPP3,
                                        image: {},
                                    });
                                }
                            }}
                        />

                        <File
                            className="col"
                            label="PDF*"
                            width="200"
                            height="250px"
                            accept=".pdf"
                            fileType="pdf"
                            file={objectPP3.file || {}}
                            onUpload={(files) => {
                                const file = files[0];

                                if (objectPP3.id) {
                                    Confirm(
                                        "Alterar PDF PP3",
                                        "Tem certeza que deseja alterar ?",
                                        async () => {
                                            const idLoading = loading();

                                            try {
                                                await Api.delete(
                                                    `/upload/files/${objectPP3.file.file.id}`
                                                );

                                                const response =
                                                    await uploadFile(file);

                                                let objectData = objects;

                                                objectData[2].file = response;

                                                await Api.put(
                                                    `/configuracoes-produtos-mercados/${objectPP3.id}`,
                                                    {
                                                        object: objectData,
                                                    }
                                                );

                                                index();

                                                Notification(
                                                    "success",
                                                    "PDF PP3 alterada"
                                                );

                                                loading(idLoading);
                                            } catch (error) {
                                                Error(error);

                                                loading(idLoading);
                                            }
                                        }
                                    );
                                } else {
                                    setObjectPP3({
                                        ...objectPP3,
                                        file: {
                                            file,
                                            preview: URL.createObjectURL(file),
                                        },
                                    });
                                }
                            }}
                            onDeleteFile={() => {
                                if (objectPP3.id) {
                                    Notification(
                                        "warning",
                                        "Não é possivel remover"
                                    );
                                } else {
                                    setObjectPP3({
                                        ...objectPP3,
                                        file: {},
                                    });
                                }
                            }}
                        />
                    </div>
                </div>
                <div className="col-sm-12 col-md-6">
                    <h3 className="col-12">Produto 4</h3>
                    <div className="row">
                        <File
                            className="col"
                            label="Imagem*"
                            width="200"
                            accept="image/*"
                            file={objectPP4.image || {}}
                            onUpload={(files) => {
                                const file = files[0];

                                if (objectPP4.id) {
                                    Confirm(
                                        "Alterar Imagem PP4",
                                        "Tem certeza que deseja alterar ?",
                                        async () => {
                                            const idLoading = loading();

                                            try {
                                                await Api.delete(
                                                    `/upload/files/${objectPP4.image.file.id}`
                                                );

                                                const response =
                                                    await uploadFile(file);

                                                let objectData = objects;

                                                objectData[3].image = response;

                                                await Api.put(
                                                    `/configuracoes-produtos-mercados/${objectPP4.id}`,
                                                    {
                                                        object: objectData,
                                                    }
                                                );

                                                index();

                                                Notification(
                                                    "success",
                                                    "Imagem PP4 alterada"
                                                );

                                                loading(idLoading);
                                            } catch (error) {
                                                Error(error);

                                                loading(idLoading);
                                            }
                                        }
                                    );
                                } else {
                                    setObjectPP4({
                                        ...objectPP4,
                                        image: {
                                            file,
                                            preview: URL.createObjectURL(file),
                                        },
                                    });
                                }
                            }}
                            onDeleteFile={() => {
                                if (objectPP4.id) {
                                    Notification(
                                        "warning",
                                        "Não é possivel remover"
                                    );
                                } else {
                                    setObjectPP4({
                                        ...objectPP4,
                                        image: {},
                                    });
                                }
                            }}
                        />

                        <File
                            className="col"
                            label="PDF*"
                            width="200"
                            height="250px"
                            accept=".pdf"
                            fileType="pdf"
                            file={objectPP4.file || {}}
                            onUpload={(files) => {
                                const file = files[0];

                                if (objectPP4.id) {
                                    Confirm(
                                        "Alterar PDF PP4",
                                        "Tem certeza que deseja alterar ?",
                                        async () => {
                                            const idLoading = loading();

                                            try {
                                                await Api.delete(
                                                    `/upload/files/${objectPP4.file.file.id}`
                                                );

                                                const response =
                                                    await uploadFile(file);

                                                let objectData = objects;

                                                objectData[3].file = response;

                                                await Api.put(
                                                    `/configuracoes-produtos-mercados/${objectPP4.id}`,
                                                    {
                                                        object: objectData,
                                                    }
                                                );

                                                index();

                                                Notification(
                                                    "success",
                                                    "PDF PP4 alterada"
                                                );

                                                loading(idLoading);
                                            } catch (error) {
                                                Error(error);

                                                loading(idLoading);
                                            }
                                        }
                                    );
                                } else {
                                    setObjectPP4({
                                        ...objectPP4,
                                        file: {
                                            file,
                                            preview: URL.createObjectURL(file),
                                        },
                                    });
                                }
                            }}
                            onDeleteFile={() => {
                                if (objectPP4.id) {
                                    Notification(
                                        "warning",
                                        "Não é possivel remover"
                                    );
                                } else {
                                    setObjectPP4({
                                        ...objectPP4,
                                        file: {},
                                    });
                                }
                            }}
                        />
                    </div>
                </div>
                <div className="col-sm-12 col-md-6">
                    <h3 className="col-12">Produto 5</h3>
                    <div className="row">
                        <File
                            className="col"
                            label="Imagem*"
                            width="200"
                            accept="image/*"
                            file={objectPP5.image || {}}
                            onUpload={(files) => {
                                const file = files[0];

                                if (objectPP5.id) {
                                    Confirm(
                                        "Alterar Imagem PP5",
                                        "Tem certeza que deseja alterar ?",
                                        async () => {
                                            const idLoading = loading();

                                            try {
                                                await Api.delete(
                                                    `/upload/files/${objectPP5.image.file.id}`
                                                );

                                                const response =
                                                    await uploadFile(file);

                                                let objectData = objects;

                                                objectData[4].image = response;

                                                await Api.put(
                                                    `/configuracoes-produtos-mercados/${objectPP5.id}`,
                                                    {
                                                        object: objectData,
                                                    }
                                                );

                                                index();

                                                Notification(
                                                    "success",
                                                    "Imagem PP5 alterada"
                                                );

                                                loading(idLoading);
                                            } catch (error) {
                                                Error(error);

                                                loading(idLoading);
                                            }
                                        }
                                    );
                                } else {
                                    setObjectPP5({
                                        ...objectPP5,
                                        image: {
                                            file,
                                            preview: URL.createObjectURL(file),
                                        },
                                    });
                                }
                            }}
                            onDeleteFile={() => {
                                if (objectPP5.id) {
                                    Notification(
                                        "warning",
                                        "Não é possivel remover"
                                    );
                                } else {
                                    setObjectPP5({
                                        ...objectPP5,
                                        image: {},
                                    });
                                }
                            }}
                        />
                        <File
                            className="col"
                            label="PDF*"
                            width="200"
                            height="250px"
                            accept=".pdf"
                            fileType="pdf"
                            file={objectPP5.file || {}}
                            onUpload={(files) => {
                                const file = files[0];

                                if (objectPP5.id) {
                                    Confirm(
                                        "Alterar PDF PP5",
                                        "Tem certeza que deseja alterar ?",
                                        async () => {
                                            const idLoading = loading();

                                            try {
                                                await Api.delete(
                                                    `/upload/files/${objectPP5.file.file.id}`
                                                );

                                                const response =
                                                    await uploadFile(file);

                                                let objectData = objects;

                                                objectData[4].file = response;

                                                await Api.put(
                                                    `/configuracoes-produtos-mercados/${objectPP5.id}`,
                                                    {
                                                        object: objectData,
                                                    }
                                                );
                                                index();
                                                Notification(
                                                    "success",
                                                    "PDF PP5 alterada"
                                                );

                                                loading(idLoading);
                                            } catch (error) {
                                                Error(error);

                                                loading(idLoading);
                                            }
                                        }
                                    );
                                } else {
                                    setObjectPP5({
                                        ...objectPP5,
                                        file: {
                                            file,
                                            preview: URL.createObjectURL(file),
                                        },
                                    });
                                }
                            }}
                            onDeleteFile={() => {
                                if (objectPP5.id) {
                                    Notification(
                                        "warning",
                                        "Não é possivel remover"
                                    );
                                } else {
                                    setObjectPP5({
                                        ...objectPP5,
                                        file: {},
                                    });
                                }
                            }}
                        />
                    </div>
                </div>
                <div className="col-sm-12 col-md-6">
                    <h3 className="col-12">Produto 6</h3>
                    <div className="row">
                        <File
                            className="col"
                            label="Imagem*"
                            width="200"
                            accept="image/*"
                            file={objectPP6.image || {}}
                            onUpload={(files) => {
                                const file = files[0];

                                if (objectPP6.id) {
                                    Confirm(
                                        "Alterar Imagem PP6",
                                        "Tem certeza que deseja alterar ?",
                                        async () => {
                                            const idLoading = loading();

                                            try {
                                                await Api.delete(
                                                    `/upload/files/${objectPP6.image.file.id}`
                                                );

                                                const response =
                                                    await uploadFile(file);

                                                let objectData = objects;

                                                objectData[5].image = response;

                                                await Api.put(
                                                    `/configuracoes-produtos-mercados/${objectPP6.id}`,
                                                    {
                                                        object: objectData,
                                                    }
                                                );
                                                index();
                                                Notification(
                                                    "success",
                                                    "Imagem PP6 alterada"
                                                );

                                                loading(idLoading);
                                            } catch (error) {
                                                Error(error);

                                                loading(idLoading);
                                            }
                                        }
                                    );
                                } else {
                                    setObjectPP6({
                                        ...objectPP6,
                                        image: {
                                            file,
                                            preview: URL.createObjectURL(file),
                                        },
                                    });
                                }
                            }}
                            onDeleteFile={() => {
                                if (objectPP6.id) {
                                    Notification(
                                        "warning",
                                        "Não é possivel remover"
                                    );
                                } else {
                                    setObjectPP6({
                                        ...objectPP6,
                                        image: {},
                                    });
                                }
                            }}
                        />
                        <File
                            className="col"
                            label="PDF*"
                            width="200"
                            height="250px"
                            accept=".pdf"
                            fileType="pdf"
                            file={objectPP6.file || {}}
                            onUpload={(files) => {
                                const file = files[0];

                                if (objectPP6.id) {
                                    Confirm(
                                        "Alterar PDF PP6",
                                        "Tem certeza que deseja alterar ?",
                                        async () => {
                                            const idLoading = loading();

                                            try {
                                                await Api.delete(
                                                    `/upload/files/${objectPP6.file.file.id}`
                                                );

                                                const response =
                                                    await uploadFile(file);

                                                let objectData = objects;

                                                objectData[5].file = response;

                                                await Api.put(
                                                    `/configuracoes-produtos-mercados/${objectPP6.id}`,
                                                    {
                                                        object: objectData,
                                                    }
                                                );
                                                index();
                                                Notification(
                                                    "success",
                                                    "PDF PP6 alterada"
                                                );

                                                loading(idLoading);
                                            } catch (error) {
                                                Error(error);

                                                loading(idLoading);
                                            }
                                        }
                                    );
                                } else {
                                    setObjectPP6({
                                        ...objectPP6,
                                        file: {
                                            file,
                                            preview: URL.createObjectURL(file),
                                        },
                                    });
                                }
                            }}
                            onDeleteFile={() => {
                                if (objectPP6.id) {
                                    Notification(
                                        "warning",
                                        "Não é possivel remover"
                                    );
                                } else {
                                    setObjectPP6({
                                        ...objectPP6,
                                        file: {},
                                    });
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
            {stateCreate && (
                <div>
                    <Button variant="success" onClick={create}>
                        Enviar
                    </Button>
                </div>
            )}
        </Section>
    );
}

export default Index;
