import React from "react";

import logo from "../../../Assets/Images/logo-ecobrite.png";

import { Spinner } from "react-og-loaders";

import { Container } from "./styles";

const Loading = () => {
    return (
        <Container>
            <img src={logo} alt="Ecobrite Loading" />

            <Spinner color="var(--primary)" size={42} />
        </Container>
    );
};

export default Loading;
