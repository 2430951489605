import React from "react";
import Container from "../../Components/Container";
import ThanksMessage from "../../Components/ThanksMessage";

const ThanksPage = () => {
  return (
    <Container>
      <ThanksMessage />
    </Container>
  );
};

export default ThanksPage;
