import Api from "../service/api";

const uploadFile = async (file) => {
    let data = new FormData();
    data.append(`files`, file);
    const response = await Api.post("/upload", data);
    return response.data[0];
};

export default uploadFile;
