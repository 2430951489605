import React from "react";
import { File } from "react-og-forms";
import { Editor } from "react-draft-wysiwyg";

import api from "../../service/api";

import { Container, Header } from "./styles";
import Button from "../Button";
import Form from "../Form";

import useSettingsCDOC from "./useSettingsCDOC";

function SettingsCDOC() {
    const {
        settings,
        popupAtivo,
        editorState,
        update,
        handlePopupValue,
        handleOnChangePopup,
        handleToggleAtivo,
        handleOnChangeFile,
        gotoHomologatedBrands,
        gotoGraphicMaterials,
        gotoGraphicEvents,
        gotoGraphicReleases,
    } = useSettingsCDOC();

    return (
        <Container>
            <Header>
                <h2>Carousel</h2>
                <div>
                    <Button variant="success" onClick={gotoHomologatedBrands}>
                        Marcas Homologadas
                    </Button>
                    <Button variant="success" onClick={gotoGraphicMaterials}>
                        Material Gráfico
                    </Button>
                    <Button variant="success" onClick={gotoGraphicEvents}>
                        Eventos
                    </Button>
                    <Button variant="success" onClick={gotoGraphicReleases}>
                        Lançamentos
                    </Button>
                </div>
            </Header>
            <Form onSubmit={update}>
                <File
                    multiple
                    value={settings.CDOCCarousel}
                    accept="image/*"
                    onChange={handleOnChangeFile}
                    baseUrl={api.defaults.baseURL}
                    headers={api.defaults.headers}
                />

                <h2>Popup</h2>
                <div className="d-flex justify-content-end">
                    <label>
                        <input
                            type="checkbox"
                            id="is-actived"
                            checked={popupAtivo}
                            onChange={handleToggleAtivo}
                        />
                        Ativo
                    </label>
                </div>
                <div>
                    <Editor
                        editorState={editorState}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={handleOnChangePopup}
                    />
                    <textarea
                        id="editor-html-popup-cdoc"
                        style={{ visibility: "hidden", position: "absolute" }}
                        disabled
                        value={handlePopupValue()}
                    />
                </div>

                <Button type="submit" variant="success">
                    Enviar
                </Button>
            </Form>
        </Container>
    );
}

export default SettingsCDOC;
