import styled from "styled-components";

export const Container = styled.div`
    width: 100%;

    .header-main {
        @media (max-width: 900px) {
            text-align: center;
        }
    }
`;
