import React, { useState } from "react";
import Styled from "styled-components";
import { FaPaperPlane } from "react-icons/fa";

import Container from "../../Components/Container";
import Img from "../../Components/Img";
import Form from "../../Components/Form";
import Input, { TextArea } from "../../Components/Input";
import GridButtons from "../../Components/ButtonsGrid";
import Button from "../../Components/Button";

import LogoInputs from "../../Assets/Images/logo-inputs.png";

import useLoading from "../../hooks/useLoading";

import api from "../../service/api";
import Mail from "../../service/mail";
import Notification, { Error } from "../../Modules/Notifications";

const Header = Styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;

    & img {
        margin: 0 1rem 1rem 0!important;
    }
`;

const Section = Styled.section`
    margin-top: 1rem!important;
`;

const Index = () => {
    const [data, setData] = useState({});

    const { loading } = useLoading();

    const handleHtml = () => {
        return `
                <!DOCTYPE html>
                <html>
                <head>
                    <style>
                        h3 {
                            color: #38733e;
                        }

                        p {
                            color: black;
                        }
                    </style>
                </head>
                <body>
                    <div>
                        <h3>Nome</h3>
                        <p>${data.name}</p>
                    </div>
                    <div>
                        <h3>Empresa</h3>
                        <p>${data.company}</p>
                    </div>
                    <div>
                        <h3>E-mail</h3>
                        <p>${data.name}</p>
                    </div>
                    <div>
                        <h3>Mensagem</h3>
                        <p>
                            ${data.message}
                        </p>
                    </div>
                </body>
            </html>`;
    };

    async function handleSubmit(e) {
        e.preventDefault();

        const idLoading = loading();

        try {
            await api.post(
                "/email",
                {
                    to: Mail.aboutInputs,
                    subject: "SOLICITAÇÃO DE LOGIN E SENHA INPUTS",
                    html: handleHtml(),
                },
                {
                    headers: {
                        Authorization: "",
                    },
                }
            );

            Notification("success", "Mensagem enviada");

            setData({
                name: "",
                company: "",
                email: "",
                message: "",
            });

            loading(idLoading);
        } catch (error) {
            Error(error);

            loading(idLoading);
        }
    }

    return (
        <Container>
            <Header>
                <Img
                    src={LogoInputs}
                    alt="Logo gs noordhen"
                    className="border-0 shadow-none"
                    max-width="20%"
                    sm-max-width="40%"
                />
                <p>
                    A nova plataforma para atender nossos parceiros de forma
                    rápida e prática, disponivel 24 horas do dia e 365 dias do
                    ano.
                </p>
            </Header>
            <hr />
            <Section>
                <p>
                    Apresentamos ao mercado nossa nova plataforma digital INPUTS
                    360. Nossa nova plataforma digital para atender às
                    crescentes demandas do mercado Na Plataforma, mediante LOGIN
                    e SENHA você pode consultar dentre nossas centenas de
                    produtos, os mais indicados para finalidades específicas,
                    inserindo palavras chave como: louças, instantâneo,
                    banheiras, etc...
                    <br /> O sistema lhe apresenta todas as nossas soluções para
                    necessidades específicas.
                </p>
                <p>
                    Na plataforma INPUTS 360 você também obtém literaturas
                    comerciais fichas de segurança laudos de comprovação de
                    eficácia lâminas de publicidade sempre atualizadas.
                </p>
                <p>
                    E também, seus PRODUTOS PRIVATE LABEL e suas documentações
                    estão disponíveis separados conforme a linha de produtos de
                    cada cliente.
                </p>
                <p>Solicite seu Login e Senha aqui.</p>
            </Section>
            <Form className="col-md-6 col-sm-8" onSubmit={handleSubmit}>
                <Input
                    label="Nome*"
                    value={data.name}
                    onChange={(e) => setData({ ...data, name: e.target.value })}
                />
                <Input
                    label="Empresa*"
                    value={data.company}
                    onChange={(e) =>
                        setData({ ...data, company: e.target.value })
                    }
                />
                <Input
                    label="E-mail*"
                    value={data.email}
                    onChange={(e) =>
                        setData({ ...data, email: e.target.value })
                    }
                />
                <TextArea
                    label="Mensagem*"
                    value={data.message}
                    onChange={(e) =>
                        setData({ ...data, message: e.target.value })
                    }
                />
                <GridButtons>
                    <Button variant="success" type="submit">
                        <FaPaperPlane />
                        Enviar
                    </Button>
                </GridButtons>
            </Form>
        </Container>
    );
};

export default Index;
