import React from "react";
import { Link } from "react-router-dom";

import { FiPlusCircle } from "react-icons/fi";

import Container from "../../../Components/Admin/Container";
import Table, { ResponsiveTable } from "../../../Components/Table";

import useGraphicMaterials from "./useGraphicMaterials";

function GraphicMaterials() {
    useGraphicMaterials();

    return (
        <Container pageTitle="Materiais Gráficos">
            <div className="grid-add">
                <Link to="/ctop/material-grafico" className="add">
                    <FiPlusCircle />
                </Link>
            </div>
            <ResponsiveTable>
                <Table id="graphic-materials-table"></Table>
            </ResponsiveTable>
        </Container>
    );
}

export default GraphicMaterials;
