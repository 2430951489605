import { useState, useEffect } from "react";

import Api, { publicRequest } from "../../service/api";

import useLoading from "../../hooks/useLoading";

import { Error } from "../../Modules/Notifications";

function WhereToFind() {
    const [data, setData] = useState([]);

    const { loading } = useLoading();

    useEffect(() => {
        async function show() {
            const idLoading = loading();

            try {
                const response = await Api.get(
                    "/parceiros?_limit=-1&ativo=true",
                    publicRequest
                );

                const serializedData = response.data.map((item) => {
                    const {
                        id,
                        nome,
                        cidade,
                        estado,
                        endereco,
                        numero,
                        bairro,
                        telefone,
                        celular,
                    } = item;

                    return {
                        id,
                        nome,
                        cidade,
                        estado,
                        endereco,
                        numero,
                        bairro,
                        telefone,
                        celular,
                        arquivo: `${Api.defaults.baseURL}${item.arquivo.url}`,
                        imagem: `${Api.defaults.baseURL}${item.imagem.url}`,
                    };
                });

                setData(serializedData);

                loading(idLoading);
            } catch (error) {
                Error(error);

                loading(idLoading);
            }
        }

        show();
    }, [loading]);

    return {
        data,
    };
}

export default WhereToFind;
