import React from "react";
import ReactDOM from "react-dom";
import { Row } from "react-bootstrap";

import Menu from "../../Components/_CDOC/Menu";

import notification, {
    Error as notificationError,
} from "../../Modules/Notifications";

import Container from "../../Components/Container";
import Input from "../../Components/Input";
import Form from "../../Components/Form";
import ButtonsGrid from "../../Components/ButtonsGrid";
import Button from "../../Components/Button";
import Img from "../../Components/Img";

import api, { publicRequest } from "../../service/api";

import useLoading from "../../hooks/useLoading";

import LogoUniversidade from "../../Assets/Images/logo-ecobrite-universidade.png";

import useCDOCLogin from "./useCDOCLogin";

import { FormGrid, Header } from "./styles";

const CDOCLogin = () => {
    const { loading } = useLoading();
    const { terms, handleToggleTerms } = useCDOCLogin();

    const handleOnSubmit = async (event) => {
        event.preventDefault();

        const idLoading = loading();

        try {
            const CNPJ = document.querySelector("#login").value;
            const Senha = document.querySelector("#senha").value;

            if (!CNPJ) {
                notification("warning", "Login é obrigatório");
                loading(idLoading);
                return;
            }

            if (!Senha) {
                notification("warning", "Senha é obrigatório");
                loading(idLoading);
                return;
            }

            if (!terms) {
                notification("warning", "Concorde com os termos para avançar");
                loading(idLoading);
                return;
            }

            const response = await api.get(
                `/clientes?login=${CNPJ}&Senha=${Senha}`,
                publicRequest
            );

            if (response.data.length === 0) {
                notification("error", "Login ou senha inválidos");
                loading(idLoading);
                return;
            }

            if (!response.data[0].CDOC) {
                notification(
                    "error",
                    "Não possui permissão para acessar essa área"
                );
                loading(idLoading);
                return;
            }

            ReactDOM.render(
                <Menu client={response.data[0]} />,
                document.getElementById("root")
            );

            loading(idLoading);
        } catch (error) {
            notificationError(error);

            loading(idLoading);
        }
    };

    // useEffect(() => {
    //     document.querySelector("#login").value = "suporte";
    //     document.querySelector("#senha").value = "suporte";
    //     handleToggleTerms();

    //     const event = new MouseEvent("click", {
    //         view: window,
    //         bubbles: true,
    //         cancelable: true,
    //     });
    //     document.querySelector("button[type=submit] ").dispatchEvent(event);
    // }, [handleToggleTerms]);

    return (
        <Container>
            <Header>
                <Img
                    src={LogoUniversidade}
                    alt="Logo universidade ecobrite"
                    className="border-0 shadow-none"
                    max-width="20%"
                    sm-max-width="40%"
                />
            </Header>
            <hr />
            <div id="cdoc-main">
                <FormGrid md="6" sm="12">
                    <Form className="col" onSubmit={handleOnSubmit}>
                        <Input
                            label="Login*"
                            className="col"
                            type="text"
                            id="login"
                        />
                        <Input
                            label="Senha*"
                            className="col"
                            type="password"
                            id="senha"
                        />
                        <Row className="d-flex justify-center">
                            <label>
                                <input
                                    type="checkbox"
                                    checked={terms}
                                    onChange={handleToggleTerms}
                                />{" "}
                                Eu li e concordo com os{" "}
                                <a href="/termos-de-uso-cdoc" target="_black">
                                    Termos e condições de uso
                                </a>
                            </label>
                        </Row>
                        <ButtonsGrid>
                            <Button variant="success" type="submit">
                                Entrar
                            </Button>
                        </ButtonsGrid>
                    </Form>
                </FormGrid>
            </div>
        </Container>
    );
};

export default CDOCLogin;
