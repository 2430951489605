import React from "react";
import Container from "../../Components/Container";
import CategoryCatalog from "../../Components/CategoryCatalog";
import AplicationCatalog from "../../Components/AplicationCatalog";

const ProductsAndMarket = () => {
    return (
        <Container>
            <CategoryCatalog />
            <AplicationCatalog />
        </Container>
    );
};

export default ProductsAndMarket;
