import React, { useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { GrEdit } from "react-icons/gr";
import { FiTrash2 } from "react-icons/fi";
import { FaPaperPlane, FaEraser } from "react-icons/fa";
import { Col } from "react-bootstrap";

import Container from "../../../Components/Admin/Container";
import Input from "../../../Components/Input";
import Button from "../../../Components/Button";
import Form from "../../../Components/Form";
import Table, { ResponsiveTable } from "../../../Components/Table";

import api from "../../../service/api";

import useLoading from "../../../hooks/useLoading";

import Notification, { Error } from "../../../Modules/Notifications";
import Confirm from "../../../Modules/AlertConfirm";

const Index = (props) => {
    const history = useHistory();

    const { loading } = useLoading();

    const { id } = props.match.params;

    const [data, setData] = useState("");

    const [list, setList] = useState([]);

    const [title, setTitle] = useState("");

    useEffect(() => {
        if (!id) setTitle("Cadastrar Aplicação");
        else setTitle("Editar Aplicação");
    }, [id]);

    useEffect(() => {
        const show = async () => {
            const idLoading = loading();

            try {
                const response = await api.get(`/aplicacaos/${id}`);

                setData(response.data.Aplicacao);

                loading(idLoading);
            } catch (error) {
                Error(error);

                loading(idLoading);
            }
        };

        if (id) {
            show();
        } else {
            setData("");
        }
    }, [id, loading]);

    const Index = useCallback(async () => {
        const idLoading = loading();

        try {
            const response = await api.get(
                "/aplicacaos?_sort=Aplicacao:ASC&_limit=-1"
            );

            setList(response.data);

            loading(idLoading);
            return;
        } catch (error) {
            Error(error);

            loading(idLoading);
        }
    }, [loading]);

    useEffect(() => {
        Index();
    }, [Index]);

    const create = async () => {
        const idLoading = loading();

        try {
            await api.post("/aplicacaos", { Aplicacao: data });

            setData("");

            Notification("success", "Aplicação cadastrada");

            Index();

            loading(idLoading);
        } catch (error) {
            Error(error);

            loading(idLoading);
        }
    };

    const update = async () => {
        const idLoading = loading();

        try {
            await api.put(`/aplicacaos/${id}`, { Aplicacao: data });

            setData("");
            Notification("success", "Aplicação autalizada");
            Index();
            history.push("/ctop/produtos/aplicacoes/");

            loading(idLoading);
        } catch (error) {
            Error(error);

            loading(idLoading);
        }
    };

    const destroy = (id) => {
        Confirm(
            "Remover Aplicação",
            "Tem certeza que deseja remover ?",
            async () => {
                const idLoading = loading();

                try {
                    await api.delete(`/aplicacaos/${id}`);

                    Notification("success", "Aplicação removida");
                    Index();

                    loading(idLoading);
                } catch (error) {
                    Error(error);

                    loading(idLoading);
                }
            }
        );
    };

    const handleValidate = () => {
        return list.find((item) => item.Aplicacao === data)
            ? { status: false, message: "Aplicação já cadastrada" }
            : { status: true };
    };

    const submit = (event) => {
        event.preventDefault();

        const validate = handleValidate();
        if (!validate.status) {
            return Notification("error", validate.message);
        }

        if (!id) {
            return create();
        }

        return update();
    };

    return (
        <Container pageTitle={title}>
            <Form onSubmit={submit} className="row d-flex justify-center">
                <Col>
                    <Input
                        type="text"
                        label="Aplicação:"
                        onChange={(event) => {
                            setData(event.target.value);
                        }}
                        value={data}
                    />
                </Col>

                <Col className="d-flex align-items-center justify-content-around">
                    {id && (
                        <Button
                            variant="outline-warning"
                            onClick={() => {
                                history.push("/ctop/produtos/aplicacoes");
                            }}
                            className="ml-10"
                        >
                            <FaEraser />
                            Limpar
                        </Button>
                    )}
                    <Button type="submit" variant="success">
                        <FaPaperPlane />
                        Enviar
                    </Button>
                </Col>
            </Form>
            <ResponsiveTable>
                <Table>
                    <thead>
                        <tr>
                            <th>Aplicação</th>
                            <th>Editar</th>
                            <th>Remover</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list.map((item) => (
                            <tr key={item.id}>
                                <td>{item.Aplicacao}</td>
                                <td>
                                    <GrEdit
                                        className="cursor-pointer"
                                        onClick={() => {
                                            history.push(
                                                `/ctop/produtos/aplicacoes/${item.id}`
                                            );
                                        }}
                                    />
                                </td>
                                <td>
                                    <FiTrash2
                                        className="cursor-pointer"
                                        color="red"
                                        onClick={() => {
                                            destroy(item.id);
                                        }}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </ResponsiveTable>
        </Container>
    );
};

export default Index;
