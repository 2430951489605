import React, { useState, useCallback, useEffect } from "react";
import Styled from "styled-components";
import { Col, Row } from "react-bootstrap";
import { FaPaperPlane } from "react-icons/fa";

import { File } from "../../Components/Input";
import ButtonsGrid from "../../Components/ButtonsGrid";
import Button from "../../Components/Button";

import Api from "../../service/api";

import useLoading from "../../hooks/useLoading";

import Notification, { Error } from "../../Modules/Notifications";
import Confirm from "../../Modules/AlertConfirm";

const Container = Styled(Row)`
        margin-top: 2rem!important;
    `;

function Index() {
    const [data, setData] = useState({
        imagem: {},
        arquivo: {},
    });

    const [list, setList] = useState([]);

    const { loading } = useLoading();

    const index = useCallback(() => {
        async function index() {
            const idLoading = loading();

            try {
                const response = await Api.get("/configuracoes-cartoes");

                const serializedData = response.data.map((item) => ({
                    id: item.id,
                    imagem: {
                        id: item.imagem.id,
                        preview: `${Api.defaults.baseURL}${item.imagem.url}`,
                    },
                    arquivo: {
                        id: item.arquivo.id,
                        preview: `${Api.defaults.baseURL}${item.arquivo.url}`,
                    },
                }));

                setList(serializedData);

                loading(idLoading);
            } catch (error) {
                Error(error);

                loading(idLoading);
            }
        }

        index();
    }, [loading]);

    useEffect(() => {
        index();
    }, [index]);

    const uploadFile = async (file) => {
        const idLoading = loading();

        let data = new FormData();
        data.append(`files`, file);
        const response = await Api.post("/upload", data);

        loading(idLoading);

        return response.data[0];
    };

    async function create() {
        const idLoading = loading();

        try {
            if (Object.keys(data.imagem).length === 0) {
                return Notification("warning", "Imagem é obrigatório");
            }

            if (Object.keys(data.arquivo).length === 0) {
                return Notification("warning", "PDF é obrigatório");
            }

            const imagem = await uploadFile(data.imagem.file);

            const arquivo = await uploadFile(data.arquivo.file);

            await Api.post("/configuracoes-cartoes", {
                imagem,
                arquivo,
            });

            setData({
                imagem: {},
                arquivo: {},
            });

            index();

            Notification("success", "Cartão cadastrado");

            loading(idLoading);
        } catch (error) {
            Error(error);

            loading(idLoading);
        }
    }

    return (
        <Container>
            <Col md="7" sm="12">
                {list.map((item) => (
                    <Col
                        sm="12"
                        className="row d-flex justify-content-center align-items-center"
                    >
                        <File
                            className="col"
                            label="Imagem*"
                            width="200"
                            accept="image/*"
                            file={item.imagem}
                            onUpload={(files) => {
                                const file = files[0];

                                Confirm(
                                    "Alterar Imagem",
                                    "Tem certeza que deseja alterar ?",
                                    async () => {
                                        const idLoading = loading();

                                        try {
                                            await Api.delete(
                                                `/upload/files/${item.imagem.id}`
                                            );

                                            const imagem = await uploadFile(
                                                file
                                            );

                                            await Api.put(
                                                `/configuracoes-cartoes/${item.id}`,
                                                { imagem }
                                            );

                                            index();

                                            Notification(
                                                "success",
                                                "Imagem alterada"
                                            );

                                            loading(idLoading);
                                        } catch (error) {
                                            Error(error);

                                            loading(idLoading);
                                        }
                                    }
                                );
                            }}
                            onDeleteFile={() => {
                                Confirm(
                                    "Remover Cartão",
                                    "Tem certeza que deseja remover ?",
                                    async () => {
                                        const idLoading = loading();

                                        try {
                                            await Api.delete(
                                                `/configuracoes-cartoes/${item.id}`
                                            );

                                            index();
                                            Notification(
                                                "success",
                                                "Cartão removido"
                                            );

                                            loading(idLoading);
                                        } catch (error) {
                                            Error(error);

                                            loading(idLoading);
                                        }
                                    }
                                );
                            }}
                        />
                        <File
                            className="col"
                            label="PDF*"
                            width="200"
                            height="250px"
                            accept=".pdf"
                            fileType="pdf"
                            file={item.arquivo}
                            onUpload={(files) => {
                                const file = files[0];

                                Confirm(
                                    "Alterar PDF",
                                    "Tem certeza que deseja alterar ?",
                                    async () => {
                                        const idLoading = loading();

                                        try {
                                            await Api.delete(
                                                `/upload/files/${item.arquivo.id}`
                                            );

                                            const arquivo = await uploadFile(
                                                file
                                            );

                                            await Api.put(
                                                `/configuracoes-cartoes/${item.id}`,
                                                { arquivo }
                                            );

                                            index();

                                            Notification(
                                                "success",
                                                "PDF alterado"
                                            );

                                            loading(idLoading);
                                        } catch (error) {
                                            Error(error);

                                            loading(idLoading);
                                        }
                                    }
                                );
                            }}
                            onDeleteFile={() => {
                                Notification(
                                    "warning",
                                    "Não é possível remover arquivo do cartão"
                                );
                            }}
                        />
                    </Col>
                ))}
            </Col>
            <Col
                md="5"
                sm="12"
                className="row d-flex justify-content-center align-items-center"
            >
                <File
                    className="col"
                    label="Imagem*"
                    width="200"
                    accept="image/*"
                    file={data.imagem}
                    onUpload={(files) => {
                        const file = files[0];

                        setData({
                            ...data,
                            imagem: {
                                file,
                                preview: URL.createObjectURL(file),
                            },
                        });
                    }}
                    onDeleteFile={() => {
                        setData({
                            ...data,
                            imagem: {},
                        });
                    }}
                />
                <File
                    className="col"
                    label="PDF*"
                    width="200"
                    height="250px"
                    accept=".pdf"
                    fileType="pdf"
                    file={data.arquivo}
                    onUpload={(files) => {
                        const file = files[0];

                        setData({
                            ...data,
                            arquivo: {
                                file,
                                preview: URL.createObjectURL(file),
                            },
                        });
                    }}
                    onDeleteFile={() => {
                        setData({
                            ...data,
                            arquivo: {},
                        });
                    }}
                />
                <ButtonsGrid>
                    <Button variant="success" onClick={create}>
                        <FaPaperPlane />
                        Enviar
                    </Button>
                </ButtonsGrid>
            </Col>
        </Container>
    );
}

export default Index;
