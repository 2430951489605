import styled from "styled-components";

const Index = styled.aside`
    background-color: var(--blue);
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    height: 100vh;
    transition: width 200ms ease;
    min-width: 6rem;
    box-shadow: 2px 2px 3px -2px var(--black);
    font-family: var(--font-title);

    .toggle-menu {
        display: none;
    }

    nav {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
    }

    nav a,
    nav strong {
        width: 100%;
        text-decoration: none;
        color: var(--gray-5);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    nav a span,
    nav strong span {
        display: none;
        margin-right: 12px !important;
    }

    nav a svg,
    nav strong svg {
        margin: 12px auto !important;
    }

    nav a:hover,
    nav strong:hover {
        filter: brightness(120%);
    }

    &:hover nav a,
    &:hover nav strong {
        justify-content: initial;
    }

    &:hover nav a span,
    &:hover nav strong span {
        display: inline;
    }

    &:hover nav a svg,
    &:hover nav strong svg {
        margin: 12px 12px !important;
    }

    nav a:last-child,
    nav strong:last-child {
        margin-top: auto !important;
        margin-bottom: 5px !important;
    }

    @media (max-width: 750px) {
        height: 6rem;
        width: 100%;

        .navVisible {
            display: flex;
            height: auto;
        }

        .navNotVisible {
            display: none;
        }

        nav {
            align-items: center;
            width: 100%;
            display: none;
        }

        nav a,
        nav strong {
            justify-content: initial;
            align-items: center;
            background-color: var(--blue);
        }

        nav a svg,
        nav strong svg {
            margin: 12px 12px !important;
        }

        .toggle-menu {
            display: flex;
            width: 100% !important;
            height: 100% !important;
            align-items: center;
            color: var(--gray-5);
            transition: 200ms;

            &:hover {
                color: var(--white);
            }

            svg {
                margin-left: 24px !important;
            }

            span {
                margin: 0 auto !important;
            }
        }

        nav a span,
        nav strong span {
            display: inline;
        }
    }
`;

export default Index;
