import styled from "styled-components";

export const Container = styled.div`
    section {
        margin-top: 1rem !important;

        [class*="col-"] {
            @media (max-width: 900px) {
                margin-top: 1rem !important;

                &:first-child {
                    margin-top: 0 !important;
                }
            }
        }

        a {
            width: 200px;
            height: fit-content;
            flex: 0 0 200px;
            color: var(--blue);
            text-decoration: none;

            background-color: var(--white);

            box-shadow: var(--shadow);

            img {
                box-shadow: none;
            }

            h2 {
                font-weight: 700;
            }

            section {
                margin-top: 0 !important;
                padding: 1rem !important;

                .address-container {
                    margin-top: 0.5rem !important;

                    h3 {
                        font-size: 1.8rem !important;

                        display: flex;
                        align-items: center;

                        svg {
                            margin-right: 5px !important;
                            color: var(--red);
                        }
                    }

                    p {
                        font-size: 1.5rem;
                    }
                }

                .contact-container {
                    display: flex;
                    flex-direction: column;

                    font-size: 1.5rem;

                    span {
                        display: flex;
                        align-items: center;

                        margin-top: 0.5rem !important;

                        i {
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            width: 24px;
                            height: 24px;
                            flex: 0 0 24px;

                            border-radius: 12px;

                            margin-right: 5px !important;
                        }
                    }
                }

                p {
                    font-size: 1.5rem;
                }
            }
        }

        h2 {
            text-align: center;
            white-space: wrap;
            font-size: 2rem !important;
            line-height: 25px;
        }

        h2,
        h3,
        p {
            margin: 0 !important;
        }
    }
`;
