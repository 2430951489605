import styled from "styled-components";

const Index = styled.main`
    min-height: 53vh !important;
    padding: 5rem 5rem !important;

    @media (max-width: 750px) {
        min-height: 13vh !important;
        padding: 2rem 2rem !important;
    }
`;

export default Index;
