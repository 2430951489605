import React, { useState, useEffect, useCallback } from "react";
import { Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { FaEraser, FaPaperPlane } from "react-icons/fa";
import { GrEdit } from "react-icons/gr";
import { FiTrash2 } from "react-icons/fi";

import Container from "../../../Components/Admin/Container";
import Form from "../../../Components/Form";
import Input from "../../../Components/Input";
import Button from "../../../Components/Button";
import Table, { ResponsiveTable } from "../../../Components/Table";

import Notification, { Error } from "../../../Modules/Notifications";
import Confirm from "../../../Modules/AlertConfirm";

import Api from "../../../service/api";

import useLoading from "../../../hooks/useLoading";

function Index({ match }) {
    const history = useHistory();

    const { loading } = useLoading();

    const { id } = match.params;

    const [data, setData] = useState("");

    const [list, setList] = useState([]);

    const index = useCallback(() => {
        async function index() {
            const idLoading = loading();

            try {
                const response = await Api.get("/videos-treinamentos");

                setList(response.data);

                loading(idLoading);
            } catch (error) {
                Error(error);

                loading(idLoading);
            }
        }

        index();
    }, [loading]);

    useEffect(() => {
        async function show() {
            const idLoading = loading();

            try {
                const response = await Api.get(`/videos-treinamentos/${id}`);

                setData(response.data.url);

                loading(idLoading);
            } catch (error) {
                Error(error);

                loading(idLoading);
            }
        }

        if (id) show();
        else setData("");
    }, [id, loading]);

    useEffect(() => {
        index();
    });

    function destroy(id) {
        Confirm(
            "Remover Vídeo",
            "Tem certeza que deseja remover ?",
            async () => {
                const idLoading = loading();

                try {
                    await Api.delete(`/videos-treinamentos/${id}`);

                    Notification("success", "Vídeo de treinamento removido");
                    index();

                    loading(idLoading);
                } catch (error) {
                    Error(error);

                    loading(idLoading);
                }
            }
        );
    }

    async function update() {
        const idLoading = loading();

        try {
            await Api.put(`/videos-treinamentos/${id}`, { url: data });

            index();
            Notification("success", "Vídeo de treinamento alterado");

            loading(idLoading);
        } catch (error) {
            Error(error);

            loading(idLoading);
        }
    }

    async function create() {
        const idLoading = loading();

        try {
            await Api.post("/videos-treinamentos", { url: data });

            index();

            setData("");

            Notification("success", "Vídeo de treinamento cadastrado");

            loading(idLoading);
        } catch (error) {
            Error(error);

            loading(idLoading);
        }
    }

    function submit(e) {
        e.preventDefault();
        if (!data) {
            return Notification("warning", "URL é obrigatório");
        }

        if (id) {
            return update();
        }

        return create();
    }

    return (
        <Container pageTitle="Vídeos de Treinamento">
            <Form onSubmit={submit} className="row d-flex justify-center">
                <Col>
                    <Input
                        type="text"
                        id="product-category"
                        name="Linha"
                        label="URL*"
                        value={data}
                        onChange={(event) => {
                            setData(event.target.value);
                        }}
                    />
                </Col>
                <Col className="d-flex align-items-center justify-content-around">
                    {id && (
                        <Button
                            variant="outline-warning"
                            onClick={() => {
                                history.push("/ctop/videos-treinamentos/");
                            }}
                        >
                            <FaEraser /> Limpar
                        </Button>
                    )}
                    <Button type="submit" variant="success">
                        <FaPaperPlane />
                        Enviar
                    </Button>
                </Col>
            </Form>
            <ResponsiveTable>
                <Table>
                    <thead>
                        <tr>
                            <th>Vídeo</th>
                            <th>Editar</th>
                            <th>Remover</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list.map((line) => (
                            <tr key={line.id}>
                                <td>{line.url}</td>
                                <td>
                                    <GrEdit
                                        className="cursor-pointer"
                                        onClick={() => {
                                            history.push(
                                                `/ctop/videos-treinamentos/${line.id}`
                                            );
                                        }}
                                    />
                                </td>
                                <td>
                                    <FiTrash2
                                        color="red"
                                        className="cursor-pointer"
                                        onClick={() => {
                                            destroy(line.id);
                                        }}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </ResponsiveTable>
        </Container>
    );
}

export default Index;
