import styled from "styled-components";

const Index = styled.main`
    width: 100%;
    padding: 2rem 2rem 6rem 10rem !important;

    h2 {
        margin-bottom: 1rem !important;
    }

    @media (max-width: 750px) {
        padding: 6rem 0 0 0 !important;
    }
`;

export default Index;
