import styled from "styled-components";

export const Container = styled.div``;

export const Training = styled.a`
    width: 50%;
`;

export const TrainingVideoGrid = styled.div.attrs({
    className: "col-md-3 col-sm-12",
})`
    padding: 1rem !important;
`;
