import React, { useCallback, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import ReactDOM from "react-dom";
import { FiFolder, FiPlusCircle, FiX, FiCheck } from "react-icons/fi";
import { FaFilm } from "react-icons/fa";

import Api from "../../../service/api";

import useLoading from "../../../hooks/useLoading";

import Container from "../../../Components/Admin/Container";
import Table, { ResponsiveTable } from "../../../Components/Table";
import DataTables from "../../../Modules/DataTables";
import Button from "../../../Components/Button";

function Index() {
    const history = useHistory();

    const { loading } = useLoading();

    const setReactComponentinTable = useCallback(() => {
        const handleEdit = (id) => {
            history.push(`/ctop/treinamento/${id}`);
        };

        const setReactComponentinTable = () => {
            const editarEls = document.querySelectorAll(".editar");

            for (const editarEl of editarEls) {
                ReactDOM.render(
                    <FiFolder
                        className="cursor-pointer"
                        onClick={() => {
                            handleEdit(editarEl.dataset.id);
                        }}
                    />,
                    document.getElementById(editarEl.id)
                );
            }

            const ativosEls = document.querySelectorAll(".ativo");

            for (const ativosEl of ativosEls) {
                ReactDOM.render(
                    ativosEl.dataset.value === "true" ? (
                        <FiCheck color="green" />
                    ) : (
                        <FiX color="red" />
                    ),
                    document.getElementById(ativosEl.id)
                );
            }
        };

        setReactComponentinTable();
    }, [history]);

    useEffect(() => {
        const show = async () => {
            const idLoading = loading();

            try {
                const response = await Api.get("/treinamentos?_limit=-1");

                const serializedData = response.data.map((item) => {
                    const { id, nome, ativo } = item;

                    return {
                        nome,
                        ativo: `<div class="ativo" data-value="${ativo}" id="ativo-${id}"></div>`,
                        Abrir: `<div class="editar" data-id="${id}" id="editar-${id}"></div>`,
                    };
                });

                DataTables(
                    "#trainings-table",
                    serializedData,
                    [
                        { title: "Nome", data: "nome" },
                        { title: "Ativo", data: "ativo" },
                        { title: "Abrir", data: "Abrir" },
                    ],
                    () => {
                        setReactComponentinTable();
                    }
                );

                loading(idLoading);
            } catch (error) {
                Error(error);

                loading(idLoading);
            }
        };

        show();
    }, [loading, setReactComponentinTable]);

    return (
        <Container pageTitle="Treinamentos">
            <div className="grid-add">
                <Link to="/ctop/treinamento" className="add">
                    <FiPlusCircle />
                </Link>
                <Button
                    onClick={() => {
                        history.push("/ctop/videos-treinamentos");
                    }}
                    variant="success"
                >
                    <FaFilm />
                    Vídeos
                </Button>
            </div>
            <ResponsiveTable>
                <Table id="trainings-table"></Table>
            </ResponsiveTable>
        </Container>
    );
}

export default Index;
