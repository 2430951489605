import React from "react";

import { Container } from "./styles";
import { File, uploadFile } from "../Input";

import notification, { Error } from "../../Modules/Notifications";

import useLoading from "../../hooks/useLoading";

import api from "../../service/api";

function Index({ id, uploadedFile, type, reload }) {
    const { loading } = useLoading();

    const docs = {
        CDOC1: "Literatura",
        CDOC2: "FISPQ",
        CDOC3: "Laudos",
        CDOC4: "Como vender o produto",
    };

    async function create(file) {
        const idLoading = loading();

        try {
            const uploadedFile = await uploadFile(file);

            await api.put(`/produtos/${id}`, {
                [type]: `${api.defaults.baseURL}${uploadedFile.url}`,
            });

            notification("success", `${docs[type]} cadastrado`);

            loading(idLoading);
        } catch (error) {
            Error(error);

            loading(idLoading);
        }
    }

    async function update(file) {
        const idLoading = loading();

        try {
            const uploadedFile = await uploadFile(file);

            await api.put(`/produtos/${id}`, {
                [type]: `${api.defaults.baseURL}${uploadedFile.url}`,
            });

            notification("success", `${docs[type]} atualizado`);

            loading(idLoading);
        } catch (error) {
            Error(error);

            loading(idLoading);
        }
    }

    return (
        <Container>
            <File
                accept="application/pdf"
                fileType="pdf"
                height="150px"
                width="125"
                file={uploadedFile ? { preview: uploadedFile } : {}}
                scale={0.35}
                onUpload={async (files) => {
                    const file = files[0];

                    if (uploadedFile) {
                        await update(file);
                    } else {
                        await create(file);
                    }

                    reload();
                }}
                onDeleteFile={async () => {
                    const idLoading = loading();

                    try {
                        if (!uploadedFile) return;

                        await api.put(`/produtos/${id}`, { [type]: "" });

                        notification("success", `${docs[type]} removido`);

                        reload();

                        loading(idLoading);
                    } catch (error) {
                        Error(error);

                        loading(idLoading);
                    }
                }}
            />
        </Container>
    );
}

export default Index;
