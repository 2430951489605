import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";

import Logo from "../../Assets/Images/logo-ecobrite.png";
import LogoSvs from "../../Assets/Images/logo-svs.png";
import Facebook from "../../Assets/Images/FACE.jpg";
import Instagram from "../../Assets/Images/INSTA.jpg";
import Whatsapp from "../../Assets/Images/WHATS.jpg";
import Youtube from "../../Assets/Images/YOUTUBE.jpg";

import Img from "../../Components/Img";

import { Error } from "../../Modules/Notifications";

import Api, { publicRequest } from "../../service/api";

import useLoading from "../../hooks/useLoading";

import "./styles.css";

const Footer = () => {
    const [company, setCompany] = useState("");

    const { loading } = useLoading();

    useEffect(() => {
        async function showCompany() {
            const idLoading = loading();

            try {
                const response = await Api.get(
                    "/configuracoes-cartoes?_limit=1",
                    publicRequest
                );

                if (response.data.length === 0) {
                    loading(idLoading);
                    return;
                }

                const company = `${Api.defaults.baseURL}${response.data[0].arquivo.url}`;
                setCompany(company);

                loading(idLoading);
            } catch (error) {
                Error(error);

                loading(idLoading);
            }
        }

        showCompany();
    }, [loading]);

    return (
        <Row className="footer m-0">
            <Col sm="12" md="3" className="footer-nav">
                <Link to="/">Página Inicial</Link>
                {company && (
                    <a href={company} target="_blank" rel="noreferrer noopener">
                        Empresa
                    </a>
                )}
                <Link to="/central-de-documentos">Universidade Ecobrite</Link>
                <Link to="/produtos-e-mercado">Produtos</Link>
                <Link to="/onde-encontrar">Onde Encontrar</Link>
                <Link to="/seja-parceiro">Seja um parceiro</Link>
                <Link to="/contato">Contato</Link>
            </Col>
            <Col sm="12" md="6" className="footer-copyright">
                <Row className="d-flex justify-content-between ">
                    <p>
                        Ecobrite - Copyright © 2020 | Todos os direitos
                        reservados.
                    </p>
                    <a
                        href="https://www.svs.com.br/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Img
                            src={LogoSvs}
                            max-width="64px"
                            max-height="30px"
                            className="align-self-end"
                        />
                    </a>
                </Row>
            </Col>
            <Col sm="12" md="3" className="footer-nordhen">
                <Col sm="10" className="footer-nordhen-container">
                    <Col className="footer-logo">
                        <Img
                            className="border-0 shadow-none"
                            src={Logo}
                            max-width="120px"
                        ></Img>
                    </Col>
                    <Row className="footer-social_media">
                        <a
                            href="https://www.facebook.com/pages/Noordhen-Brasil/549914461844925"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Img
                                src={Facebook}
                                className="border-0 shadow-none"
                                max-width="32px"
                            />
                        </a>
                        <a
                            href="https://www.instagram.com/noordhenbrasil/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Img
                                src={Instagram}
                                className="border-0 shadow-none"
                                max-width="32px"
                            />
                        </a>
                        <a
                            href="https://www.youtube.com/channel/UCkWBokjxZenW_qnqhJtyAVQ"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Img
                                src={Youtube}
                                className="border-0 shadow-none"
                                max-width="32px"
                            />
                        </a>
                        <a
                            href="https://api.whatsapp.com/send?phone=5512982205641"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Img
                                src={Whatsapp}
                                className="border-0 shadow-none"
                                max-width="32px"
                            />
                        </a>
                    </Row>
                </Col>
            </Col>
        </Row>
    );
};

export default Footer;
