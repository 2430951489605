import { useCallback, useState } from "react";

function useCDOCLogin() {
    const [terms, setTerms] = useState(false);

    const handleToggleTerms = useCallback(() => {
        setTerms(!terms);
    }, [terms]);

    return { terms, handleToggleTerms };
}

export default useCDOCLogin;
