import React, { useState } from "react";
import {
    FaBars,
    FaHome,
    FaSignOutAlt,
    FaGraduationCap,
    FaBox,
    FaEnvelope,
    FaCertificate,
    FaCalendarDay,
} from "react-icons/fa";
import { MdNewReleases } from "react-icons/md";
import { AiOutlineAntDesign } from "react-icons/ai";

import "../../../Pages/CDOC";

import Aside from "../../Admin/Aside";
import Main from "../../Admin/Main";

import useMenu from "./useMenu";

import { Root as RootLoading } from "../../../hooks/useLoading";

import { Container } from "./styles";

function CDOCNavbar({ client }) {
    const [navVisible, setNavVisible] = useState("navNotVisible");

    const {
        handleOnClickProduct,
        handleOnClickTrainings,
        handleOnClickContact,
        handleSignOut,
        handleOnClickHome,
        handleOnClickHomologatedBrands,
        handleOnClickGraphicMaterial,
        handleOnClickEvents,
        handleOnClickReleases,
    } = useMenu(client);

    return (
        <>
            <RootLoading />
            <Container>
                <Aside>
                    <div
                        className="toggle-menu cursor-pointer"
                        onClick={() => {
                            if (navVisible === "navNotVisible") {
                                return setNavVisible("navVisible");
                            }

                            return setNavVisible("navNotVisible");
                        }}
                    >
                        <FaBars />
                        <span>Menu</span>
                    </div>
                    <nav className={navVisible}>
                        <strong onClick={handleOnClickHome}>
                            <FaHome />
                            <span>Home</span>
                        </strong>

                        <strong onClick={handleOnClickReleases}>
                            <MdNewReleases />
                            <span>Lançamentos</span>
                        </strong>

                        <strong onClick={handleOnClickTrainings}>
                            <FaGraduationCap />
                            <span>Treinamentos</span>
                        </strong>

                        <strong onClick={handleOnClickProduct}>
                            <FaBox />
                            <span>Produtos</span>
                        </strong>

                        <strong onClick={handleOnClickHomologatedBrands}>
                            <FaCertificate />
                            <span>Marcas Homologadas</span>
                        </strong>

                        <strong onClick={handleOnClickGraphicMaterial}>
                            <AiOutlineAntDesign />
                            <span>Material Gráfico</span>
                        </strong>

                        <strong onClick={handleOnClickEvents}>
                            <FaCalendarDay />
                            <span>Eventos</span>
                        </strong>

                        <strong onClick={handleOnClickContact}>
                            <FaEnvelope />
                            <span>Contato</span>
                        </strong>

                        <strong onClick={handleSignOut}>
                            <FaSignOutAlt />
                            <span>Sair</span>
                        </strong>
                    </nav>
                </Aside>
                <Main id="cdoc-main"></Main>
            </Container>
        </>
    );
}

export default CDOCNavbar;
