import { useEffect, useState } from "react";

import api, { publicRequest } from "../../../service/api";

import { Error as notificationError } from "../../../Modules/Notifications";

import useLoading from "../../../hooks/useLoading";

function useReleases(client) {
    const [data, setData] = useState([]);

    const { loading } = useLoading();

    useEffect(() => {
        async function index() {
            const idLoading = loading();

            try {
                const response = await api.get(
                    "/lancamentos?_limit=-1",
                    publicRequest
                );

                const serializedData = response.data.map((item) => {
                    return {
                        id: item.id,
                        nome: item.nome,
                        arquivo: `${api.defaults.baseURL}${item.arquivo.url}`,
                        imagem: `${api.defaults.baseURL}${item.imagem.url}`,
                    };
                });

                setData(serializedData);

                loading(idLoading);
            } catch (error) {
                notificationError(error);

                loading(idLoading);
            }
        }

        index();
    }, [loading]);

    async function createLogCDOC(cliente, titulo) {
        try {
            api.post(
                "/log-cdocs",
                {
                    cliente,
                    cdoc: 6,
                    titulo,
                },
                publicRequest
            );
        } catch (error) {
            return console.error(error);
        }
    }

    function handleSelectDocument(document, title) {
        if (typeof document === "undefined") return;

        window.open(document, "_blank");
        createLogCDOC(client, title);
    }

    return { data, handleSelectDocument };
}

export default useReleases;
