import styled from "styled-components";
import { Table } from "react-bootstrap";

export const ResponsiveTable = styled.div`
    overflow-x: auto;
    margin: 1rem !important;
`;

const Index = styled(Table)`
    background-color: var(--white);
    border-radius: 4px;

    thead {
        background-color: var(--blue) !important;
        color: var(--white);
    }

    th:nth-child {
        margin: 0 auto !important;
    }

    th,
    td {
        padding: 0.5rem 1rem !important;
    }

    tbody tr:hover {
        background-color: var(--gray-5);
    }
`;

export default Index;
