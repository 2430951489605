import React from "react";
import Slider from "react-slick";
import { FaMapMarkerAlt, FaPhoneAlt, FaPaperPlane } from "react-icons/fa";

import PublicContainer from "../../Components/Container";
import Form from "../../Components/Form";
import Input, { TextArea } from "../../Components/Input";
import Img from "../../Components/Img";
import Button from "../../Components/Button";

import usePartner from "./usePartner";

import { Container } from "./styles";
import { Col, Row } from "react-bootstrap";

function PublicPartner(props) {
    const { id } = props.match.params;

    const {
        carouselSettings,
        data,
        contact,
        handleContactInputOnChange,
        handleSubmitContact,
    } = usePartner(id);

    return (
        <PublicContainer>
            <Container>
                <header>
                    <h1>Onde Encontrar</h1>
                </header>
                <hr />
                <section>
                    <header className="partner-header-container">
                        <div className="col partner-wellcome-container">
                            <h2>
                                Bem vindo a <br />
                                {data.nome}
                            </h2>
                        </div>
                        {data.imagem && (
                            <div className="col partner-image-container">
                                <img src={data.imagem} alt="Ecobrite Taubaté" />
                            </div>
                        )}
                    </header>
                    <section className="carousel-container">
                        <Slider {...carouselSettings}>
                            {data.carousel &&
                                Array.isArray(data.carousel) &&
                                !!data.carousel.length &&
                                data.carousel.map((item, index) => (
                                    <Img
                                        key={index}
                                        src={item}
                                        className="shadow-none border-0"
                                        alt="Destaque Noordhen Higiene Aplicada"
                                        width="100%"
                                    />
                                ))}
                        </Slider>
                    </section>
                    <section className="partner-map-container">
                        {data.lat && data.long && (
                            <iframe
                                title="Localização Ecobrite"
                                width="100%"
                                height="500"
                                frameborder="0"
                                scrolling="no"
                                marginheight="0"
                                marginwidth="0"
                                src={`https://maps.google.com/maps?q=${data.lat},${data.long}&hl=pt-br&z=18&amp&output=embed`}
                            ></iframe>
                        )}
                    </section>
                    <section className="partner-contact-container">
                        <Row className="partner-informations-container">
                            <Col
                                md="6"
                                sm="12"
                                className="partner-address-container"
                            >
                                <h3>
                                    <FaMapMarkerAlt /> {data.cidade} -{" "}
                                    {data.estado}
                                </h3>
                                <p>
                                    {data.endereco}, {data.numero} <br />
                                    {data.bairro}
                                </p>

                                <div className="partner-contact-information-container">
                                    <span>
                                        <i
                                            style={{
                                                background: "var(--blue)",
                                                color: "var(--white)",
                                            }}
                                        >
                                            <FaPhoneAlt />
                                        </i>{" "}
                                        {data.telefone}
                                    </span>
                                    <span>
                                        <i
                                            style={{
                                                background: "var(--green)",
                                                color: "var(--white)",
                                            }}
                                        >
                                            <FaPhoneAlt />
                                        </i>{" "}
                                        {data.celular}
                                    </span>
                                </div>
                            </Col>

                            <Col
                                md="6"
                                sm="12"
                                className="partner-contact-form-container"
                            >
                                <Form onSubmit={handleSubmitContact}>
                                    <h3>Envie-nos uma mensagem</h3>

                                    <Input
                                        placeholder="Nome*"
                                        name="nome"
                                        value={contact.nome}
                                        onChange={handleContactInputOnChange}
                                    />

                                    <Input
                                        placeholder="Cidade*"
                                        name="cidade"
                                        value={contact.cidade}
                                        onChange={handleContactInputOnChange}
                                    />

                                    <Input
                                        placeholder="Telefone*"
                                        name="telefone"
                                        value={contact.telefone}
                                        onChange={handleContactInputOnChange}
                                    />

                                    <Input
                                        placeholder="Celular*"
                                        name="celular"
                                        value={contact.celular}
                                        onChange={handleContactInputOnChange}
                                    />

                                    <TextArea
                                        placeholder="Informe o melhor horário para contato*"
                                        name="horario_contato"
                                        value={contact.horario_contato}
                                        onChange={handleContactInputOnChange}
                                    />

                                    <Input
                                        placeholder="E-mail*"
                                        name="email"
                                        value={contact.email}
                                        onChange={handleContactInputOnChange}
                                    />

                                    <TextArea
                                        placeholder="Mensagem*"
                                        name="mensagem"
                                        value={contact.mensagem}
                                        onChange={handleContactInputOnChange}
                                    />

                                    <Button type="submit" variant="success">
                                        <FaPaperPlane />
                                        Enviar
                                    </Button>
                                </Form>
                            </Col>
                        </Row>
                    </section>
                </section>
            </Container>
        </PublicContainer>
    );
}

export default PublicPartner;
