import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { FaPaperPlane, FaTrash } from "react-icons/fa";
import Select from "react-select";
import Styled from "styled-components";

import "../../../Assets/Style/global.css";

import Container from "../../../Components/Admin/Container";
import Fieldset from "../../../Components/Fieldset";
import Input, { File, Switch } from "../../../Components/Input";
import Button from "../../../Components/Button";
import Form from "../../../Components/Form";
import Application from "../../../Components/Application";
import Line from "../../../Components/Line";
import Video from "../../../Components/Video";
import ButtonsGrid from "../../../Components/ButtonsGrid";
import Label from "../../../Components/Label";

import Notification, { Error } from "../../../Modules/Notifications";
import Confirm from "../../../Modules/AlertConfirm";

import api from "../../../service/api";

import useLoading from "../../../hooks/useLoading";

const GridFile = Styled(Col)`
    margin-top: 0.5rem!important;
`;

const Index = (props) => {
    const history = useHistory();

    const { id } = props.match.params;

    const { loading } = useLoading();

    const [data, setData] = useState({});

    const [imageProduct, setImageProduct] = useState({});

    const [CDOC1, setCDOC1] = useState({});

    const [CDOC2, setCDOC2] = useState({});

    const [CDOC3, setCDOC3] = useState({});

    const [CDOC4, setCDOC4] = useState({});

    const [productInfo, setProductInfo] = useState({});

    const [linhas, setLinhas] = useState([]);

    const [aplicacoes, setAplicacoes] = useState([]);

    const [videos, setVideos] = useState([]);

    const [clients, setClients] = useState([]);

    const [productsInfos, setProductsInfos] = useState([]);

    useEffect(() => {
        const result = Object.fromEntries(
            new URLSearchParams(String(props.location.search).replace("?", ""))
        );

        if (result.goto === "cdoc5") {
            document.querySelector("#cdoc5-container").scrollIntoView();
        }
    }, [props]);

    useEffect(() => {
        async function indexLinhas() {
            const idLoading = loading();

            const response = await api.get("/linhas");

            setLinhas(response.data);

            loading(idLoading);
        }

        async function indexAplicacoes() {
            const idLoading = loading();

            const response = await api.get(
                "/aplicacaos?_sort=Aplicacao:ASC&_limit=-1"
            );

            setAplicacoes(response.data);

            loading(idLoading);
        }

        indexLinhas();

        indexAplicacoes();
    }, [loading]);

    const show = useCallback(() => {
        async function show() {
            const idLoading = loading();

            try {
                const response = await api.get(`/produtos/${id}`);

                response.data.aplicacaos = response.data.aplicacaos.map(
                    (item) => item.id
                );

                response.data.clientes = response.data.clientes.map((item) => ({
                    value: item.id,
                    label: item.Nome,
                }));

                response.data.linha = response.data.linha.id;

                setVideos(response.data.videos);

                delete response.data.videos;

                setImageProduct({ preview: response.data.image });

                setCDOC1({ preview: response.data.CDOC1 });

                setCDOC2({ preview: response.data.CDOC2 });

                setCDOC3({ preview: response.data.CDOC3 });

                setCDOC4({ preview: response.data.CDOC4 });

                response.data.exclusivo = response.data.exclusivo
                    ? response.data.exclusivo
                    : false;

                const productsInfos = response.data.produtos_informacoes.map(
                    (item) => {
                        return {
                            id: item.id,
                            idFile: item.arquivo.id,
                            preview: `${api.defaults.baseURL}${item.arquivo.url}`,
                        };
                    }
                );

                setProductsInfos(productsInfos);

                setData(response.data);

                loading(idLoading);
            } catch (error) {
                Error(error);

                loading(idLoading);
            }
        }

        if (id) show();
    }, [id, loading]);

    useEffect(() => {
        show();
    }, [show]);

    useEffect(() => {
        async function index() {
            const idLoading = loading();

            try {
                if (data.exclusivo) {
                    const response = await api.get("/clientes?_limit=-1");

                    const serializedData = response.data.map((item) => ({
                        value: item.id,
                        label: item.Nome,
                    }));

                    setClients(serializedData);
                } else {
                    setClients([]);
                }

                loading(idLoading);
            } catch (error) {
                Error(error);

                loading(idLoading);
            }
        }

        index();
    }, [data, loading]);

    function checked(check) {
        return data.aplicacaos && data.aplicacaos.includes(check);
    }

    function handleAddVideo() {
        const videoUrl = document.querySelector("#video-url").value;

        if (!videoUrl) return;

        setVideos([...videos, { link: videoUrl }]);

        document.querySelector("#video-url").value = "";
    }

    const handleValidate = (data) => {
        if (!data.Titulo) {
            return { status: false, message: "Informar título é obrigatório" };
        }

        if (data.aplicacaos.length === 0) {
            return {
                status: false,
                message: "Informar uma aplicação é obrigatório",
            };
        }

        if (!data.linha)
            return {
                status: false,
                message: "Informar uma linha é obrigatório",
            };

        return { status: true };
    };

    const create = async (data) => {
        const idLoading = loading();

        try {
            const response = await api.post("/produtos", data);

            await uploadFiles(response.data.id);

            await uploadVideos(response.data.id);

            productsInfos.forEach(async (item) => {
                const arquivo = await uploadFile(item.file);

                await api.post("/produtos-informacoes", {
                    arquivo,
                    produto: response.data.id,
                });
            });

            Notification("success", "Produto cadastrado");

            history.push("/ctop/produtos");

            loading(idLoading);

            return response.data;
        } catch (error) {
            Error(error);

            loading(idLoading);
        }
    };

    async function update(data) {
        const idLoading = loading();

        try {
            const response = await api.put(`/produtos/${id}`, data);

            await uploadFiles(response.data.id);

            await uploadVideos(response.data.id);

            Notification("success", "Produto alterado");
            history.push("/ctop/produtos");

            loading(idLoading);
        } catch (error) {
            Error(error);

            loading(idLoading);
        }
    }

    const uploadVideos = async (id) => {
        const idLoading = loading();
        for (const video of videos) {
            if (!video.id) {
                await api.post("/videos", {
                    link: video.link,
                    produto: id,
                });
            }
        }
        loading(idLoading);
    };

    async function uploadFile(file) {
        const idLoading = loading();

        let data = new FormData();
        data.append("files", file);

        const response = await api.post("/upload", data);

        loading(idLoading);

        return response.data[0];
    }

    const uploadFiles = async (id) => {
        const idLoading = loading();

        try {
            const Image = imageProduct.file;
            if (Image) {
                const response = await uploadFile(Image);

                await api.put(`/produtos/${id}`, {
                    image: `${api.defaults.baseURL}${response.url}`,
                });
            } else if (!imageProduct.preview) {
                await api.put(`/produtos/${id}`, {
                    image: "",
                });
            }

            const cDoc1File = CDOC1.file;
            if (cDoc1File) {
                const response = await uploadFile(cDoc1File);

                await api.put(`/produtos/${id}`, {
                    CDOC1: `${api.defaults.baseURL}${response.url}`,
                });
            } else if (!CDOC1.preview) {
                await api.put(`/produtos/${id}`, {
                    CDOC1: "",
                });
            }

            const cDoc2File = CDOC2.file;
            if (cDoc2File) {
                const response = await uploadFile(cDoc2File);

                await api.put(`/produtos/${id}`, {
                    CDOC2: `${api.defaults.baseURL}${response.url}`,
                });
            } else if (!CDOC2.preview) {
                await api.put(`/produtos/${id}`, {
                    CDOC2: "",
                });
            }

            const cDoc3File = CDOC3.file;
            if (cDoc3File) {
                const response = await uploadFile(cDoc3File);

                await api.put(`/produtos/${id}`, {
                    CDOC3: `${api.defaults.baseURL}${response.url}`,
                });
            } else if (!CDOC3.preview) {
                await api.put(`/produtos/${id}`, {
                    CDOC3: "",
                });
            }

            const cDoc4File = CDOC4.file;
            if (cDoc4File) {
                const response = await uploadFile(cDoc4File);

                await api.put(`/produtos/${id}`, {
                    CDOC4: `${api.defaults.baseURL}${response.url}`,
                });
            } else if (!CDOC4.preview) {
                await api.put(`/produtos/${id}`, {
                    CDOC4: "",
                });
            }

            loading(idLoading);
        } catch (error) {
            Error(error);

            loading(idLoading);
        }
    };

    const processData = (data) => {
        if (Array.isArray(data.clientes)) {
            data.clientes = data.clientes.map((item) => item.value);
        }

        return data;
    };

    const submit = async (event) => {
        try {
            event.preventDefault();
            let processedData = processData(data);

            const validate = handleValidate(processedData);
            if (!validate.status) {
                Notification("warning", validate.message);
                return;
            }

            if (!id) return create(processedData);

            return update(processedData);
        } catch (error) {
            Error(error);
        }
    };

    const destroy = async (event) => {
        event.preventDefault();

        Confirm(
            "Remover Produto",
            "Tem certeza que deseja remover ?",
            async () => {
                const idLoading = loading();

                try {
                    await api.delete(`/produtos/${id}`);

                    Notification("success", "Produto removido");
                    history.push("/ctop/produtos");

                    loading(idLoading);
                } catch (error) {
                    Error(error);

                    loading(idLoading);
                }
            }
        );
    };

    async function createProductInfo() {
        const idLoading = loading();

        try {
            if (Object.keys(productInfo).length === 0) {
                Notification("warning", "Arquivo é obrigatório");

                loading(idLoading);
                return;
            }

            if (id) {
                const arquivo = await uploadFile(productInfo.file);

                await api.post("/produtos-informacoes", {
                    arquivo,
                    produto: id,
                });
                show();
                Notification(
                    "success",
                    "Informação sobre o produto cadastrada"
                );
            } else {
                setProductsInfos([...productsInfos, productInfo]);
            }
            setProductInfo({});

            loading(idLoading);
        } catch (error) {
            Error(error);

            loading(idLoading);
        }
    }

    return (
        <Container
            pageTitle={`${
                !props.match.params.id ? "Cadastrar" : "Editar"
            } Produto`}
        >
            <Form onSubmit={submit}>
                <div className="row">
                    <Col className="d-flex justify-center align-items-center">
                        <File
                            label="Imagem:"
                            accept="image/*"
                            file={imageProduct}
                            width="280"
                            height="154px"
                            onUpload={(files) => {
                                const file = files[0];

                                setImageProduct({
                                    file,
                                    preview: URL.createObjectURL(file),
                                });
                            }}
                            onDeleteFile={() => {
                                setImageProduct({});
                            }}
                        />
                    </Col>
                    <Col>
                        <Input
                            type="text"
                            id="Titulo"
                            label="Título:"
                            value={data.Titulo}
                            onChange={(event) => {
                                setData({
                                    ...data,
                                    Titulo: event.target.value,
                                });
                            }}
                        />
                    </Col>
                </div>
                <Fieldset>
                    <header className="d-flex justify-content-between align-items-center">
                        <h3>Exclusivo</h3>
                        <Switch
                            id="exclusivo"
                            label="Ativo"
                            checked={data.exclusivo}
                            onChange={() => {
                                setData({
                                    ...data,
                                    exclusivo: !data.exclusivo,
                                });
                            }}
                        />
                    </header>
                    {clients.length > 0 && (
                        <section className="d-flex flex-wrap">
                            <Label className="col">
                                <span>
                                    Escolha abaixo ou digite a palavra chave
                                </span>
                                <Select
                                    id="search-product"
                                    label="Nome do Produto"
                                    type="text"
                                    options={clients}
                                    defaultValue={data.clientes}
                                    onChange={(event) => {
                                        setData({
                                            ...data,
                                            clientes: event,
                                        });
                                    }}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    isMulti
                                />
                            </Label>
                        </section>
                    )}
                </Fieldset>
                <Fieldset>
                    <h3>Linhas</h3>
                    <div className="d-flex flex-wrap">
                        {linhas.map((linha) => (
                            <Line
                                key={linha.id}
                                label={linha.Linha}
                                checked={
                                    parseInt(data.linha) === parseInt(linha.id)
                                }
                                onClick={() => {
                                    setData({
                                        ...data,
                                        linha: linha.id,
                                    });
                                }}
                            />
                        ))}
                    </div>
                </Fieldset>
                <Fieldset>
                    <h3>Aplicações</h3>
                    <div className="d-flex flex-wrap">
                        {aplicacoes.map((aplicacao) => (
                            <Application
                                key={aplicacao.id}
                                label={aplicacao.Aplicacao}
                                checked={checked(aplicacao.id)}
                                onClick={() => {
                                    if (checked(aplicacao.id)) {
                                        const array = data.aplicacaos.filter(
                                            (item) => item !== aplicacao.id
                                        );
                                        setData({
                                            ...data,
                                            aplicacaos: array,
                                        });
                                        return;
                                    }

                                    setData({
                                        ...data,
                                        aplicacaos: [
                                            ...(data.aplicacaos || []),
                                            aplicacao.id,
                                        ],
                                    });
                                }}
                            />
                        ))}
                    </div>
                </Fieldset>

                <Fieldset>
                    <h3>Central de Documentos</h3>
                    <Row className="justify-center">
                        <Row>
                            <File
                                label="Literatura"
                                accept=".pdf"
                                fileType="pdf"
                                height="250px"
                                file={CDOC1}
                                onUpload={(files) => {
                                    const file = files[0];

                                    setCDOC1({
                                        file,
                                        preview: URL.createObjectURL(file),
                                    });
                                }}
                                onDeleteFile={() => {
                                    setCDOC1({});
                                }}
                            />
                            <File
                                label="FISPQ"
                                accept=".pdf"
                                fileType="pdf"
                                height="250px"
                                file={CDOC2}
                                onUpload={(files) => {
                                    const file = files[0];

                                    setCDOC2({
                                        file,
                                        preview: URL.createObjectURL(file),
                                    });
                                }}
                                onDeleteFile={() => {
                                    setCDOC2({});
                                }}
                            />
                        </Row>
                        <Row>
                            <File
                                label="Laudos"
                                accept=".pdf"
                                fileType="pdf"
                                height="250px"
                                file={CDOC3}
                                onUpload={(files) => {
                                    const file = files[0];

                                    setCDOC3({
                                        file,
                                        preview: URL.createObjectURL(file),
                                    });
                                }}
                                onDeleteFile={() => {
                                    setCDOC3({});
                                }}
                            />
                            <File
                                label="Como vender o produto"
                                labelStyle={{ fontSize: "1.4rem" }}
                                accept=".pdf"
                                fileType="pdf"
                                height="250px"
                                file={CDOC4}
                                onUpload={(files) => {
                                    const file = files[0];

                                    setCDOC4({
                                        file,
                                        preview: URL.createObjectURL(file),
                                    });
                                }}
                                onDeleteFile={() => {
                                    setCDOC4({});
                                }}
                            />
                        </Row>
                        <Row className="col-12" id="cdoc5-container">
                            <h3 className="col-12">
                                Material publicitário de apoio
                            </h3>
                            <Col
                                md="8"
                                sm="12"
                                className="row d-flex justify-content-center align-items-center"
                            >
                                {productsInfos.map((item) => (
                                    <GridFile
                                        className="d-flex justify-content-center"
                                        md="4"
                                    >
                                        <File
                                            key={item.id}
                                            accept=".pdf"
                                            fileType="pdf"
                                            height="250px"
                                            file={item}
                                            onUpload={(files) => {
                                                const file = files[0];

                                                if (id) {
                                                    Confirm(
                                                        "Alterar Arquivo",
                                                        "tem certeza que deseja alterar ?",
                                                        async () => {
                                                            const idLoading =
                                                                loading();

                                                            try {
                                                                const response =
                                                                    await uploadFile(
                                                                        file
                                                                    );

                                                                await api.put(
                                                                    `/produtos-informacoes/${item.id}`,
                                                                    {
                                                                        arquivo:
                                                                            response,
                                                                    }
                                                                );

                                                                await api.delete(
                                                                    `/upload/files/${item.idFile}`
                                                                );

                                                                show();
                                                                Notification(
                                                                    "success",
                                                                    "Arquivo alterado"
                                                                );

                                                                loading(
                                                                    idLoading
                                                                );
                                                            } catch (error) {
                                                                Error(error);

                                                                loading(
                                                                    idLoading
                                                                );
                                                            }
                                                        }
                                                    );
                                                } else {
                                                    const newProductInfos =
                                                        productsInfos.map(
                                                            (info) => {
                                                                if (
                                                                    info.preview ===
                                                                    item.preview
                                                                ) {
                                                                    return {
                                                                        file,
                                                                        preview:
                                                                            URL.createObjectURL(
                                                                                file
                                                                            ),
                                                                    };
                                                                }

                                                                return info;
                                                            }
                                                        );

                                                    setProductsInfos(
                                                        newProductInfos
                                                    );
                                                }
                                            }}
                                            onDeleteFile={() => {
                                                if (id) {
                                                    Confirm(
                                                        "Remover Arquivo",
                                                        "tem certeza que deseja remover ?",
                                                        async () => {
                                                            const idLoading =
                                                                loading();

                                                            try {
                                                                await api.delete(
                                                                    `/produtos-informacoes/${item.id}`
                                                                );

                                                                await api.delete(
                                                                    `/upload/files/${item.idFile}`
                                                                );

                                                                show();
                                                                Notification(
                                                                    "success",
                                                                    "Arquivo removido"
                                                                );

                                                                loading(
                                                                    idLoading
                                                                );
                                                            } catch (error) {
                                                                Error(error);

                                                                loading(
                                                                    idLoading
                                                                );
                                                            }
                                                        }
                                                    );
                                                } else {
                                                    const filteredProductInfos =
                                                        productsInfos.filter(
                                                            (productInfo) =>
                                                                productInfo.preview !==
                                                                item.preview
                                                        );

                                                    setProductsInfos(
                                                        filteredProductInfos
                                                    );
                                                }
                                            }}
                                        />
                                    </GridFile>
                                ))}
                            </Col>
                            <Col
                                md="4"
                                sm="12"
                                className="d-flex justify-content-center flex-column align-items-center"
                            >
                                <File
                                    label="Adicionar"
                                    accept=".pdf"
                                    fileType="pdf"
                                    height="250px"
                                    file={productInfo}
                                    onUpload={(files) => {
                                        const file = files[0];
                                        setProductInfo({
                                            file,
                                            preview: URL.createObjectURL(file),
                                        });
                                    }}
                                    onDeleteFile={() => {
                                        setProductInfo({});
                                    }}
                                />
                                <ButtonsGrid>
                                    <Button
                                        variant="success"
                                        onClick={createProductInfo}
                                    >
                                        <FaPaperPlane />
                                        Enviar
                                    </Button>
                                </ButtonsGrid>
                            </Col>
                        </Row>
                    </Row>
                </Fieldset>
                <Fieldset>
                    <h3>Vídeos</h3>
                    <div className="d-flex flex-wrap justify-center">
                        {videos.map((video) => (
                            <Video
                                key={Math.random()}
                                src={video.link}
                                onDelete={async (event) => {
                                    event.preventDefault();

                                    const idLoading = loading();

                                    const filterVideos = videos.filter((v) => {
                                        if (v.link !== video.link) return true;

                                        return false;
                                    });

                                    if (video.id) {
                                        await api.delete(`/videos/${video.id}`);
                                    }

                                    setVideos(filterVideos);

                                    loading(idLoading);
                                }}
                            />
                        ))}
                    </div>
                    <Row>
                        <Input type="text" id="video-url" />
                        <Button
                            className="button"
                            onClick={(event) => {
                                event.preventDefault();
                                handleAddVideo();
                            }}
                        >
                            Adicionar
                        </Button>
                    </Row>
                </Fieldset>
                <ButtonsGrid>
                    <Button variant="success" type="submit">
                        <FaPaperPlane />
                        Enviar
                    </Button>
                    {id && (
                        <Button variant="outline-danger" onClick={destroy}>
                            <FaTrash />
                            Remover
                        </Button>
                    )}
                </ButtonsGrid>
            </Form>
        </Container>
    );
};

export default Index;
