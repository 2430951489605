import React from "react";
import { FaMapMarkerAlt, FaPhoneAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";

import PublicContainer from "../../Components/Container";
import Img from "../../Components/Img";

import useWhereToFind from "./useWhereToFind";

import { Container } from "./styles";

function Index() {
    const { data } = useWhereToFind();

    return (
        <PublicContainer>
            <Container>
                <header>
                    <h1>Onde Encontrar</h1>
                </header>
                <hr />
                <section>
                    <Row>
                        {data.map((item) => (
                            <>
                                <Col
                                    key={item.id}
                                    md="3"
                                    sm="12"
                                    className="d-flex justify-content-center"
                                >
                                    <Link to={`/onde-encontrar/${item.id}`}>
                                        <header>
                                            <Img
                                                src={item.imagem}
                                                max-width="200px"
                                            />
                                            <h2>{item.nome}</h2>
                                        </header>
                                        <section>
                                            <div className="address-container">
                                                <h3>
                                                    <FaMapMarkerAlt />{" "}
                                                    {item.cidade} -{" "}
                                                    {item.estado}
                                                </h3>
                                                <p>
                                                    {item.endereco},{" "}
                                                    {item.numero} <br />
                                                    {item.bairro}
                                                </p>
                                            </div>
                                            <div className="contact-container">
                                                <span>
                                                    <i
                                                        style={{
                                                            background:
                                                                "var(--blue)",
                                                            color:
                                                                "var(--white)",
                                                        }}
                                                    >
                                                        <FaPhoneAlt />
                                                    </i>{" "}
                                                    {item.telefone}
                                                </span>
                                                <span>
                                                    <i
                                                        style={{
                                                            background:
                                                                "var(--green)",
                                                            color:
                                                                "var(--white)",
                                                        }}
                                                    >
                                                        <FaPhoneAlt />
                                                    </i>{" "}
                                                    {item.celular}
                                                </span>
                                            </div>
                                        </section>
                                    </Link>
                                </Col>
                            </>
                        ))}
                    </Row>
                </section>
            </Container>
        </PublicContainer>
    );
}

export default Index;
