import { useCallback, useEffect, useState } from "react";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { useHistory } from "react-router";

import notification, {
    Error as notificationError,
} from "../../Modules/Notifications";

import api from "../../service/api";

import useLoading from "../../hooks/useLoading";

function useSettingsCDOC() {
    const history = useHistory();

    const { loading } = useLoading();

    const initialSettings = useCallback(
        () => ({
            CDOCCarousel: [],
        }),
        []
    );

    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [popupAtivo, setPopupAtivo] = useState(false);

    const [settings, setSettings] = useState(initialSettings());

    const show = useCallback(() => {
        async function show() {
            const idLoading = loading();

            try {
                const response = await api.get("/configuracoes");

                let settings = response.data[0];

                setPopupAtivo(settings.CDOCPopup.ativo);

                const html = settings.CDOCPopup.html;
                const contentBlock = htmlToDraft(html);
                if (contentBlock) {
                    const contentState = ContentState.createFromBlockArray(
                        contentBlock.contentBlocks
                    );
                    const editorState =
                        EditorState.createWithContent(contentState);
                    setEditorState(editorState);
                }

                settings.CDOCCarousel = settings.CDOCCarousel.map((file) => {
                    return {
                        ...file,
                        preview: `${api.defaults.baseURL}${file.url}`,
                    };
                });

                setSettings(settings);

                loading(idLoading);
            } catch (error) {
                notificationError(error);

                loading(idLoading);
            }
        }

        show();
    }, [loading]);

    useEffect(() => {
        show();
    }, [show]);

    async function update(event) {
        const idLoading = loading();

        try {
            event.preventDefault();

            const html = document.querySelector(
                "#editor-html-popup-cdoc"
            ).value;

            await api.put(`/configuracoes/${settings.id}`, {
                ...settings,
                CDOCPopup: { ativo: popupAtivo, html },
            });

            await show();

            notification(
                "success",
                "Configurações Universidade Ecobrite atualizada"
            );

            loading(idLoading);
        } catch (error) {
            notificationError(error);

            loading(idLoading);
        }
    }

    function handlePopupValue() {
        return draftToHtml(convertToRaw(editorState.getCurrentContent()));
    }

    function handleOnChangePopup(value) {
        setEditorState(value);
    }

    function handleOnChangeFile(value) {
        setSettings({ ...settings, CDOCCarousel: value });
    }

    function handleToggleAtivo() {
        setPopupAtivo(!popupAtivo);
    }

    function gotoHomologatedBrands() {
        history.push("/ctop/marcas-homologadas");
    }

    function gotoGraphicMaterials() {
        history.push("/ctop/materiais-graficos");
    }

    function gotoGraphicEvents() {
        history.push("/ctop/eventos");
    }

    function gotoGraphicReleases() {
        history.push("/ctop/lancamentos");
    }

    return {
        editorState,
        settings,
        popupAtivo,
        update,
        handlePopupValue,
        handleOnChangePopup,
        handleToggleAtivo,
        handleOnChangeFile,
        gotoHomologatedBrands,
        gotoGraphicMaterials,
        gotoGraphicEvents,
        gotoGraphicReleases,
    };
}

export default useSettingsCDOC;
