import { useState, useEffect } from "react";

import api, { publicRequest } from "../../../service/api";

import useLoading from "../../../hooks/useLoading";

function useProduct({ id, client }) {
    const [data, setData] = useState({});

    const [productInfos, setProductInfos] = useState([]);

    const [videos, setVideos] = useState([]);

    const { loading } = useLoading();

    const documents = {
        CDOC1: 1,
        CDOC2: 2,
        CDOC3: 3,
        CDOC4: 4,
        CDOC5: 5,
        Others: 6,
    };

    useEffect(() => {
        async function show() {
            const idLoading = loading();

            try {
                const response = await api.get(
                    `/produtos/${id}`,
                    publicRequest
                );

                response.data.aplicacaos = response.data.aplicacaos.map(
                    (item) => item.id
                );

                response.data.linha =
                    response.data.linha && response.data.linha.id;

                setVideos(response.data.videos);

                delete response.data.videos;

                const productInfos = response.data.produtos_informacoes.map(
                    (item) => {
                        return `${api.defaults.baseURL}${item.arquivo.url}`;
                    }
                );
                setProductInfos(productInfos);

                setData(response.data);

                loading(idLoading);
            } catch (error) {
                Error(error);

                loading(idLoading);
            }
        }

        show();
    }, [id, loading]);

    async function createLogCDOC(cliente, cdoc, produto) {
        const idLoading = loading();

        try {
            api.post(
                "/log-cdocs",
                {
                    cliente,
                    cdoc,
                    produto,
                },
                publicRequest
            );

            loading(idLoading);
        } catch (error) {
            console.error(error);

            loading(idLoading);
        }
    }

    function handleSelectDocument(document) {
        if (typeof document === "undefined") return;

        if (data[document]) {
            window.open(data[document], "_blank");
            createLogCDOC(client, documents[document], data.id);
        }
    }

    return { data, productInfos, videos, handleSelectDocument, createLogCDOC };
}

export default useProduct;
