import React from "react";
import Styled from "styled-components";
import ProfessionalProducts from "../SettingsPMPP";

const Container = Styled.div`

`;

function Index() {
    return (
        <Container>
            <h1>Produtos</h1>
            <ProfessionalProducts />
        </Container>
    );
}

export default Index;
