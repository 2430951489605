import React, { useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import ReactDOM from "react-dom";
import { useHistory } from "react-router-dom";
import { FiFolder, FiPlusCircle, FiX, FiCheck } from "react-icons/fi";

import Container from "../../../Components/Admin/Container";
import Table, { ResponsiveTable } from "../../../Components/Table";

import api from "../../../service/api";

import useLoading from "../../../hooks/useLoading";

import { Error } from "../../../Modules/Notifications";
import DataTables from "../../../Modules/DataTables";
import { Data } from "../../../Modules/Formatter";

const Index = () => {
    const history = useHistory();

    const { loading } = useLoading();

    const setReactComponentinTable = useCallback(() => {
        const handleEdit = (id) => {
            history.push(`/ctop/noticia/${id}`);
        };

        const setReactComponentinTable = () => {
            const editarEls = document.querySelectorAll(".editar");

            for (const editarEl of editarEls) {
                ReactDOM.render(
                    <FiFolder
                        className="cursor-pointer"
                        onClick={() => {
                            handleEdit(editarEl.dataset.id);
                        }}
                    />,
                    document.getElementById(editarEl.id)
                );
            }

            const ativosEls = document.querySelectorAll(".ativo");

            for (const ativosEl of ativosEls) {
                ReactDOM.render(
                    ativosEl.dataset.value === "true" ? (
                        <FiCheck color="green" />
                    ) : (
                        <FiX color="red" />
                    ),
                    document.getElementById(ativosEl.id)
                );
            }
        };

        setReactComponentinTable();
    }, [history]);

    useEffect(() => {
        const show = async () => {
            const idLoading = loading();

            try {
                const response = await api.get("/noticias?_limit=-1");

                const serializedData = response.data.map((item) => {
                    const { id, titulo, data, ativo } = item;

                    return {
                        titulo,
                        data: Data(data),
                        ativo: `<div class="ativo" data-value="${ativo}" id="ativo-${id}"></div>`,
                        Abrir: `<div class="editar" data-id="${id}" id="editar-${id}"></div>`,
                    };
                });

                DataTables(
                    "#news-table",
                    serializedData,
                    [
                        { title: "Título", data: "titulo" },
                        { title: "Data", data: "data" },
                        { title: "Ativo", data: "ativo" },
                        { title: "Abrir", data: "Abrir" },
                    ],
                    () => {
                        setReactComponentinTable();
                    }
                );

                loading(idLoading);
            } catch (error) {
                Error(error);

                loading(idLoading);
            }
        };

        show();
    }, [loading, setReactComponentinTable]);

    return (
        <Container pageTitle="Listar Notícias">
            <div className="grid-add">
                <Link to="/ctop/noticia" className="add">
                    <FiPlusCircle />
                </Link>
            </div>
            <ResponsiveTable>
                <Table id="news-table"></Table>
            </ResponsiveTable>
        </Container>
    );
};

export default Index;
