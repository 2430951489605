import styled from "styled-components";

export const Container = styled.div`
    table {
        th,
        td {
            text-align: center;
        }

        .link {
            color: var(--blue);
            transition: 0.2s;
            cursor: pointer;

            &:hover {
                filter: brightness(120%);
            }
        }
    }
`;
