import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { FaPaperPlane, FaTrash } from "react-icons/fa";

import Container from "../../../Components/Admin/Container";
import Input, { Switch } from "../../../Components/Input";
import Button from "../../../Components/Button";
import ButtonsGrid from "../../../Components/ButtonsGrid";
import Form from "../../../Components/Form";

import api from "../../../service/api";

import useLoading from "../../../hooks/useLoading";

import Notification, { Error } from "../../../Modules/Notifications";
import Confirm from "../../../Modules/AlertConfirm";

const Index = (props) => {
    const { id } = props.match.params;

    const history = useHistory();
    const { loading } = useLoading();

    const [data, setData] = useState({});

    useEffect(() => {
        async function show() {
            const idLoading = loading();

            const response = await api.get(`/users/${id}`);

            setData(response.data);

            loading(idLoading);
        }

        if (id) show();
    }, [id, loading]);

    async function create() {
        const idLoading = loading();

        try {
            await api.post("/auth/local/register", { ...data, blocked: false });

            Notification("success", "Usuário cadastrado");

            history.push("/ctop/usuarios");

            loading(idLoading);
        } catch (error) {
            Error(error);

            loading(idLoading);
        }
    }

    async function update() {
        const idLoading = loading();

        try {
            await api.put(`/users/${id}`, data);

            Notification("success", "Usuário atualizado");
            history.push("/ctop/usuarios");

            loading(idLoading);
        } catch (error) {
            Error(error);

            loading(idLoading);
        }
    }

    const destroy = async (event) => {
        event.preventDefault();

        Confirm(
            "Remover Usuário",
            "Tem certeza que deseja remover ?",
            async () => {
                const idLoading = loading();

                try {
                    await api.delete(`/users/${id}`);

                    Notification("success", "Usuário removido");
                    history.push("/ctop/usuarios");

                    loading(idLoading);
                } catch (error) {
                    Error(error);

                    loading(idLoading);
                }
            }
        );
    };

    const handleValidate = () => {
        if (!data.username) {
            return { status: false, message: "Nome de usuário é obrigatório" };
        }

        if (!data.email) {
            return { status: false, message: "E-Mail é obrigatório" };
        }

        if (!data.password && !id) {
            return { status: false, message: "Senha é obrigatório" };
        }

        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(data.email)) {
            return { status: false, message: "E-mail inválido!" };
        }

        return { status: true };
    };

    const submit = (event) => {
        event.preventDefault();

        const validate = handleValidate();
        if (!validate.status) {
            return Notification("warning", validate.message);
        }

        if (!id) {
            return create();
        }

        return update();
    };

    return (
        <Container pageTitle={`${!id ? "Cadastrar" : "Editar"} Usuário`}>
            <Form onSubmit={submit}>
                <Row className="col-md-4 col-sm-12 m-auto">
                    <Col>
                        <Input
                            type="text"
                            label="Nome do Usuário*"
                            value={data.username}
                            onChange={(event) => {
                                setData({
                                    ...data,
                                    username: event.target.value,
                                });
                            }}
                        />
                    </Col>
                    <Col>
                        <Input
                            type="email"
                            label="E-mail*"
                            value={data.email}
                            onChange={(event) => {
                                setData({ ...data, email: event.target.value });
                            }}
                        />
                    </Col>
                    <Col>
                        <Input
                            type="password"
                            label="Senha*"
                            value={data.password}
                            onChange={(event) => {
                                setData({
                                    ...data,
                                    password: event.target.value,
                                });
                            }}
                        />
                    </Col>
                    {id && (
                        <Col sm="12" className="d-flex justify-center">
                            <Switch
                                checked={!data.blocked}
                                label="Ativo"
                                id="blocked"
                                onChange={(event) => {
                                    setData({
                                        ...data,
                                        blocked: !event.target.checked,
                                    });
                                }}
                            />
                        </Col>
                    )}
                </Row>
                <Row>
                    <ButtonsGrid>
                        <Button
                            className="button"
                            type="submit"
                            variant="success"
                        >
                            <FaPaperPlane />
                            Enviar
                        </Button>
                        {id && (
                            <Button variant="outline-danger" onClick={destroy}>
                                <FaTrash />
                                Remover
                            </Button>
                        )}
                    </ButtonsGrid>
                </Row>
            </Form>
        </Container>
    );
};

export default Index;
