import React, { useEffect, useState, useCallback } from "react";
import { Row, Col } from "react-bootstrap";
import { FaPaperPlane, FaTrash } from "react-icons/fa";
import { useHistory } from "react-router-dom";

import Container from "../../../Components/Admin/Container";
import Input, { File, Switch } from "../../../Components/Input";
import Button from "../../../Components/Button";
import Form from "../../../Components/Form";
import ButtonsGrid from "../../../Components/ButtonsGrid";

import Api from "../../../service/api";

import useLoading from "../../../hooks/useLoading";

import Notification, { Error } from "../../../Modules/Notifications";
import Confirm from "../../../Modules/AlertConfirm";

function Index({ match }) {
    const { id } = match.params;

    const history = useHistory();
    const { loading } = useLoading();

    const [data, setData] = useState({});
    const [image, setImage] = useState({});
    const [file, setFile] = useState({});

    const uploadFile = async (file) => {
        const idLoading = loading();

        let data = new FormData();
        data.append(`files`, file);
        const response = await Api.post("/upload", data);

        loading(idLoading);

        return response.data[0];
    };

    const show = useCallback(() => {
        async function show() {
            const idLoading = loading();

            try {
                const response = await Api.get(`/treinamentos/${id}`);

                const { nome, ativo } = response.data;

                setData({ nome, ativo });

                const image = {
                    id: response.data.imagem.id,
                    preview: `${Api.defaults.baseURL}${response.data.imagem.url}`,
                };

                setImage(image);

                const file = {
                    id: response.data.arquivo.id,
                    preview: `${Api.defaults.baseURL}${response.data.arquivo.url}`,
                };

                setFile(file);

                loading(idLoading);
            } catch (error) {
                Error(error);

                loading(idLoading);
            }
        }

        show();
    }, [id, loading]);

    useEffect(() => {
        if (id) show();
    }, [show, id]);

    function handleValidate() {
        if (!data.nome) {
            return { status: false, message: "Nome é obrigatório" };
        }

        if (Object.keys(image).length === 0) {
            return { status: false, message: "Imagem é obrigatório" };
        }

        if (Object.keys(file).length === 0) {
            return { status: false, message: "Arquivo é obrigatório" };
        }

        return { status: true };
    }

    async function create(data) {
        const idLoading = loading();

        try {
            await Api.post("/treinamentos", data);

            Notification("success", "Treinamento cadastrado");

            loading(idLoading);
        } catch (error) {
            Error(error);

            loading(idLoading);
        }
    }

    async function update(id, data) {
        const idLoading = loading();

        try {
            await Api.put(`/treinamentos/${id}`, data);

            Notification("success", "Treinamento alterado");

            loading(idLoading);
        } catch (error) {
            Error(error);

            loading(idLoading);
        }
    }

    async function handleSubmit(e) {
        e.preventDefault();
        const validate = handleValidate();
        if (!validate.status) {
            return Notification("warning", validate.message);
        }

        if (!image.id) {
            var imagem = await uploadFile(image.file);
        }

        if (!file.id) {
            var arquivo = await uploadFile(file.file);
        }

        if (!id) {
            await create({
                ...data,
                imagem,
                arquivo,
            });
        } else {
            await update(id, data);
        }

        history.push("/ctop/treinamentos");
    }

    function destroy() {
        Confirm(
            "Remover Treinamento",
            "Tem certeza que deseja remover ?",
            async () => {
                const idLoading = loading();
                try {
                    await Api.delete(`/treinamentos/${id}`);

                    Notification("success", "Treinamento removido");
                    history.push("/ctop/treinamentos");

                    loading(idLoading);
                } catch (error) {
                    Error(error);

                    loading(idLoading);
                }
            }
        );
    }

    return (
        <Container pageTitle={`${id ? "Editar" : "Cadastrar"} Treinamento`}>
            <Row className="d-flex justify-content-center">
                <Col md="6" sm="10">
                    <Form onSubmit={handleSubmit}>
                        <Input
                            label="Nome*"
                            value={data.nome}
                            onChange={(e) =>
                                setData({ ...data, nome: e.target.value })
                            }
                        />
                        {id && (
                            <Switch
                                className="col"
                                type="checkbox"
                                label="Ativo"
                                id="is-actived"
                                checked={data.ativo}
                                onChange={(event) => {
                                    setData({
                                        ...data,
                                        ativo: event.target.checked,
                                    });
                                }}
                            />
                        )}
                        <Row>
                            <Col className="d-flex justify-content-center">
                                <File
                                    label="Imagem*"
                                    width="200"
                                    height="200px"
                                    accept="image/*"
                                    file={image}
                                    onUpload={(files) => {
                                        const file = files[0];

                                        if (image.id) {
                                            Confirm(
                                                "Alterar Imagem",
                                                "Tem certeza que deseja alterar ?",
                                                async () => {
                                                    const idLoading = loading();

                                                    try {
                                                        await Api.delete(
                                                            `/upload/files/${image.id}`
                                                        );

                                                        const response =
                                                            await uploadFile(
                                                                file
                                                            );

                                                        await Api.put(
                                                            `/treinamentos/${id}`,
                                                            { imagem: response }
                                                        );

                                                        show();
                                                        Notification(
                                                            "success",
                                                            "Imagem alterada"
                                                        );

                                                        loading(idLoading);
                                                    } catch (error) {
                                                        Error(error);

                                                        loading(idLoading);
                                                    }
                                                }
                                            );
                                        } else {
                                            setImage({
                                                file,
                                                preview:
                                                    URL.createObjectURL(file),
                                            });
                                        }
                                    }}
                                    onDeleteFile={() => {
                                        if (image.id) {
                                            Notification(
                                                "warning",
                                                "Não é possivel remover"
                                            );
                                        } else {
                                            setImage({});
                                        }
                                    }}
                                />
                            </Col>
                            <Col className="d-flex justify-content-center">
                                <File
                                    label="Arquivo*"
                                    width="200"
                                    height="200px"
                                    accept=".pdf"
                                    fileType="pdf"
                                    file={file}
                                    onUpload={(files) => {
                                        const uploadedFile = files[0];
                                        if (file.id) {
                                            Confirm(
                                                "Alterar Arquivo",
                                                "Tem certeza que deseja alterar ?",
                                                async () => {
                                                    const idLoading = loading();

                                                    try {
                                                        await Api.delete(
                                                            `/upload/files/${file.id}`
                                                        );

                                                        const response =
                                                            await uploadFile(
                                                                uploadedFile
                                                            );

                                                        await Api.put(
                                                            `/treinamentos/${id}`,
                                                            {
                                                                arquivo:
                                                                    response,
                                                            }
                                                        );

                                                        show();
                                                        Notification(
                                                            "success",
                                                            "Arquivo alterado"
                                                        );

                                                        loading(idLoading);
                                                    } catch (error) {
                                                        Error(error);

                                                        loading(idLoading);
                                                    }
                                                }
                                            );
                                        } else {
                                            setFile({
                                                file: uploadedFile,
                                                preview:
                                                    URL.createObjectURL(
                                                        uploadedFile
                                                    ),
                                            });
                                        }
                                    }}
                                    onDeleteFile={() => {
                                        if (file.id) {
                                            Notification(
                                                "warning",
                                                "Não é possivel remover"
                                            );
                                        } else {
                                            setFile({});
                                        }
                                    }}
                                />
                            </Col>
                            <ButtonsGrid>
                                <Button variant="success" type="submit">
                                    <FaPaperPlane />
                                    Enviar
                                </Button>
                                {id && (
                                    <Button
                                        variant="outline-danger"
                                        onClick={destroy}
                                    >
                                        <FaTrash />
                                        Remover
                                    </Button>
                                )}
                            </ButtonsGrid>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}

export default Index;
