import React, { useState, useEffect, useCallback } from "react";
import Styled from "styled-components";
import { FaPaperPlane } from "react-icons/fa";
import { Col } from "react-bootstrap";

import { File } from "../../Components/Input";
import Button from "../../Components/Button";

import Api from "../../service/api";

import useLoading from "../../hooks/useLoading";

import Confirm from "../../Modules/AlertConfirm";
import Notification, { Error } from "../../Modules/Notifications";

const Container = Styled.div`
    img {
        width: 100%;
    }

    figure {
        max-width: 300px;

        button {
            border: 0;
            background-color: var(--gray-3);
            height: 32px;
        }

        button:disabled {
            filter: brightness(90%)
        }

        div.col:last-child {
            filter: brightness(90%);
        }

        .view-spot {
            background-color: var(--blue);
            transition: 0.2s;
        }
        
        .view-spot:hover {
            filter: brightness(120%);
        }
        
        .delete-spot {
            border: 2px solid #f44336;
            background-color: var(--white);
            color: #f44336!important;
        }
        
        .delete-spot:hover {
            border: 2px solid #f44336;
            background-color: #f44336;
            color:  var(--white)!important;
        }
    }
`;

const AddGrid = Styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;

    padding: 1rem!important;

    button {
        margin-top: 1rem!important;
    }

    .grid-file {
        margin: 0!important;
    }
`;

const FileGrid = Styled.div.attrs({
    className: "col",
})`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem!important;
`;

function Index() {
    const [uploadImage, setUploadImage] = useState({});

    const [images, setImages] = useState([]);

    const { loading } = useLoading();

    const indexSpotlights = useCallback(() => {
        async function indexSpotlights() {
            const idLoading = loading();

            try {
                const response = await Api.get("/configuracoes-destaques");

                if (Array.isArray(response.data)) {
                    const serializedData = response.data.map((item) => {
                        const preview = `${Api.defaults.baseURL}${item.image.url}`;

                        return { id: item.image.id, preview, file: item };
                    });

                    setImages(serializedData);
                }

                loading(idLoading);
            } catch (error) {
                Error(error);

                loading(idLoading);
            }
        }

        indexSpotlights();
    }, [loading]);

    useEffect(() => {
        indexSpotlights();
    }, [indexSpotlights]);

    async function handleSpotlightUpload() {
        const idLoading = loading();

        try {
            const image = await uploadFile(uploadImage.file);

            await Api.post("/configuracoes-destaques", { image });

            Notification("success", "Destaque adicionado");

            setUploadImage({});

            indexSpotlights();

            loading(idLoading);
        } catch (error) {
            Error(error);

            loading(idLoading);
        }
    }

    const uploadFile = async (file) => {
        const idLoading = loading();

        let data = new FormData();
        data.append(`files`, file);
        const response = await Api.post("/upload", data);

        loading(idLoading);

        return response.data[0];
    };

    return (
        <div>
            <Container className="row">
                <header className="col-12">
                    <h2>Imagens Destaques</h2>
                </header>
                <Col
                    sm="12"
                    md="8"
                    className="row d-flex justify-content-center align-items-center"
                >
                    {images.map((image) => {
                        return (
                            <FileGrid>
                                <File
                                    key={image.id}
                                    width="280"
                                    accept="image/*"
                                    file={image}
                                    onUpload={(files) => {
                                        const file = files[0];
                                        Confirm(
                                            "Alterar Destaque",
                                            "Tem certeza que deseja alterar ?",
                                            async () => {
                                                const idLoading = loading();

                                                await Api.delete(
                                                    `/upload/files/${image.id}`
                                                );

                                                const response =
                                                    await uploadFile(file);

                                                await Api.put(
                                                    `/configuracoes-destaques/${image.file.id}`,
                                                    { image: response }
                                                );

                                                indexSpotlights();
                                                Notification(
                                                    "success",
                                                    "Destaque alterado"
                                                );

                                                loading(idLoading);
                                            }
                                        );
                                    }}
                                    onDeleteFile={() => {
                                        Confirm(
                                            "Remover Destaque",
                                            "Tem certeza que deseja remover ?",
                                            async () => {
                                                const idLoading = loading();

                                                try {
                                                    await Api.delete(
                                                        `/upload/files/${image.id}`
                                                    );

                                                    await Api.delete(
                                                        `/configuracoes-destaques/${image.file.id}`
                                                    );

                                                    Notification(
                                                        "success",
                                                        "Destaque removido"
                                                    );
                                                    indexSpotlights();

                                                    loading(idLoading);
                                                } catch (error) {
                                                    Error(error);

                                                    loading(idLoading);
                                                }
                                            }
                                        );
                                    }}
                                />
                            </FileGrid>
                        );
                    })}
                </Col>
                <AddGrid className="col-md-4 col-sm-12">
                    <File
                        label="Adicionar"
                        className="col upload-spot"
                        width="280"
                        height="180px"
                        accept="image/*"
                        file={uploadImage}
                        onUpload={(files) => {
                            const file = files[0];
                            setUploadImage({
                                file,
                                preview: URL.createObjectURL(file),
                            });
                        }}
                        onDeleteFile={() => {
                            setUploadImage({});
                        }}
                    />
                    <Button variant="success" onClick={handleSpotlightUpload}>
                        <FaPaperPlane />
                        Enviar
                    </Button>
                </AddGrid>
            </Container>
        </div>
    );
}

export default Index;
