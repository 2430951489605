import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import AdminRoute from "./Components/PrivateRoutes/AdminRoutes";
import {
    PageNotFound404,
    Home,
    Contact,
    Company,
    ThanksPage,
    LoginAdmin,
    HomeAdmin,
    User,
    Client,
    Product,
    Aplications,
    Lines,
    Products,
    Clients,
    ProductsAndMarket,
    Users,
    LogsCDOC,
    New,
    News,
    CDOC,
    TermsOfUseCDOC,
    GsSolutions,
    AboutInputs,
    Settings,
    BePartner,
    Partners,
    Partner,
    WhereToFind,
    Training,
    Trainings,
    TrainingVideos,
    Cookies,
    PublicPartner,
    HomologatedBrands,
    HomologatedBrand,
    GraphicMaterials,
    GraphicMaterial,
    Events,
    Event,
    Releases,
    Release,
} from "../Pages";

const Routes = () => (
    <BrowserRouter>
        <Switch>
            <AdminRoute path="/ctop/settings" exact component={Settings} />

            <AdminRoute path="/ctop/produtos/linhas" exact component={Lines} />

            <AdminRoute path="/ctop/treinamentos" exact component={Trainings} />
            <AdminRoute path="/ctop/treinamento" exact component={Training} />
            <AdminRoute
                path="/ctop/videos-treinamentos"
                exact
                component={TrainingVideos}
            />
            <AdminRoute
                path="/ctop/videos-treinamentos/:id"
                exact
                component={TrainingVideos}
            />

            <AdminRoute
                path="/ctop/treinamento/:id"
                exact
                component={Training}
            />

            <AdminRoute path="/ctop/parceiros" exact component={Partners} />
            <AdminRoute path="/ctop/parceiro" exact component={Partner} />
            <AdminRoute path="/ctop/parceiro/:id" exact component={Partner} />

            <AdminRoute
                path="/ctop/produtos/linhas/:id"
                exact
                component={Lines}
            />
            <AdminRoute
                path="/ctop/produtos/aplicacoes"
                exact
                component={Aplications}
            />
            <AdminRoute
                path="/ctop/produtos/aplicacoes/:id"
                exact
                component={Aplications}
            />
            <AdminRoute path="/ctop/produtos" exact component={Products} />
            <AdminRoute path="/ctop/produto" exact component={Product} />

            <AdminRoute path="/ctop/produto/:id" exact component={Product} />

            <AdminRoute path="/ctop/clientes" exact component={Clients} />

            <AdminRoute path="/ctop/cliente/:id" exact component={Client} />
            <AdminRoute path="/ctop/cliente/" exact component={Client} />

            <AdminRoute path="/ctop/usuarios" exact component={Users} />
            <AdminRoute path="/ctop/usuario" exact component={User} />
            <AdminRoute path="/ctop/usuario/:id" exact component={User} />

            <AdminRoute path="/ctop/noticias" exact component={News} />
            <AdminRoute path="/ctop/noticia" exact component={New} />
            <AdminRoute path="/ctop/noticia/:id" exact component={New} />

            <AdminRoute path="/ctop/eventos" exact component={Events} />
            <AdminRoute path="/ctop/evento" exact component={Event} />
            <AdminRoute path="/ctop/evento/:id" exact component={Event} />

            <AdminRoute path="/ctop/lancamentos/" exact component={Releases} />
            <AdminRoute path="/ctop/lancamento/" exact component={Release} />
            <AdminRoute path="/ctop/lancamento/:id" exact component={Release} />

            <AdminRoute
                path="/ctop/marcas-homologadas"
                exact
                component={HomologatedBrands}
            />

            <AdminRoute
                path="/ctop/marca-homologada"
                exact
                component={HomologatedBrand}
            />
            <AdminRoute
                path="/ctop/marca-homologada/:id"
                exact
                component={HomologatedBrand}
            />

            <AdminRoute
                path="/ctop/materiais-graficos"
                exact
                component={GraphicMaterials}
            />
            <AdminRoute
                path="/ctop/material-grafico"
                exact
                component={GraphicMaterial}
            />
            <AdminRoute
                path="/ctop/material-grafico/:id"
                exact
                component={GraphicMaterial}
            />

            <AdminRoute path="/ctop/home" exact component={HomeAdmin} />

            <Route path="/onde-encontrar" exact component={WhereToFind} />
            <Route path="/onde-encontrar/:id" exact component={PublicPartner} />
            <Route path="/politica-de-privacidade" exact component={Cookies} />
            <Route path="/seja-parceiro" exact component={BePartner} />
            <Route path="/ctop" exact component={LoginAdmin} />
            <Route path="/ctop/logs-cdoc" exact component={LogsCDOC} />

            <Route path="/universidade-ecobrite" exact component={CDOC} />
            <Route
                path="/produtos-e-mercado"
                exact
                component={ProductsAndMarket}
            />
            <Route path="/empresa" exact component={Company} />
            <Route
                path="/termos-de-uso-cdoc"
                exact
                component={TermsOfUseCDOC}
            />
            <Route path="/obrigado" exact component={ThanksPage} />
            <Route path="/contato" exact component={Contact} />
            <Route path="/gs-solutions" exact component={GsSolutions} />
            <Route path="/about-inputs" exact component={AboutInputs} />
            <Route path="/" exact component={Home} />
            <Route path="*" component={PageNotFound404} />
        </Switch>
    </BrowserRouter>
);

export default Routes;
