import React from "react";

import Container from "../../Components/Container";

const Company = () => {
    return (
        <Container>
            <header>
                <h1>A empresa</h1>
            </header>
        </Container>
    );
};

export default Company;
