import styled from "styled-components";

export const Container = styled.div``;

export const Header = styled.header`
    margin: 1rem !important;
    margin-bottom: 3rem !important;

    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
        width: 20rem;
    }
`;
