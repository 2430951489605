import React from "react";
import { useParams } from "react-router";
import { Col, Row } from "react-bootstrap";
import { FaPaperPlane, FaTrash } from "react-icons/fa";

import Form from "../../../Components/Form";
import Input, { File } from "../../../Components/Input";
import Container from "../../../Components/Admin/Container";
import ButtonsGrid from "../../../Components/ButtonsGrid";
import Button from "../../../Components/Button";

import useHomologatedBrand from "./useHomologatedBrand";

function HomologatedBrands() {
    const { id } = useParams();

    const {
        nome,
        imagem,
        arquivo,
        handleOnChangeNome,
        handleOnChangeImagem,
        handleOnDeleteImagem,
        handleOnChangeArquivo,
        handleOnDeleteArquivo,
        handleSubmit,
        destroy,
    } = useHomologatedBrand();

    return (
        <Container pageTitle="Marca Homologada">
            <Row className="d-flex justify-content-center">
                <Col md="6" sm="10">
                    <Form onSubmit={handleSubmit}>
                        <Input
                            label="Nome*"
                            value={nome}
                            onChange={handleOnChangeNome}
                        />
                        <Row>
                            <Col className="d-flex justify-content-center">
                                <File
                                    label="Imagem*"
                                    width="200"
                                    height="200px"
                                    accept="image/*"
                                    file={imagem}
                                    onUpload={handleOnChangeImagem}
                                    onDeleteFile={handleOnDeleteImagem}
                                />
                            </Col>
                            <Col className="d-flex justify-content-center">
                                <File
                                    label="Arquivo*"
                                    width="200"
                                    height="200px"
                                    accept=".pdf"
                                    fileType="pdf"
                                    file={arquivo}
                                    onUpload={handleOnChangeArquivo}
                                    onDeleteFile={handleOnDeleteArquivo}
                                />
                            </Col>
                            <ButtonsGrid>
                                <Button variant="success" type="submit">
                                    <FaPaperPlane />
                                    Enviar
                                </Button>
                                {id && (
                                    <Button
                                        variant="outline-danger"
                                        onClick={destroy}
                                    >
                                        <FaTrash />
                                        Remover
                                    </Button>
                                )}
                            </ButtonsGrid>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}

export default HomologatedBrands;
