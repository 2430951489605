import styled from "styled-components";

export const Container = styled.div`
    position: fixed;
    inset: 0;
    z-index: 100000;

    background-color: rgba(0, 0, 0, 0.5);

    display: ${(props) => (props.show ? "flex" : "none")};
    justify-content: center;
    align-items: center;

    padding: 1rem !important;
`;

export const PopupContainer = styled.div`
    width: 100%;
    max-width: 900px;

    display: flex;
    flex-direction: column;

    background-color: #fff;

    border-radius: 5px;

    padding: 1rem !important;

    .popup-inner-container {
        height: 70vh;
        overflow-y: auto;
    }

    p {
        margin: 0 !important;
    }
`;

export const Button = styled.button`
    border: 0;
    background-color: var(--blue);
    color: var(--white);

    padding: 0 5px !important;
    margin: 5px auto !important;

    outline: 0 !important;

    box-shadow: 1px 1px 3px #000;

    transition: 0.2s;

    &:active {
        box-shadow: none;
    }
`;
