import React, { useEffect, useCallback } from "react";
import ReactDOM from "react-dom";
import { Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { FiCheck, FiFolder, FiPlusCircle, FiX } from "react-icons/fi";
import { Link } from "react-router-dom";

import Container from "../../../Components/Admin/Container";
import Table, { ResponsiveTable } from "../../../Components/Table";

import api from "../../../service/api";

import useLoading from "../../../hooks/useLoading";

import { Error } from "../../../Modules/Notifications";
import DataTables from "../../../Modules/DataTables";

const ShowUsers = () => {
    const history = useHistory();
    const { loading } = useLoading();

    const setReactComponentinTable = useCallback(() => {
        const handleEdit = (id) => {
            history.push(`/ctop/usuario/${id}`);
        };

        const setReactComponentinTable = () => {
            const editarEls = document.querySelectorAll(".editar");

            for (const editarEl of editarEls) {
                ReactDOM.render(
                    <FiFolder
                        className="cursor-pointer"
                        onClick={() => {
                            handleEdit(editarEl.dataset.id);
                        }}
                    />,
                    document.getElementById(editarEl.id)
                );
            }

            const ativosEls = document.querySelectorAll(".ativo");

            for (const ativosEl of ativosEls) {
                ReactDOM.render(
                    !(ativosEl.dataset.value === "true") ? (
                        <FiCheck color="green" />
                    ) : (
                        <FiX color="red" />
                    ),
                    document.getElementById(ativosEl.id)
                );
            }
        };

        setReactComponentinTable();
    }, [history]);

    useEffect(() => {
        const show = async () => {
            const idLoading = loading();

            try {
                const response = await api.get("/users?_limit=-1");

                const serializedData = response.data.map((item) => {
                    const { id, username, email, blocked } = item;

                    return {
                        username,
                        email,
                        Ativo: `<div class="ativo" data-value="${blocked}" id="ativo-${id}"></div>`,
                        Abrir: `<div class="editar" data-id="${id}" id="editar-${id}"></div>`,
                    };
                });

                DataTables(
                    "#users-table",
                    serializedData,
                    [
                        { title: "Nome de Usuário", data: "username" },
                        { title: "E-Mail", data: "email" },
                        { title: "Ativo", data: "Ativo" },
                        { title: "Abrir", data: "Abrir" },
                    ],
                    () => {
                        setReactComponentinTable();
                    }
                );

                loading(idLoading);
            } catch (error) {
                Error(error);

                loading(idLoading);
            }
        };

        show();
    }, [loading, setReactComponentinTable]);

    return (
        <Container pageTitle="Listar Usuários">
            <Row className="grid-add">
                <Link to="/ctop/usuario" className="add">
                    <FiPlusCircle />
                </Link>
            </Row>
            <ResponsiveTable>
                <Table id="users-table" size="sm"></Table>
            </ResponsiveTable>
        </Container>
    );
};

export default ShowUsers;
