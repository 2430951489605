import React, { useState, useEffect } from "react";
import Styled from "styled-components";
import { Row } from "react-bootstrap";

import Container from "../../Components/Container";
import News from "../../Components/News";
import Spotlight from "../../Components/Spotlight";
import Card from "../../Components/Card";
import Img from "../../Components/Img";
import CookiesPopup from "../../Components/CookiesPopup";

import { Error } from "../../Modules/Notifications";

import Api, { publicRequest } from "../../service/api";

import useLoading from "../../hooks/useLoading";

const GridCards = Styled(Row)`
`;

const Home = () => {
    const { loading } = useLoading();

    const [list, setList] = useState([]);

    useEffect(() => {
        async function index() {
            const idLoading = loading();

            try {
                const response = await Api.get(
                    "/configuracoes-cartoes",
                    publicRequest
                );

                const serializedData = response.data.map((item) => ({
                    arquivo: `${Api.defaults.baseURL}${item.arquivo.url}`,
                    imagem: `${Api.defaults.baseURL}${item.imagem.url}`,
                }));

                setList(serializedData);

                loading(idLoading);
            } catch (error) {
                Error(error);

                loading(idLoading);
            }
        }

        index();
    }, [loading]);

    return (
        <>
            <CookiesPopup />
            <Container Spotlight={Spotlight} News={News}>
                <GridCards>
                    {list.map((item) => (
                        <Card md="4" sm="12">
                            <a
                                href={item.arquivo}
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                <Img src={item.imagem} />
                            </a>
                        </Card>
                    ))}
                </GridCards>
            </Container>
        </>
    );
};

export default Home;
