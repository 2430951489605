import React, { useState, useEffect } from "react";
import Slider from "react-slick";

import Img from "../../Components/Img";

import { Error } from "../../Modules/Notifications";

import Api from "../../service/api";

import useLoading from "../../hooks/useLoading";

import { Container } from "./styles";

const Index = () => {
    const [spotlights, setSpotlights] = useState([]);

    const { loading } = useLoading();

    const settings = {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2500,
        autoplaySpeed: 2500,
        cssEase: "linear",
        pauseOnHover: false,
    };

    useEffect(() => {
        async function indexStoplight() {
            const idLoading = loading();

            try {
                const response = await Api.get("/configuracoes-destaques", {
                    headers: {
                        Authorization: "",
                    },
                });

                const spotlights = response.data.map((item) => {
                    return {
                        url: `${Api.defaults.baseURL}${item.image.url}`,
                        id: item.id,
                    };
                });

                setSpotlights(spotlights);

                loading(idLoading);
            } catch (error) {
                Error(error);

                loading(idLoading);
            }
        }
        indexStoplight();
    }, [loading]);

    return (
        <Container>
            <Slider {...settings}>
                {spotlights.map((spot) => (
                    <div>
                        <Img
                            key={spot.id}
                            src={spot.url}
                            className="shadow-none border-0"
                            alt="Destaque Noordhen Higiene Aplicada"
                            width="100%"
                        />
                    </div>
                ))}
            </Slider>
        </Container>
    );
};

export default Index;
