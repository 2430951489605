import React from "react";
import { Row, Col } from "react-bootstrap";
import { File as OGFile } from "react-og-forms";
import { FaPaperPlane, FaTrash, FaSearch } from "react-icons/fa";

import AdminContainer from "../../../Components/Admin/Container";
import Input, { File, Switch, Select } from "../../../Components/Input";
import Button from "../../../Components/Button";
import Form from "../../../Components/Form";
import ButtonsGrid from "../../../Components/ButtonsGrid";

import api from "../../../service/api";

import { Container, CarouselContainer } from "./styles";

import usePartner from "./usePartner";

function Index({ match }) {
    const { id } = match.params;

    const {
        data,
        image,
        file,
        ufs,
        cities,
        searchCEP,
        handleOnChange,
        onUploadImage,
        onDeleteImage,
        onUploadFile,
        onDeleteFile,
        handleSubmit,
        destroy,
        onChangeCarousel,
    } = usePartner(id);

    return (
        <AdminContainer
            pageTitle={`${id ? "Editar" : "Cadastrar"} Localização`}
        >
            <Container>
                <Row className="d-flex justify-content-center">
                    <Col md="9" sm="10">
                        <Form onSubmit={handleSubmit}>
                            {id && (
                                <Switch
                                    className="col"
                                    type="checkbox"
                                    label="Ativo"
                                    id="is-actived"
                                    name="ativo"
                                    checked={data.ativo}
                                    onChange={handleOnChange}
                                />
                            )}
                            <Input
                                label="Nome*"
                                value={data.nome}
                                name="nome"
                                onChange={handleOnChange}
                            />
                            <Row>
                                <Col md="4" sm="12">
                                    <Input
                                        label="Telefone*"
                                        name="telefone"
                                        value={data.telefone}
                                        onChange={handleOnChange}
                                    />
                                </Col>
                                <Col md="4" sm="12">
                                    <Input
                                        label="Celular*"
                                        name="celular"
                                        value={data.celular}
                                        onChange={handleOnChange}
                                        type="tel"
                                    />
                                </Col>
                                <Col md="4" sm="12">
                                    <Input
                                        label="E-mail para contato*"
                                        name="email_mensagens"
                                        value={data.email_mensagens}
                                        onChange={handleOnChange}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col className="row" md="5">
                                    <Col sm="10">
                                        <Input
                                            label="CEP*"
                                            name="cep"
                                            value={data.cep}
                                            onChange={handleOnChange}
                                        />
                                    </Col>
                                    <Col
                                        className="input-cep-button-container"
                                        sm="2"
                                    >
                                        <Button className="search-cep-button">
                                            <FaSearch onClick={searchCEP} />
                                        </Button>
                                    </Col>
                                </Col>
                                <Col md="3">
                                    <Select
                                        label="Estado*"
                                        name="estado"
                                        value={data.estado}
                                        onChange={handleOnChange}
                                    >
                                        <option>Selecione...</option>
                                        {ufs.map((uf) => (
                                            <option key={uf} value={uf}>
                                                {uf}
                                            </option>
                                        ))}
                                    </Select>
                                </Col>
                                <Col md="4">
                                    <Select
                                        label="Cidade*"
                                        name="cidade"
                                        value={data.cidade}
                                        onChange={handleOnChange}
                                    >
                                        <option>Selecione...</option>
                                        {cities.map((city) => (
                                            <option key={city} value={city}>
                                                {city}
                                            </option>
                                        ))}
                                    </Select>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="6" sm="12">
                                    <Input
                                        label="Endereço*"
                                        name="endereco"
                                        value={data.endereco}
                                        onChange={handleOnChange}
                                    />
                                </Col>
                                <Col md="3" sm="12">
                                    <Input
                                        title="Número"
                                        label="Num.*"
                                        name="numero"
                                        type="number"
                                        value={data.numero}
                                        onChange={handleOnChange}
                                    />
                                </Col>
                                <Col md="3" sm="12">
                                    <Input
                                        title="Complemento"
                                        label="Comp."
                                        name="complemento"
                                        value={data.complemento}
                                        onChange={handleOnChange}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col md="3" sm="12">
                                    <Input
                                        label="Latitude"
                                        name="lat"
                                        type="number"
                                        value={data.lat}
                                        onChange={handleOnChange}
                                    />
                                </Col>
                                <Col md="3" sm="12">
                                    <Input
                                        label="Longitude"
                                        name="long"
                                        type="number"
                                        value={data.long}
                                        onChange={handleOnChange}
                                    />
                                </Col>
                                {/* <Col
                                    className="input-latandlong-button-container"
                                    sm="2"
                                >
                                    <Button className="search-latandlong-button">
                                        <FaSearch onClick={searchLatAndLong} />
                                    </Button>
                                </Col> */}
                            </Row>

                            <Row>
                                <Col className="d-flex justify-content-center">
                                    <File
                                        label="Imagem*"
                                        width="200"
                                        height="200px"
                                        accept="image/*"
                                        file={image}
                                        onUpload={onUploadImage}
                                        onDeleteFile={onDeleteImage}
                                    />
                                </Col>
                                <Col className="d-flex justify-content-center">
                                    <File
                                        label="Arquivo*"
                                        width="200"
                                        height="200px"
                                        accept=".pdf"
                                        fileType="pdf"
                                        file={file}
                                        onUpload={onUploadFile}
                                        onDeleteFile={onDeleteFile}
                                    />
                                </Col>
                            </Row>
                            <CarouselContainer>
                                Carousel
                                <OGFile
                                    accept="image/*"
                                    value={data.carousel || []}
                                    onChange={onChangeCarousel}
                                    baseUrl={api.defaults.baseURL}
                                    headers={api.defaults.headers}
                                    multiple={true}
                                />
                            </CarouselContainer>
                            <ButtonsGrid>
                                <Button variant="success" type="submit">
                                    <FaPaperPlane />
                                    Enviar
                                </Button>
                                {id && (
                                    <Button
                                        variant="outline-danger"
                                        onClick={destroy}
                                    >
                                        <FaTrash />
                                        Remover
                                    </Button>
                                )}
                            </ButtonsGrid>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </AdminContainer>
    );
}

export default Index;
