import React from "react";
import PageDefaultPublicArea from "../../Components/Container";

const PageNotFound404 = () => {
  return (
    <PageDefaultPublicArea>
      <h1>Página Não Encontrada</h1>
    </PageDefaultPublicArea>
  );
};

export default PageNotFound404;
