import React from "react";
import Menu from "../Menu";
import Main from "../Main";

import { Container } from "./styles";

const Index = ({ children, pageTitle }) => {
    document.title = `Ecobrite - ${pageTitle}`;
    return (
        <Container>
            <Menu />
            <Main>
                <header className="header-main">
                    <h1>{pageTitle}</h1>
                </header>
                {children}
            </Main>
        </Container>
    );
};

export default Index;
