import styled from "styled-components";

export const Container = styled.div`
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;

    padding: 1rem !important;

    background-color: rgba(0, 0, 0, 0.3);

    display: flex;
    align-items: flex-end;

    button {
        border: 0;

        outline: 0;
        background: var(--blue);
        color: #fafafa;

        padding: 0 0.5rem !important;
        margin: 1rem auto 0 !important;

        box-shadow: 1px 1px 3px -1px #121212;
        transition: box-shadow 0.2s;

        &:hover {
            filter: brightness(120%);
        }

        &:active {
            box-shadow: none;
        }
    }
`;

export const Grid = styled.div.attrs({
    id: "cookies-popout-grid",
})`
    width: 100%;

    position: relative;
    display: flex;
    flex-wrap: wrap;

    background: #ffff;
    padding: 0 1rem 1rem 1rem !important;

    border-radius: 2px;

    box-shadow: 1px 1px 3px -1px #121212;

    p {
        margin: 1rem 0 0 0 !important;
        font-size: 1.5rem;
    }
`;
