import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";

import Container from "../../Components/Container";
import Button from "../../Components/Button";
import Form from "../../Components/Form";
import Input, { TextArea } from "../../Components/Input";

import Api, { publicRequest } from "../../service/api";
import Mail from "../../service/mail";

import useLoading from "../../hooks/useLoading";

import Notification, { Error } from "../../Modules/Notifications";

function Index() {
    const [data, setData] = useState({});

    const { loading } = useLoading();

    const handleHtml = () => {
        return `
                <!DOCTYPE html>
                <html>
                <head>
                    <style>
                        .container {
                            position: relative;
                            width: 100%;
                        }

                        * {
                            margin: 0;
                            padding: 0;
                        }

                        h1 {
                            color: #115d8c;
                            margin-bottom: 1rem;
                        }

                        .campo {
                            margin-bottom: 1rem;
                        }

                        .campo h2 {
                            color: #115d8c;
                            margin-right: 0.1rem;
                            display: flex;
                        }

                        .campo span {
                            color: black;
                        }
                    </style>
                </head>
                <body>
                    <h1>Novo parceiro</h1>
                    <div class="container">
                        <div class="campo">
                            <h2>Nome</h2>
                            <span>${data.name}</span>
                        </div>
                        <div class="campo">
                            <h2>Cidade</h2>
                            <span>${data.city}</span>
                        </div>
                        <div class="campo">
                            <h2>Telefone</h2>
                            <span>${data.telephone}</span>
                        </div>
                        <div class="campo">
                            <h2>Celular</h2>
                            <span>${data.cellphone}</span>
                        </div>
                        <div class="campo">
                            <h2>E-mail</h2>
                            <span>${data.email}</span>
                        </div>
                        <div class="campo">
                            <h2>Horário para contato</h2>
                            <span>${data.contactHours}</span>
                        </div>
                        <div class="campo">
                            <h2>Mensagem</h2>
                            <span>${data.message}</span>
                        </div>
                    </div>
                </body>
            </html>`;
    };

    function handleValidate() {
        if (!data.name) {
            return { status: false, message: "Nome é obrigatório" };
        }

        if (!data.city) {
            return { status: false, message: "Cidade é obrigatório" };
        }

        if (!data.telephone) {
            return { status: false, message: "Telefone é obrigatório" };
        }

        if (!data.cellphone) {
            return { status: false, message: "Celular é obrigatório" };
        }

        if (!data.email) {
            return { status: false, message: "E-Mail é obrigatório" };
        }

        if (!data.contactHours) {
            return {
                status: false,
                message: "Informar o melhor horário para contato é obrigatório",
            };
        }

        if (!data.message) {
            return { status: false, message: "Mensagem é obrigatório" };
        }

        return { status: true };
    }

    async function handleSubmit(e) {
        e.preventDefault();

        const idLoading = loading();

        try {
            const validate = handleValidate();
            if (!validate.status) {
                Notification("warning", validate.message);

                loading(idLoading);
                return;
            }

            await Api.post(
                "/email",
                {
                    to: Mail.bePartner,
                    subject: "Novo parceiro",
                    html: handleHtml(),
                },
                publicRequest
            );

            setData({
                name: "",
                city: "",
                telephone: "",
                cellphone: "",
                email: "",
                contactHours: "",
                message: "",
            });

            Notification("success", "Mensagem enviada");

            loading(idLoading);
        } catch (error) {
            Error(error);

            loading(idLoading);
        }
    }

    return (
        <Container>
            <header>
                <h1>Seja um parceiro</h1>
            </header>
            <hr />
            <section>
                <p>
                    Solicite mais informação sobre a Ecobrite e tenha seu
                    próprio negócio!
                </p>
                <Row className="d-flex justify-content-center">
                    <Col md="5" sm="10">
                        <Form onSubmit={handleSubmit}>
                            <Input
                                placeholder="Nome*"
                                value={data.name}
                                onChange={(e) =>
                                    setData({ ...data, name: e.target.value })
                                }
                            />
                            <Input
                                placeholder="Cidade*"
                                value={data.city}
                                onChange={(e) =>
                                    setData({ ...data, city: e.target.value })
                                }
                            />
                            <Input
                                type="number"
                                placeholder="Telefone*"
                                value={data.telephone}
                                onChange={(e) =>
                                    setData({
                                        ...data,
                                        telephone: e.target.value,
                                    })
                                }
                            />
                            <Input
                                type="number"
                                placeholder="Celular*"
                                value={data.cellphone}
                                onChange={(e) =>
                                    setData({
                                        ...data,
                                        cellphone: e.target.value,
                                    })
                                }
                            />
                            <Input
                                placeholder="E-Mail*"
                                value={data.email}
                                onChange={(e) =>
                                    setData({
                                        ...data,
                                        email: e.target.value,
                                    })
                                }
                            />
                            <TextArea
                                placeholder="Informe o melhor horário para contato*"
                                value={data.contactHours}
                                onChange={(e) =>
                                    setData({
                                        ...data,
                                        contactHours: e.target.value,
                                    })
                                }
                            />
                            <TextArea
                                placeholder="Mensagem*"
                                value={data.message}
                                onChange={(e) =>
                                    setData({
                                        ...data,
                                        message: e.target.value,
                                    })
                                }
                            />
                            <Row className="d-flex justify-content-center">
                                <Button type="submit">Enviar</Button>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </section>
        </Container>
    );
}

export default Index;
