import React from "react";
import { Link } from "react-router-dom";

import { FiPlusCircle } from "react-icons/fi";

import Container from "../../../Components/Admin/Container";

import Table, { ResponsiveTable } from "../../../Components/Table";

import useHomologatedBrands from "./useHomologatedBrands";

function HomologatedBrands() {
    useHomologatedBrands();

    return (
        <Container pageTitle="Marcas Homologadas">
            <div className="grid-add">
                <Link to="/ctop/marca-homologada" className="add">
                    <FiPlusCircle />
                </Link>
            </div>
            <ResponsiveTable>
                <Table id="homologated-brand-table"></Table>
            </ResponsiveTable>
        </Container>
    );
}

export default HomologatedBrands;
