/* eslint-disable react/react-in-jsx-scope */
import React, { useCallback, useEffect } from "react";
import { render } from "react-dom";

import Products from "../Products";
import Trainings from "../Trainings";
import Contact from "../Contact";
import HomologatedBrands from "../HomologatedBrands";
import GraphicMaterial from "../GraphicMaterial";
import Events from "../Events";
import Releases from "../Releases";
import Home from "../Home";

import App from "../../../App";

function useMenu(client) {
    function handleOnClickProduct() {
        render(
            <Products {...{ client }} />,
            document.getElementById("cdoc-main")
        );
    }

    function handleOnClickTrainings() {
        render(
            <Trainings {...{ client }} />,
            document.getElementById("cdoc-main")
        );
    }

    function handleOnClickContact() {
        render(
            <Contact {...{ client }} />,
            document.getElementById("cdoc-main")
        );
    }

    function handleOnClickHomologatedBrands() {
        render(
            <HomologatedBrands {...{ client }} />,
            document.getElementById("cdoc-main")
        );
    }

    function handleOnClickReleases() {
        render(
            <Releases {...{ client }} />,
            document.getElementById("cdoc-main")
        );
    }

    function handleOnClickGraphicMaterial() {
        render(
            <GraphicMaterial {...{ client }} />,
            document.getElementById("cdoc-main")
        );
    }

    function handleOnClickEvents() {
        render(
            <Events {...{ client }} />,
            document.getElementById("cdoc-main")
        );
    }

    function handleSignOut() {
        render(<App />, document.getElementById("root"));
    }

    const handleOnClickHome = useCallback(() => {
        render(<Home {...{ client }} />, document.getElementById("cdoc-main"));
    }, [client]);

    useEffect(() => {
        handleOnClickHome();
    }, [handleOnClickHome]);

    return {
        handleOnClickProduct,
        handleOnClickTrainings,
        handleOnClickContact,
        handleSignOut,
        handleOnClickHome,
        handleOnClickHomologatedBrands,
        handleOnClickGraphicMaterial,
        handleOnClickEvents,
        handleOnClickReleases,
    };
}

export default useMenu;
