import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Row } from "react-bootstrap";
import Styled from "styled-components";
import { FaTimes } from "react-icons/fa";

import Img from "../../Components/Img";
import Label from "../../Components/Label";
import Button from "../../Components/Button";

import api from "../../service/api";
import { Error } from "../../Modules/Notifications";

import useLoading from "../../hooks/useLoading";

import { useOrder } from "../../hooks/useOrder";

const Figure = Styled.figure`
    display: flex;
    flex-direction: column;

    padding: 1rem!important;
    margin:2rem!important;

    background-color: var(--white);
    border: 2px solid var(--gray-5);
    box-shadow: var(--shadow);

    & img {
        margin: 0 auto!important;
    }

    @media (max-width: 750px) {
        width: 100%;
    }
`;

const Modal = Styled.div`
    visibility: ${(props) => (props.show ? "visible" : "hidden")};

    width: 100%;
    height: 100vh;
    background-color: rgba(51, 51, 51, 0.4);

    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;

`;

const CloseButton = Styled(Button)`
    border-radius: 42px;

    width: 42px!important;
    height: 42px;
    padding: 0!important;

    position: absolute;
    top: 18px;
    right: 18px;

    display: flex;
    justify-content: center;
    align-items: center;

    & svg {
        margin: 0!important;
    }

`;

const Index = () => {
    const [aplications, setAplications] = useState([]);

    const [products, setProducts] = useState([]);

    const [selectedAplication, setSelectedAplication] = useState("");

    const [showProduct, setShowProduct] = useState(false);

    const [selectedProduct, setSelectedProduct] = useState("");

    const { orderProducts } = useOrder();

    const { loading } = useLoading();

    useEffect(() => {
        const indexAplications = async () => {
            const idLoading = loading();

            try {
                const response = await api.get(
                    "/aplicacaos?_sort=Aplicacao:ASC&_limit=-1",
                    {
                        headers: { Authorization: "" },
                    }
                );

                const serializedData = response.data.map((item) => ({
                    value: item.id,
                    label: item.Aplicacao,
                }));

                setAplications(serializedData);

                loading(idLoading);
            } catch (error) {
                Error(error);

                loading(idLoading);
            }
        };

        indexAplications();
    }, [loading]);

    useEffect(() => {
        const indexProducts = async () => {
            const idLoading = loading();

            try {
                const response = await api.get("/produtos?_limit=-1", {
                    headers: { Authorization: "" },
                });

                const orderedProducts = orderProducts(response.data);

                const serializedData = orderedProducts.map((item) => {
                    const {
                        id: ID,
                        Titulo,
                        aplicacaos: Aplicacoes,
                        image,
                        exclusivo,
                    } = item;

                    const serializedAplicacoes = Aplicacoes.map(
                        (aplicacao) => aplicacao.id
                    );

                    return {
                        ID,
                        Titulo,
                        Aplicacoes: serializedAplicacoes,
                        image,
                        exclusivo,
                    };
                });

                if (!selectedAplication) return setProducts([]);

                const filteredData = serializedData.filter(
                    (item) =>
                        item.Aplicacoes.includes(selectedAplication) &&
                        !item.exclusivo
                );

                setProducts(filteredData);

                loading(idLoading);
            } catch (error) {
                Error(error);

                loading(idLoading);
            }
        };

        indexProducts();
    }, [loading, orderProducts, selectedAplication]);

    return (
        <>
            <Modal show={showProduct}>
                <CloseButton
                    variant="success"
                    onClick={() => {
                        setShowProduct(false);
                        setSelectedProduct("");
                    }}
                >
                    <FaTimes />
                </CloseButton>
                <Img
                    src={selectedProduct}
                    alt="Produto noordhen"
                    max-width="600px"
                />
            </Modal>
            <section>
                <header className="col-md-6 col-sm-12">
                    <h2>Catálogo por Aplicação</h2>
                    <Label className="col">
                        <span>Escolha abaixo ou digite a palavra chave</span>
                        <Select
                            options={aplications}
                            defaultValue={selectedAplication}
                            onChange={(event) => {
                                setSelectedAplication(event.value);
                            }}
                        />
                    </Label>
                </header>
                <section>
                    {products.length !== 0 && (
                        <Row>
                            {products.map((product) => (
                                <Figure
                                    key={product.ID}
                                    onClick={() => {
                                        setShowProduct(true);
                                        setSelectedProduct(product.image);
                                    }}
                                    className="cursor-pointer"
                                >
                                    <Img
                                        src={product.image}
                                        alt="Produto noordhen"
                                        max-height="110px"
                                        max-width="200px"
                                        className="border-0 shadow-none"
                                    />
                                </Figure>
                            ))}
                        </Row>
                    )}
                </section>
            </section>
        </>
    );
};

export default Index;
