import { useEffect, useState } from "react";

import useLoading from "../../../hooks/useLoading";

import api, { publicRequest } from "../../../service/api";

import { Error as notificationError } from "../../../Modules/Notifications";

function useEvents(client) {
    const [events, setEvents] = useState([]);

    const { loading } = useLoading();

    useEffect(() => {
        async function index() {
            const idLoading = loading();

            try {
                const response = await api.get(
                    "/eventos?_limit=-1",
                    publicRequest
                );

                const serializedData = response.data.map((item) => {
                    return {
                        Id: item.id,
                        Subject: item.titulo,
                        StartTime: item.inicio,
                        EndTime: item.fim,
                        arquivo: `${api.defaults.baseURL}${item.arquivo.url}`,
                    };
                });

                setEvents(serializedData);

                loading(idLoading);
            } catch (error) {
                notificationError(error);

                loading(idLoading);
            }
        }

        index();
    }, [loading]);

    async function createLogCDOC(cliente, titulo) {
        const idLoading = loading();

        try {
            api.post(
                "/log-cdocs",
                {
                    cliente,
                    cdoc: 6,
                    titulo,
                },
                publicRequest
            );

            loading(idLoading);
        } catch (error) {
            console.error(error);

            loading(idLoading);
            return;
        }
    }

    function eventClick(args) {
        args.cancel = true;

        const {
            event: { Id },
        } = args;

        const event = events.filter((event) => event.Id === Id)[0];

        window.open(event.arquivo, "_blank");
        createLogCDOC(client, event.Subject);
    }

    function cancelEvent(args) {
        args.cancel = true;
    }

    return { events, eventClick, cancelEvent };
}

export default useEvents;
