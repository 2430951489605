import { useEffect, useState } from "react";
import api, { publicRequest } from "../../../service/api";

import { Error as notificationError } from "../../../Modules/Notifications";

import useLoading from "../../../hooks/useLoading";

function useTrainings() {
    const [trainings, setTrainings] = useState([]);
    const [trainingsVideos, setTrainingsVideos] = useState([]);

    const { loading } = useLoading();

    useEffect(() => {
        async function indexTrainingVideos() {
            const idLoading = loading();

            try {
                const response = await api.get(
                    "/videos-treinamentos",
                    publicRequest
                );

                const serializedData = response.data.map((item) => item.url);

                setTrainingsVideos(serializedData);

                loading(idLoading);
            } catch (error) {
                notificationError(error);

                loading(idLoading);
            }
        }

        indexTrainingVideos();
    }, [loading]);

    useEffect(() => {
        async function indexTrainings() {
            const idLoading = loading();

            try {
                const response = await api.get(
                    "/treinamentos?_limit=-1&ativo=true",
                    publicRequest
                );

                const serializedData = response.data.map((item) => {
                    return {
                        id: item.id,
                        arquivo: `${api.defaults.baseURL}${item.arquivo.url}`,
                        imagem: `${api.defaults.baseURL}${item.imagem.url}`,
                    };
                });

                setTrainings(serializedData);

                loading(idLoading);
            } catch (error) {
                notificationError(error);

                loading(idLoading);
            }
        }

        indexTrainings();
    }, [loading]);

    return { trainings, trainingsVideos };
}

export default useTrainings;
