import React from "react";
import { Col, Row } from "react-bootstrap";

import Img from "../../Img";
import CDOCContainer from "../Container";

import { Container, Training, TrainingVideoGrid } from "./styles";

import useTrainings from "./useTrainings";

function Trainings({ client }) {
    const { trainings, trainingsVideos } = useTrainings(client);
    return (
        <CDOCContainer {...{ client, title: "Treinamentos" }}>
            <Container>
                <Row>
                    {trainings.map((training) => (
                        <Col
                            md="4"
                            sm="12"
                            key={training.id}
                            className="d-flex justify-content-center"
                        >
                            <Training
                                href={training.arquivo}
                                target="_black"
                                rel="noopener noreferrer"
                            >
                                <Img
                                    src={training.imagem}
                                    className="border-0 shadow-none"
                                />
                            </Training>
                        </Col>
                    ))}
                </Row>
                <Row>
                    <h2 className="col-12">Vídeos de treinamento</h2>
                    {trainingsVideos.map((item) => (
                        <TrainingVideoGrid>
                            <iframe title={item} src={item}></iframe>
                        </TrainingVideoGrid>
                    ))}
                </Row>
            </Container>
        </CDOCContainer>
    );
}

export default Trainings;
