import styled from "styled-components";

export const Container = styled.div`
    .input-cep-button-container,
    .input-latandlong-button-container {
        display: flex;
        align-items: flex-end;

        @media (max-width: 980px) {
            padding: 0 24px !important;
        }
    }

    .search-cep-button,
    .search-latandlong-button {
        height: 53px;
        width: 53px;

        flex-shrink: 0;
        flex-basis: 53px;

        margin-bottom: 12px !important;
        padding: 0px !important;

        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: 980px) {
            height: 44px;
            width: 100%;

            flex-basis: 100%;
        }

        svg {
            margin: 0 !important;
        }
    }
`;

export const CarouselContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    width: fit-content;

    margin: 0 auto !important;
    margin-top: 1rem !important;

    font-weight: 700;

    ul {
        margin: 0 !important;
    }
`;
