import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Styled from "styled-components";

import api from "../../service/api";
import { Error } from "../../Modules/Notifications";

import useLoading from "../../hooks/useLoading";

import Img from "../Img";

const GridPP = Styled(Row)`
    & div {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem!important;
    }
`;

const CategoryCatalog = () => {
    const { loading } = useLoading();

    const [pp, setPP] = useState([
        { file: "", image: "" },
        { file: "", image: "" },
        { file: "", image: "" },
        { file: "", image: "" },
        { file: "", image: "" },
        { file: "", image: "" },
        { file: "", image: "" },
        { file: "", image: "" },
    ]);

    useEffect(() => {
        async function index() {
            const idLoading = loading();

            try {
                const response = await api.get(
                    "/configuracoes-produtos-mercados",
                    {
                        headers: {
                            Authorization: "",
                        },
                    }
                );

                response.data.forEach((item) => {
                    switch (item.type) {
                        case 2:
                            if (Array.isArray(item.object)) {
                                const pp = item.object.map((item) => {
                                    return {
                                        image: `${api.defaults.baseURL}${item.image.url}`,
                                        file: `${api.defaults.baseURL}${item.file.url}`,
                                    };
                                });

                                setPP(pp);
                            }
                            break;

                        default:
                            break;
                    }
                });

                loading(idLoading);
            } catch (error) {
                Error(error);

                loading(idLoading);
            }
        }

        index();
    }, [loading]);

    return (
        <>
            <GridPP>
                <Col md="2" sm="12">
                    <a
                        href={pp[0].file}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Img
                            src={pp[0].image}
                            alt="PP 1"
                            max-width="200px"
                            className="border-0 shadow-none"
                        />
                    </a>
                </Col>
                <Col md="2" sm="12">
                    <a
                        href={pp[1].file}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Img
                            src={pp[1].image}
                            alt="PP 2"
                            max-width="200px"
                            className="border-0 shadow-none"
                        />
                    </a>
                </Col>
                <Col md="2" sm="12">
                    <a
                        href={pp[2].file}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Img
                            src={pp[2].image}
                            alt="PP 3"
                            max-width="200px"
                            className="border-0 shadow-none"
                        />
                    </a>
                </Col>
                <Col md="2" sm="12">
                    <a
                        href={pp[3].file}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Img
                            src={pp[3].image}
                            alt="PP 4"
                            max-width="200px"
                            className="border-0 shadow-none"
                        />
                    </a>
                </Col>
                <Col md="2" sm="12">
                    <a
                        href={pp[4].file}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Img
                            src={pp[4].image}
                            alt="PP 5"
                            max-width="200px"
                            className="border-0 shadow-none"
                        />
                    </a>
                </Col>
                <Col md="2" sm="12">
                    <a
                        href={pp[5].file}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Img
                            src={pp[5].image}
                            alt="PP 6"
                            max-width="200px"
                            className="border-0 shadow-none"
                        />
                    </a>
                </Col>
            </GridPP>
        </>
    );
};

export default CategoryCatalog;
