import { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";

import notification, {
    Error as notificationError,
} from "../../../Modules/Notifications";
import confirm from "../../../Modules/AlertConfirm";

import api from "../../../service/api";

import useLoading from "../../../hooks/useLoading";

function useEvent() {
    const { id } = useParams();

    const history = useHistory();

    const { loading } = useLoading();

    const initialData = useCallback(
        () => ({
            titulo: "",
            inicio: null,
            fim: null,
        }),
        []
    );

    const [data, setData] = useState(initialData());

    const [arquivo, setArquivo] = useState({});

    const show = useCallback(() => {
        async function show() {
            const idLoading = loading();

            try {
                const response = await api.get(`/eventos/${id}`);

                const { titulo, inicio, fim } = response.data;

                setData({ titulo, inicio, fim });

                const arquivo = {
                    id: response.data.arquivo.id,
                    preview: `${api.defaults.baseURL}${response.data.arquivo.url}`,
                };

                setArquivo(arquivo);

                loading(idLoading);
            } catch (error) {
                notificationError(error);

                loading(idLoading);
            }
        }

        show();
    }, [id, loading]);

    useEffect(() => {
        if (id) show();
    }, [show, id]);

    function handleOnChange({ target }) {
        const { name, value } = target;

        setData({ ...data, [name]: value });
    }

    function handleOnChangeArquivo(files) {
        const file = files[0];

        if (arquivo.id) {
            confirm(
                "Alterar Arquivo",
                "Tem certeza que deseja alterar ?",
                async () => {
                    const idLoading = loading();

                    try {
                        await api.delete(`/upload/files/${arquivo.id}`);
                        const response = await uploadFile(file);
                        await api.put(`/eventos/${id}`, {
                            arquivo: response,
                        });
                        show();
                        notification("success", "Arquivo alterada");

                        loading(idLoading);
                    } catch (error) {
                        notificationError(error);

                        loading(idLoading);
                    }
                }
            );
        } else {
            setArquivo({
                file,
                preview: URL.createObjectURL(file),
            });
        }
    }

    function handleOnDeleteArquivo() {
        if (arquivo.id) {
            notification("warning", "Não é possivel remover");
        } else {
            setArquivo({});
        }
    }

    function handleValidate() {
        if (!data.titulo) {
            return { status: false, message: "Título é obrigatório" };
        }

        if (!data.inicio) {
            return { status: false, message: "Início é obrigatório" };
        }

        if (!data.fim) {
            return { status: false, message: "Fim é obrigatório" };
        }

        if (Object.keys(arquivo).length === 0) {
            return { status: false, message: "Arquivo é obrigatório" };
        }

        return { status: true };
    }

    const uploadFile = async (file) => {
        const idLoading = loading();

        let data = new FormData();
        data.append(`files`, file);
        const response = await api.post("/upload", data);

        loading(idLoading);

        return response.data[0];
    };

    async function getData() {
        if (!arquivo.id) {
            var uploaded = await uploadFile(arquivo.file);
        }

        return { ...data, arquivo: uploaded };
    }

    async function create(data) {
        const idLoading = loading();

        try {
            await api.post("/eventos", data);

            notification("success", "Evento cadastrado");

            loading(idLoading);
        } catch (error) {
            notificationError(error);

            loading(idLoading);
        }
    }

    async function update(id, data) {
        const idLoading = loading();

        try {
            await api.put(`/eventos/${id}`, data);

            notification("success", "Evento alterado");

            loading(idLoading);
        } catch (error) {
            notificationError(error);

            loading(idLoading);
        }
    }

    function destroy() {
        confirm(
            "Remover Evento",
            "Tem certeza que deseja remover ?",
            async () => {
                const idLoading = loading();

                try {
                    await api.delete(`/eventos/${id}`);
                    notification("success", "Evento removido");
                    history.push("/ctop/eventos");

                    loading(idLoading);
                } catch (error) {
                    notificationError(error);

                    loading(idLoading);
                }
            }
        );
    }

    async function handleOnSubmit(event) {
        try {
            event.preventDefault();

            const validate = handleValidate();
            if (!validate.status) {
                return notification("warning", validate.message);
            }

            if (!id) {
                const createData = await getData();

                await create(createData);
            } else {
                await update(id, data);
            }

            history.push("/ctop/eventos");
        } catch (error) {
            notificationError(error);
        }
    }

    return {
        data,
        arquivo,
        handleOnChange,
        handleOnChangeArquivo,
        handleOnDeleteArquivo,
        handleOnSubmit,
        destroy,
    };
}

export default useEvent;
