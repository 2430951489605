import React from "react";

import LogoUniversidade from "../../../Assets/Images/logo-ecobrite-universidade.png";

import Img from "../../Img";

import { Container, Header } from "./styles";

function Index({ children, client, title }) {
    return (
        <Container>
            <Header>
                <Img
                    src={LogoUniversidade}
                    alt="Logo universidade ecobrite"
                    className="border-0 shadow-none"
                />

                <h2>{title}</h2>

                <span>
                    Bem-vindo, <strong>{client.Nome}</strong>
                </span>
            </Header>

            <main>{children}</main>
        </Container>
    );
}

export default Index;
