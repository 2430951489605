import { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import "jquery-mask-plugin";
import axios from "axios";

import Api from "../../../service/api";

import useLoading from "../../../hooks/useLoading";

import Confirm from "../../../Modules/AlertConfirm";
import UploadFiles from "../../../Modules/UploadFiles";
import Notification, { Error } from "../../../Modules/Notifications";

function usePartner(id) {
    const history = useHistory();

    const [data, setData] = useState({});

    const [image, setImage] = useState({});

    const [file, setFile] = useState({});

    const [ufs, setUfs] = useState([]);

    const [cities, setCities] = useState([]);

    const { loading } = useLoading();

    useEffect(() => {
        function setMask() {
            $("input[name=telefone]").mask("(00) 0000-0000");
            $("input[name=celular]").mask("(00) 0 0000-0000");
            $("input[name=cep]").mask("00000-000");
        }

        setMask();
    });

    useEffect(() => {
        async function renderCities() {
            const idLoading = loading();

            const response = await axios.get(
                `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${data.estado}/municipios`
            );

            const cities = response.data.map((city) => city.nome);
            setCities(cities);

            loading(idLoading);
        }

        renderCities();
    }, [data.estado, loading]);

    useEffect(() => {
        async function renderUfs() {
            const idLoading = loading();

            const response = await axios.get(
                "https://servicodados.ibge.gov.br/api/v1/localidades/estados"
            );

            const ufInitials = response.data.map((uf) => uf.sigla);
            setUfs(ufInitials.sort());

            loading(idLoading);
        }

        renderUfs();
    }, [loading]);

    const show = useCallback(() => {
        async function show() {
            const idLoading = loading();

            try {
                const response = await Api.get(`/parceiros/${id}`);

                const data = response.data;

                setData(data);

                const image = {
                    id: response.data.imagem.id,
                    preview: `${Api.defaults.baseURL}${response.data.imagem.url}`,
                };

                setImage(image);

                const file = {
                    id: response.data.arquivo.id,
                    preview: `${Api.defaults.baseURL}${response.data.arquivo.url}`,
                };

                setFile(file);

                loading(idLoading);
            } catch (error) {
                Error(error);

                loading(idLoading);
            }
        }

        show();
    }, [id, loading]);

    useEffect(() => {
        if (id) show();
    }, [show, id]);

    async function searchLatAndLong() {
        const idLoading = loading();

        try {
            const { numero, estado, cep } = data;
            const endereco = String(data.endereco).replaceAll(" ", "+");
            const cidade = String(data.cidade).replaceAll(" ", "+");
            const bairro = String(data.bairro).replaceAll(" ", "+");

            const address = `${endereco},+${numero}+-+${bairro},+${cidade}+-+${estado},+${cep},+Brasil`;

            const {
                data: {
                    results: {
                        0: {
                            geometry: {
                                location: { lat, lng: long },
                            },
                        },
                    },
                },
            } = await axios.get(
                `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${process.env.REACT_APP_GEOCODE_KEY}`
            );

            setData({ ...data, lat, long });

            loading(idLoading);
        } catch (error) {
            Error(error);

            loading(idLoading);
        }
    }

    async function searchCEP(event) {
        event.preventDefault();

        const idLoading = loading();

        try {
            const cep = $("input[name=cep]").cleanVal();

            if (!cep) return;

            const response = await axios.get(
                `https://viacep.com.br/ws/${cep}/json/`
            );

            const { uf, localidade, bairro, logradouro } = response.data;

            setData({
                ...data,
                estado: uf,
                cidade: localidade,
                bairro,
                endereco: logradouro,
            });

            loading(idLoading);
        } catch (error) {
            Notification("Buscar informações sobre o cep falhou");

            loading(idLoading);
        }
    }

    function onUploadImage(files) {
        const file = files[0];

        if (image.id) {
            Confirm(
                "Alterar Imagem",
                "Tem certeza que deseja alterar ?",
                async () => {
                    const idLoading = loading();

                    try {
                        await Api.delete(`/upload/files/${image.id}`);

                        const response = await UploadFiles(file);

                        await Api.put(`/parceiros/${id}`, { imagem: response });

                        show();
                        Notification("success", "Imagem alterada");

                        loading(idLoading);
                    } catch (error) {
                        Error(error);

                        loading(idLoading);
                    }
                }
            );
        } else {
            setImage({
                file,
                preview: URL.createObjectURL(file),
            });
        }
    }

    function onDeleteImage() {
        if (image.id) {
            Notification("warning", "Não é possivel remover");
        } else {
            setImage({});
        }
    }

    function handleOnChange(event) {
        const { name, value } = event.currentTarget;
        setData({ ...data, [name]: value });
    }

    function onUploadFile(files) {
        const uploadedFile = files[0];
        if (file.id) {
            Confirm(
                "Alterar Arquivo",
                "Tem certeza que deseja alterar ?",
                async () => {
                    const idLoading = loading();

                    try {
                        await Api.delete(`/upload/files/${file.id}`);

                        const response = await UploadFiles(uploadedFile);

                        await Api.put(`/parceiros/${id}`, {
                            arquivo: response,
                        });

                        show();

                        Notification("success", "Arquivo alterado");

                        loading(idLoading);
                    } catch (error) {
                        Error(error);

                        loading(idLoading);
                    }
                }
            );
        } else {
            setFile({
                file: uploadedFile,
                preview: URL.createObjectURL(uploadedFile),
            });
        }
    }

    function onDeleteFile() {
        if (file.id) {
            Notification("warning", "Não é possivel remover");
        } else {
            setFile({});
        }
    }

    function onChangeCarousel(files) {
        setData({ ...data, carousel: files });
    }

    function handleValidate() {
        if (!data.nome) {
            return { status: false, message: "Nome é obrigatório" };
        }

        if (!data.telefone) {
            return { status: false, message: "Telefone é obrigatório" };
        }

        if (!data.celular) {
            return { status: false, message: "Celular é obrigatório" };
        }

        if (!data.email_mensagens) {
            return {
                status: false,
                message: "E-mail para contato é obrigatório",
            };
        }

        if (!data.cep) {
            return {
                status: false,
                message: "CEP é obrigatório",
            };
        }

        if (!data.estado) {
            return {
                status: false,
                message: "Estado é obrigatório",
            };
        }

        if (!data.cidade) {
            return {
                status: false,
                message: "Cidade é obrigatório",
            };
        }

        if (!data.endereco) {
            return {
                status: false,
                message: "Endereço é obrigatório",
            };
        }

        if (!data.numero) {
            return {
                status: false,
                message: "Número é obrigatório",
            };
        }

        if (Object.keys(image).length === 0) {
            return { status: false, message: "Imagem é obrigatório" };
        }

        if (Object.keys(file).length === 0) {
            return { status: false, message: "Arquivo é obrigatório" };
        }

        return { status: true };
    }

    async function handleSubmit(e) {
        e.preventDefault();

        const idLoading = loading();

        try {
            const validate = handleValidate();
            if (!validate.status) {
                Notification("warning", validate.message);
                loading(idLoading);
                return;
            }

            if (!image.id) {
                var imagem = await UploadFiles(image.file);
            }

            if (!file.id) {
                var arquivo = await UploadFiles(file.file);
            }

            if (!id) {
                await Api.post("/parceiros", {
                    ...data,
                    imagem,
                    arquivo,
                });

                Notification("success", "Parceiro cadastrado");
            } else {
                await Api.put(`/parceiros/${id}`, data);

                Notification("success", "Parceiro alterado");
            }

            history.push("/ctop/parceiros");

            loading(idLoading);
        } catch (error) {
            Error(error);

            loading(idLoading);
        }
    }

    function destroy() {
        Confirm(
            "Remover Parceiro",
            "Tem certeza que deseja remover ?",
            async () => {
                const idLoading = loading();

                try {
                    await Api.delete(`/parceiros/${id}`);

                    Notification("success", "Parceiro removido");
                    history.push("/ctop/parceiros");

                    loading(idLoading);
                } catch (error) {
                    Error(error);

                    loading(idLoading);
                }
            }
        );
    }

    return {
        data,
        image,
        file,
        ufs,
        cities,
        searchCEP,
        searchLatAndLong,
        handleOnChange,
        onUploadImage,
        onDeleteImage,
        onUploadFile,
        onDeleteFile,
        handleSubmit,
        destroy,
        onChangeCarousel,
    };
}

export default usePartner;
