import { useCallback, useEffect, useState } from "react";
import $ from "jquery";
import "jquery-mask-plugin";

import notification, { Error } from "../../Modules/Notifications";

import api, { publicRequest } from "../../service/api";

import useLoading from "../../hooks/useLoading";

function usePartner(id) {
    const { loading } = useLoading();

    const [data, setData] = useState({});

    const [contact, setContact] = useState({});

    const show = useCallback(() => {
        async function show() {
            const idLoading = loading();

            try {
                const { data } = await api.get(
                    `/parceiros/${id}`,
                    publicRequest
                );

                const imagem = `${api.defaults.baseURL}${data.imagem.url}`;

                let carousel;
                if (data.carousel && Array.isArray(data.carousel)) {
                    carousel = data.carousel.map(
                        (item) => `${api.defaults.baseURL}${item.url}`
                    );
                }

                setData({ ...data, imagem, carousel });

                loading(idLoading);
            } catch (error) {
                Error(error);

                loading(idLoading);
            }
        }

        show();
    }, [id, loading]);

    useEffect(() => {
        show();
    }, [show]);

    useEffect(() => {
        $("input[name=telefone]").mask("(00) 0000-0000");
        $("input[name=celular]").mask("(00) 0 0000-0000");
    }, []);

    const carouselSettings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
    };

    function handleContactInputOnChange(e) {
        const { name, value } = e.currentTarget;

        setContact({ ...contact, [name]: value });
    }

    const handleHtml = () => {
        return `
                <!DOCTYPE html>
                <html>
                <head>
                    <style>
                        .container {
                            position: relative;
                            width: 100%;
                        }

                        * {
                            margin: 0;
                            padding: 0;
                        }

                        h1 {
                            color: #115d8c;
                            margin-bottom: 1rem;
                        }

                        .campo {
                            margin-bottom: 1rem;
                        }

                        .campo h2 {
                            color: #115d8c;
                            margin-right: 0.1rem;
                            display: flex;
                        }

                        .campo span {
                            color: black;
                        }
                    </style>
                </head>
                <body>
                    <h1>Novo contato pelo site</h1>
                    <div class="container">
                        <div class="campo">
                            <h2>Nome</h2>
                            <span>${contact.nome}</span>
                        </div>
                        <div class="campo">
                            <h2>Cidade</h2>
                            <span>${contact.cidade}</span>
                        </div>
                        <div class="campo">
                            <h2>Telefone Fixo</h2>
                            <span>${contact.telefone}</span>
                        </div>
                        <div class="campo">
                            <h2>Telefone Celular</h2>
                            <span>${contact.celular}</span>
                        </div>
                        <div class="campo">
                            <h2>Horário para contato</h2>
                            <span>${contact.horario_contato}</span>
                        </div>
                        <div class="campo">
                            <h2>Email</h2>
                            <span>${contact.email}</span>
                        </div>
                        <div class="campo">
                            <h2>Mensagem</h2>
                            <span>${contact.mensagem}</span>
                        </div>
                    </div>
                </body>
            </html>`;
    };

    function clear() {
        setData({
            nome: "",
            cidade: "",
            telefone: "",
            celular: "",
            horario_contato: "",
            email: "",
            mensagem: "",
        });
    }

    function handleValidade() {
        if (!contact.nome) {
            return { status: false, message: "Nome é obrigatório" };
        }

        if (!contact.cidade) {
            return { status: false, message: "Cidade é obrigatório" };
        }

        if (!contact.telefone) {
            return { status: false, message: "Telefone é obrigatório" };
        }

        if (!contact.celular) {
            return { status: false, message: "Celular é obrigatório" };
        }

        if (!contact.horario_contato) {
            return {
                status: false,
                message: "Horário para contato é obrigatório",
            };
        }

        if (!contact.email) {
            return { status: false, message: "Email é obrigatório" };
        }

        if (!contact.mensagem) {
            return { status: false, message: "Mensagem é obrigatório" };
        }

        return { status: true };
    }

    async function handleSubmitContact(e) {
        e.preventDefault();

        const idLoading = loading();
        try {
            const validate = handleValidade();
            if (!validate.status) {
                notification("warning", validate.message);

                loading(idLoading);
                return;
            }

            await api.post(
                "/email",
                {
                    to: data.email_mensagens,
                    subject: "Novo contato pelo site",
                    html: handleHtml(),
                },
                publicRequest
            );

            clear();

            notification("success", "Mensagem enviada");

            loading(idLoading);
        } catch (error) {
            Error(error);

            loading(idLoading);
        }
    }

    return {
        carouselSettings,
        data,
        contact,
        handleContactInputOnChange,
        handleSubmitContact,
    };
}

export default usePartner;
