import React, { useState } from "react";
import {
    FaBox,
    FaUser,
    FaUserLock,
    FaTag,
    FaPuzzlePiece,
    FaNewspaper,
    FaSignOutAlt,
    FaHome,
    FaBars,
    FaCogs,
    FaMapMarkedAlt,
    FaGraduationCap,
} from "react-icons/fa";
import { NavLink } from "react-router-dom";

import Aside from "../Aside";

const Index = () => {
    const [navVisible, setNavVisible] = useState("navNotVisible");

    return (
        <Aside>
            <div
                className="toggle-menu cursor-pointer"
                onClick={() => {
                    if (navVisible === "navNotVisible") {
                        return setNavVisible("navVisible");
                    }

                    return setNavVisible("navNotVisible");
                }}
            >
                <FaBars />
                <span>Menu</span>
            </div>
            <nav className={navVisible}>
                <NavLink to="/ctop/home">
                    <FaHome />
                    <span>Home</span>
                </NavLink>

                <NavLink to="/ctop/produtos">
                    <FaBox />
                    <span>Produtos</span>
                </NavLink>

                <NavLink to="/ctop/parceiros">
                    <FaMapMarkedAlt />
                    <span>Onde Encontrar</span>
                </NavLink>

                <NavLink to="/ctop/clientes">
                    <FaUser />
                    <span>Clientes</span>
                </NavLink>

                <NavLink to="/ctop/noticias">
                    <FaNewspaper />
                    <span>Noticias</span>
                </NavLink>

                <NavLink to="/ctop/treinamentos">
                    <FaGraduationCap />
                    <span>Treinamentos</span>
                </NavLink>

                <NavLink to="/ctop/produtos/aplicacoes">
                    <FaPuzzlePiece />
                    <span>Aplicações</span>
                </NavLink>

                <NavLink to="/ctop/produtos/linhas">
                    <FaTag />
                    <span>Linhas</span>
                </NavLink>

                <NavLink to="/ctop/usuarios">
                    <FaUserLock />
                    <span>Usuários</span>
                </NavLink>

                <NavLink to="/ctop/settings">
                    <FaCogs />
                    <span>Configurações</span>
                </NavLink>

                <NavLink to="/ctop">
                    <FaSignOutAlt />
                    <span>Sair</span>
                </NavLink>
            </nav>
        </Aside>
    );
};

export default Index;
