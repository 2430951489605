import React from "react";
import { GrEdit } from "react-icons/gr";
import { FiTrash2 } from "react-icons/fi";
import { Col } from "react-bootstrap";
import { FaPaperPlane, FaEraser } from "react-icons/fa";

import Container from "../../../Components/Admin/Container";
import Input from "../../../Components/Input";
import Button from "../../../Components/Button";
import Form from "../../../Components/Form";
import Table, { ResponsiveTable } from "../../../Components/Table";

import useLines from "./useLines";

const Index = (props) => {
    const {
        id,
        title,
        data,
        lines,
        handlerSubmit,
        handlerClear,
        handlerOnChange,
        handlerDestroy,
        handlerGotoUpdate,
    } = useLines(props);

    return (
        <Container pageTitle={title}>
            <Form
                onSubmit={handlerSubmit}
                className="row d-flex justify-center"
            >
                <Col>
                    <Input
                        type="text"
                        name="Linha"
                        label="Linha:"
                        value={data.Linha}
                        onChange={handlerOnChange}
                    />
                </Col>
                <Col>
                    <Input
                        type="number"
                        name="ordem"
                        label="Ordem:"
                        value={data.ordem}
                        onChange={handlerOnChange}
                    />
                </Col>
                <Col className="d-flex align-items-center justify-content-around">
                    {id && (
                        <Button
                            variant="outline-warning"
                            onClick={handlerClear}
                        >
                            <FaEraser /> Limpar
                        </Button>
                    )}
                    <Button type="submit" variant="success">
                        <FaPaperPlane />
                        Enviar
                    </Button>
                </Col>
            </Form>
            <ResponsiveTable>
                <Table>
                    <thead>
                        <tr>
                            <th>Linha</th>
                            <th>Ordem</th>
                            <th>Editar</th>
                            <th>Remover</th>
                        </tr>
                    </thead>
                    <tbody>
                        {lines.map((line) => (
                            <tr key={line.id}>
                                <td>{line.Linha}</td>
                                <td>{line.ordem}</td>
                                <td>
                                    <GrEdit
                                        className="cursor-pointer"
                                        data-id={line.id}
                                        onClick={handlerGotoUpdate}
                                    />
                                </td>
                                <td>
                                    <FiTrash2
                                        color="red"
                                        className="cursor-pointer"
                                        data-id={line.id}
                                        onClick={handlerDestroy}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </ResponsiveTable>
        </Container>
    );
};

export default Index;
