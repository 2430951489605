import React, { useEffect, useState } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

import "./styles.css";

import Logo from "../../Assets/Images/logo-ecobrite.png";

import Img from "../../Components/Img";

import Api, { publicRequest } from "../../service/api";

import useLoading from "../../hooks/useLoading";

import { Error } from "../../Modules/Notifications";

const Index = () => {
    const [company, setCompany] = useState("");

    const { loading } = useLoading();

    useEffect(() => {
        async function showCompany() {
            const idLoading = loading();

            try {
                const response = await Api.get(
                    "/configuracoes-cartoes?_limit=1",
                    publicRequest
                );

                if (response.data.length === 0) {
                    loading(idLoading);
                    return;
                }

                const company = `${Api.defaults.baseURL}${response.data[0].arquivo.url}`;
                setCompany(company);

                loading(idLoading);
            } catch (error) {
                Error(error);

                loading(idLoading);
            }
        }

        showCompany();
    }, [loading]);

    return (
        <Navbar collapseOnSelect expand="lg" className="navbar-public">
            <Navbar.Brand className="navbar-public-logo">
                <Link to="/">
                    <Img
                        className="border-0 shadow-none"
                        src={Logo}
                        max-height="88px"
                    />
                </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                    {company && (
                        <a
                            href={company}
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            Empresa
                        </a>
                    )}
                    <Link to="/universidade-ecobrite">
                        Universidade Ecobrite
                    </Link>
                    <Link to="/produtos-e-mercado">Produtos</Link>
                    <Link to="/onde-encontrar">Onde Encontrar</Link>
                    <Link to="/seja-parceiro">Seja um parceiro</Link>
                    <Link to="/contato">Contato</Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

export default Index;
