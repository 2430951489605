import styled from "styled-components";

export const Container = styled.div`
    .partner-header-container {
        display: flex;
        flex-direction: row-reverse;
        padding: 5vw 0 2vw !important;

        @media (max-width: 900px) {
            flex-direction: column;
        }

        .partner-image-container {
            display: flex;
            justify-content: center;
            padding: 5vw;

            img {
                width: 100%;
                max-width: 360px;

                border: 5px solid var(--white);
                box-shadow: var(--shadow);
            }
        }

        .partner-wellcome-container {
            display: flex;
            align-items: center;

            @media (max-width: 900px) {
                justify-content: center;
                text-align: center;

                margin-bottom: 1rem !important;
            }

            h2 {
                margin: 0 !important;
            }
        }
    }

    .partner-map-container {
        margin: 5% !important;
    }

    .partner-address-container {
        margin-top: 0.5rem !important;

        h3,
        p {
            margin: 0 !important;
        }

        h3 {
            margin-bottom: 1rem !important;

            font-size: 1.8rem !important;

            display: flex;
            align-items: center;

            svg {
                margin-right: 5px !important;
                color: var(--red);
            }
        }

        p {
            font-size: 1.5rem;
        }
    }

    .partner-contact-information-container {
        display: flex;
        flex-direction: column;

        font-size: 1.5rem;

        span {
            display: flex;
            align-items: center;

            margin-top: 0.5rem !important;

            i {
                display: flex;
                justify-content: center;
                align-items: center;

                width: 24px;
                height: 24px;
                flex: 0 0 24px;

                border-radius: 12px;

                margin-right: 5px !important;
            }
        }
    }

    .partner-contact-form-container {
        position: relative;
        float: right;

        text-align: center;

        margin: 0 auto !important;

        button {
            margin: 0 auto !important;
        }
    }
`;
