import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";

import api from "../../../service/api";

import useLoading from "../../../hooks/useLoading";

import Notification, { Error } from "../../../Modules/Notifications";
import Confirm from "../../../Modules/AlertConfirm";

function useLines(props) {
    const { id } = props.match.params;

    const { loading } = useLoading();

    const initialData = useCallback(
        () => ({
            Linha: "",
            ordem: "",
        }),
        []
    );

    const history = useHistory();

    const [lines, setLines] = useState([]);

    const [data, setData] = useState(initialData());

    const [title, setTitle] = useState("");

    useEffect(() => {
        const show = async () => {
            const idLoading = loading();

            try {
                const {
                    data: { Linha, ordem },
                } = await api.get(`/linhas/${id}`);

                setData({ Linha, ordem: ordem || "" });

                loading(idLoading);
            } catch (error) {
                Error(error);

                loading(idLoading);
            }
        };

        if (id) {
            setTitle("Editar Linha");
            show();
        } else {
            setData("");
            setTitle("Cadastrar Linha");
        }
    }, [id, loading]);

    const index = useCallback(() => {
        const index = async () => {
            const idLoading = loading();

            try {
                const response = await api.get("/linhas?_sort=ordem:ASC");

                setLines(response.data);

                loading(idLoading);
                return;
            } catch (error) {
                Error(error);

                loading(idLoading);
            }
        };

        index();
    }, [loading]);

    useEffect(() => {
        index();
    }, [index]);

    const handleValidate = () => {
        return lines.find((line) => line.Linha === data)
            ? { status: false, message: "Linha já cadastrada" }
            : { status: true };
    };

    const create = async () => {
        const idLoading = loading();

        try {
            await api.post("/linhas", data);

            setData("");

            Notification("success", "Linha cadastrada");

            index();

            loading(idLoading);
        } catch (error) {
            Error(error);

            loading(idLoading);
        }
    };

    const update = async () => {
        const idLoading = loading();

        try {
            await api.put(`/linhas/${id}`, data);

            setData("");
            Notification("success", "Linha atualizada");
            index();
            history.push("/ctop/produtos/linhas");

            loading(idLoading);
        } catch (error) {
            Error(error);

            loading(idLoading);
        }
    };

    const handlerDestroy = (id) => {
        Confirm(
            "Remover Linha",
            "Tem certeza que deseja remover ?",
            async () => {
                const idLoading = loading();

                try {
                    await api.delete(`/linhas/${id}`);

                    Notification("success", "Linha removida");
                    index();

                    loading(idLoading);
                } catch (error) {
                    Error(error);

                    loading(idLoading);
                }
            }
        );
    };

    const handlerSubmit = (event) => {
        event.preventDefault();

        const validate = handleValidate();
        if (!validate.status) {
            return Notification("error", validate.message);
        }

        if (!id) return create();

        return update();
    };

    const handlerClear = () => {
        setData(initialData());

        history.push("/ctop/produtos/linhas");
    };

    const handlerOnChange = (event) => {
        const { name, value } = event.target;

        setData({ ...data, [name]: value });
    };

    const handlerGotoUpdate = (event) => {
        const { id } = event.currentTarget.dataset;

        history.push(`/ctop/produtos/linhas/${id}`);
    };

    return {
        id,
        title,
        data,
        lines,
        handlerSubmit,
        handlerDestroy,
        handlerClear,
        handlerOnChange,
        handlerGotoUpdate,
    };
}

export default useLines;
