import styled from "styled-components";

export const Container = styled.div`
    & button {
        margin-left: 1rem !important;
    }
`;

export const Figure = styled.figure`
    display: flex;
    flex-direction: column;

    padding: 1rem !important;
    margin: 2rem !important;

    background-color: var(--white);
    border: 2px solid var(--gray-5);
    box-shadow: var(--shadow);

    & img {
        margin: 0 auto !important;
    }

    @media (max-width: 750px) {
        width: 100%;
    }
`;
