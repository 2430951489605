import React, { useEffect, useState } from "react";
import Styled from "styled-components";
import { Row } from "react-bootstrap";
import { FaEye, FaTrash } from "react-icons/fa";

import Button from "../Button";

import useLoading from "../../hooks/useLoading";

const Img = Styled.img``;

const Video = Styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
    padding: 0.5rem 0.8rem !important;

    font-size: 1.2rem;

    background-color: var(--white);
    box-shadow: var(--shadow);

    margin: 1rem 1rem!important;

    border-radius: 5px;


    & a {
        font-family: var(--font-title);
        font-weight: 600;
        font-size: 2.3rem !important;
        padding: 4px 20px !important;

        display: flex;
        align-items: center;
        justify-content: center;

        box-shadow: var(--shadow);

        width: ${(props) => props.width || "auto"} !important;

        & svg {
            margin-right: 10px !important;
        }
        margin: 0!important;
    }

    & img {
        margin: 1rem 0 !important;
    }
`;

const Buttons = Styled(Row)`
    width: 100%;
    justify-content: space-around;
    margin-bottom: 1rem!important;

    & button svg {
        margin: 0!important;
    }
    
    & a svg {
        margin: 0!important;
    }
`;

const Index = (props) => {
    const [srcImage, setSrcImage] = useState("");

    const { loading } = useLoading();

    useEffect(() => {
        const idLoading = loading();

        try {
            const { src } = props;

            const id = new URL(src).searchParams.get("v");

            setSrcImage(`https://img.youtube.com/vi/${id}/mqdefault.jpg`);

            loading(idLoading);
        } catch (error) {
            Error("URL inválida");

            loading(idLoading);
        }
    }, [loading, props]);

    return (
        <Video>
            <Img
                alt="Video produto noordhen"
                src={srcImage}
                width="200px"
                height="150px"
            />
            <Buttons>
                <Button variant="outline-danger" onClick={props.onDelete}>
                    <FaTrash />
                </Button>
                <a
                    href={props.src}
                    className="btn btn-primary"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <FaEye />
                </a>
            </Buttons>
        </Video>
    );
};

export default Index;
