import React from "react";
import Styled from "styled-components";

import { Col } from "react-bootstrap";
import { FaPhoneAlt, FaWhatsapp } from "react-icons/fa";

import Container from "../../Components/Container";
import ContactForm from "../../Components/ContactForm";

const ContactWays = Styled.div`
    & p {
        display: flex;
        align-items: center;
        margin-top: 0.5rem!important;
    }

    & p div {
        display: flex;
        justify-content: center;
        align-items: center;

        background-color: var(--green);
        color: var(--white);
        padding: 0.7rem !important;
        border-radius: 120px;
        margin-right: 0.5rem!important;
    }

    .phone-ecobrite {
        background-color: var(--blue);
    }
`;

const Contact = () => {
    const whatsAppNumber = "(12) 99120.0512";
    const phoneNumber = "(12) 3432.0067";
    return (
        <Container className="row">
            <Col md="6" sm="12">
                <header>
                    <h1>Canais de Contato</h1>
                </header>
                <p>
                    A Ecobrite atende de Segunda à Sábado, das 8h às 18h, exceto
                    feriados.
                </p>
                <ContactWays>
                    <p>
                        <div className="phone-ecobrite">
                            <FaPhoneAlt />
                        </div>
                        <span>{phoneNumber}</span>
                    </p>
                    <p>
                        <div>
                            <FaWhatsapp />
                        </div>
                        <span>{whatsAppNumber}</span>
                    </p>
                </ContactWays>
            </Col>
            <Col md="6" sm="12">
                <ContactForm />
            </Col>
        </Container>
    );
};

export default Contact;
