import React from "react";
import Routes from "./Routes/routes";
import { Worker } from "@phuocng/react-pdf-viewer";

import "../node_modules/bootstrap/dist/css/bootstrap.css";

import "./Assets/Style/global.css";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { Root as RootLoading } from "./hooks/useLoading";

function App() {
    return (
        <>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js"></Worker>
            <RootLoading />
            <Routes />
        </>
    );
}

export default App;
