import { useCallback } from "react";

export function useOrder() {
    const orderProducts = useCallback((products = []) => {
        return products.sort((a, b) => {
            if (a.linha.ordem > b.linha.ordem) {
                return 1;
            }

            if (a.linha.ordem < b.linha.ordem) {
                return -1;
            }

            return 0;
        });
    }, []);

    return { orderProducts };
}
