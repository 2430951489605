import styled from "styled-components";
import { Col } from "react-bootstrap";

import Fieldset from "../../Fieldset";

export const Container = styled.div``;

export const GridCDOC = styled(Col)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & p {
        font-size: 1.1rem;
        display: none;
    }

    &:hover p {
        display: block;
    }
`;

export const CDOC = styled.button`
    width: 124px;
    height: 124px;
    background-color: var(--green);
    color: var(--white);
    border-radius: 64px;
    border: 0;
    display: flex;
    justify-items: center;
    align-items: center;
    margin: 10px 10px;
    text-decoration: none;
    margin: 0.5rem !important;
    font-size: 1.2rem;

    &:hover {
        filter: brightness(120%);
        color: var(--white);
        text-decoration: none;
    }

    & span {
        margin: 0 auto !important;
        display: flex;
        text-align: center;
    }
`;

export const Videos = styled(Fieldset)`
    font-size: 1.5rem;
    margin-top: 1rem;
`;
