import { useState } from "react";

import api, { publicRequest } from "../../../service/api";
import main from "../../../service/mail";

import useLoading from "../../../hooks/useLoading";

function useContact(client) {
    const { loading } = useLoading();

    const [data, setData] = useState({
        subject: "",
        message: "",
    });

    const handleHtml = () => {
        return `
                    <!DOCTYPE html>
                    <html>
                    <head>
                        <style>
                            h3 {
                                color: #38733e;
                            }
    
                            p {
                                color: black;
                            }
                        </style>
                    </head>
                    <body>
                        <div>
                            <h3>Cliente</h3>
                            <p>${client.Nome}</p>
                        </div>
                        <div>
                            <h3>Mensagem</h3>
                            <p>
                                ${data.message}
                            </p>
                        </div>
                    </body>
                </html>`;
    };

    async function handleSubmit(e) {
        e.preventDefault();

        const idLoading = loading();

        try {
            if (!data.subject) {
                return Notification("warning", "Assunto é obrigatório");
            }

            if (!data.message) {
                return Notification("warning", "Mensagem é obrigatório");
            }

            await api.post(
                "/email",
                {
                    to: main.inputs,
                    subject: data.subject,
                    html: handleHtml(),
                },
                publicRequest
            );

            setData({
                subject: "",
                message: "",
            });

            Notification("success", "Mensagem enviada");

            loading(idLoading);
        } catch (error) {
            Error(error);

            loading(idLoading);
        }
    }

    function handleOnChange({ target }) {
        const { name, value } = target;

        setData({ ...data, [name]: value });
    }

    return { data, handleSubmit, handleOnChange };
}

export default useContact;
